import { t } from '@lingui/macro';
import { useMemo, useState } from 'react';

import { TRAFFIC_TYPES } from '@/api/common';
import { VendorActivityDataType, useVendorActivity } from '@/api/vendor';
import VendorActivityTable from '@/app/vendors/VendorActivityTable';
import { useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { RangeFilter } from '@/components/filters';
import { usePageFilterContext } from '@/components/page';
import { Link } from '@/components/typography';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants';
import { VisitsTableBarCell } from '@/features/VisitsTableBarCell';
import didClickOnAntTooltip from '@/helper/didClickOnAntTooltip';
import { numberFormat } from '@/helper/numberFormatter';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { TableState } from '@/providers/TableState';
import { ROUTES } from '@/router';
import { WebTrafficSearchParamType } from '@/router/searchParams/webTrafficSearchParams';

const VendorActivityTableContainer = () => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();
  const { queryState } = useInstantSearchState();
  const [disabledSeries, setDisabledSeries] = useState<TRAFFIC_TYPES[]>([]);

  const {
    vendorActivity: data,
    totalResults,
    isLoading,
    error,
  } = useVendorActivity(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
    audience?.id,
  );

  const [hoveredRow, setHoveredRow] = useState<VendorActivityDataType | null>(null);
  const [stickyRow, setStickyRow] = useState<{
    element: HTMLElement;
    record: VendorActivityDataType;
  } | null>(null);

  useOnClickOutside(stickyRow?.element, (event) => {
    if (!didClickOnAntTooltip(event.target)) {
      setStickyRow(null);
    }
  });

  // We use this to calculate bar percentage for each row.
  const maxVisits = useMemo(
    () =>
      data?.reduce((max, d) => {
        let total = 0;

        if (audience?.id) {
          if (!disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)) {
            total += d.targetVisits;
          }
        } else {
          if (!disabledSeries.includes(TRAFFIC_TYPES.TAM)) {
            total += d.targetVisits;
          }
        }

        if (!disabledSeries.includes(TRAFFIC_TYPES.OTHER)) {
          total += d.otherVisits;
        }
        if (!disabledSeries.includes(TRAFFIC_TYPES.BOT)) {
          total += d.botVisits;
        }
        if (!disabledSeries.includes(TRAFFIC_TYPES.UNRESOLVED)) {
          total += d.unresolvedVisits;
        }

        return Math.max(total, max);
      }, 0),
    [data, audience?.id, disabledSeries],
  );

  const sortFields = ROUTES.vendors.searchParams.sortBy.values;

  const vendorActivityMetrics: ColumnsType<VendorActivityDataType> = useMemo(
    () => [
      {
        title: t`Spend`,
        key: 'spend',
        width: 110,
        align: 'right',
        sorter: sortFields.includes('spend'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Impressions`,
        key: 'adImpressions',
        width: 120,
        align: 'right',
        sorter: sortFields.includes('adImpressions'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text),
      },
      {
        title: t`Clicks`,
        key: 'adClicks',
        width: 90,
        align: 'right',
        sorter: sortFields.includes('adClicks'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text),
      },
      {
        title: t`Visits`,
        key: 'visits',
        width: 300,
        align: 'left',
        sorter: sortFields.includes('visits'),
        defaultSortOrder: 'descend',
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (_, field) => (
          <VisitsTableBarCell
            data={[
              {
                type: audience?.id ? TRAFFIC_TYPES.AUDIENCE : TRAFFIC_TYPES.TAM,
                label: audience?.name ?? t`TAM`,
                value: field.targetVisits,
                color: audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
                isDisabled: audience?.id
                  ? disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)
                  : disabledSeries.includes(TRAFFIC_TYPES.TAM),
              },
              {
                type: TRAFFIC_TYPES.OTHER,
                label: t`Other Companies`,
                value: field.otherVisits,
                color: VISITS_PRIMARY,
                isDisabled: disabledSeries.includes(TRAFFIC_TYPES.OTHER),
              },
              {
                type: TRAFFIC_TYPES.BOT,
                label: t`Bot`,
                value: field.botVisits,
                color: BOT_PRIMARY,
                isDisabled: disabledSeries.includes(TRAFFIC_TYPES.BOT),
              },
              {
                type: TRAFFIC_TYPES.UNRESOLVED,
                label: t`Unknown`,
                value: field.unresolvedVisits,
                color: UNKNOWN_PRIMARY,
                isDisabled: disabledSeries.includes(TRAFFIC_TYPES.UNRESOLVED),
              },
            ]}
            maxVisits={maxVisits}
            isOpaque={
              (stickyRow == null && hoveredRow == null) ||
              stickyRow?.record === field ||
              hoveredRow === field
            }
            isTooltipOpen={hoveredRow === field || stickyRow?.record === field}
            isTooltipSticky={stickyRow?.record === field}
            tooltipTitle={field.vendor.name}
            tooltipLink={(trafficType: TRAFFIC_TYPES) => {
              const queryParams = {
                vendorIds: [field.vendor.id],
                'metrics.visits': '[1,]',
              } as WebTrafficSearchParamType;

              if (trafficType === TRAFFIC_TYPES.AUDIENCE && audience?.id) {
                return ROUTES.webTrafficActivityWithParams({
                  ...queryParams,
                  audienceIds: audience.id,
                });
              } else if (trafficType === TRAFFIC_TYPES.TAM) {
                return ROUTES.webTrafficActivityWithParams({
                  ...queryParams,
                  inTam: 'true',
                });
              } else if (trafficType === TRAFFIC_TYPES.OTHER) {
                if (audience?.id) {
                  return ROUTES.webTrafficActivityWithParams({
                    ...queryParams,
                    ['audienceIds!']: audience.id,
                  });
                }
                return ROUTES.webTrafficActivityWithParams({
                  ...queryParams,
                  inTam: 'false',
                });
              } else if (trafficType === TRAFFIC_TYPES.ALL) {
                return ROUTES.webTrafficActivityWithParams(queryParams);
              }

              return undefined;
            }}
            onStickyTooltipClose={() => setStickyRow(null)}
          />
        ),
        onCell: (record) => ({
          style: { cursor: 'pointer' },
          onMouseEnter: () => setHoveredRow(record),
          onMouseLeave: () => setHoveredRow(null),
          onClick: (e) => {
            if (didClickOnAntTooltip(e.target)) {
              return;
            }

            // clicking on the same row again will close the tooltip
            if (stickyRow?.record === record) {
              setStickyRow(null);
            } else {
              setStickyRow({ element: e.currentTarget as HTMLElement, record });
            }
          },
        }),
      },
      {
        title: t`Visit Efficiency`,
        key: 'visitEfficiency',
        align: 'right',
        sorter: sortFields.includes('visitEfficiency'),
        width: 115,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter type="percent" />
          </FilterDropdown>
        ),
        render: (_, field) =>
          numberFormat(field.visitEfficiency, { isPercent: true, precision: 1 }),
      },
      {
        title: t`View-Through Visits`,
        key: 'viewThroughVisits',
        align: 'right',
        sorter: sortFields.includes('viewThroughVisits'),
        width: 140,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (_, field) => numberFormat(field.viewThroughVisits),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        width: 115,
        align: 'right',
        sorter: sortFields.includes('engagedCompanies'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text, field) =>
          +text > 0 ? (
            <Link
              variant="caption1"
              to={ROUTES.webTrafficActivityWithParams({
                vendorIds: field.vendor.id,
                audienceIds: audience?.id,
                'metrics.visits': '[1,]',
              })}
            >
              {numberFormat(text)}
            </Link>
          ) : (
            0
          ),
      },
      {
        title: t`Pipeline Influenced`,
        key: 'pipelineInfluenced',
        width: 120,
        align: 'right',
        sorter: sortFields.includes('pipelineInfluenced'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
      {
        title: t`Closed Won Influenced`,
        key: 'closedWonInfluenced',
        width: 120,
        align: 'right',
        sorter: sortFields.includes('closedWonInfluenced'),
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <RangeFilter />
          </FilterDropdown>
        ),
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [maxVisits, audience, hoveredRow, stickyRow, disabledSeries],
  );

  return (
    <TableState columns={vendorActivityMetrics}>
      <VendorActivityTable
        data={data}
        totalResults={totalResults}
        isLoading={isLoading}
        error={error}
        disabledSeries={disabledSeries}
        maxVisits={maxVisits}
        onDisabledSeriesChange={setDisabledSeries}
      />
    </TableState>
  );
};

export default VendorActivityTableContainer;
