import { CompanyDetailType } from '../activity';
import { BaseMetadataType, OptionType } from '../common';
import { Edge, SolDeleteResponseType } from '../solType';

type AudienceTypeType = 'salesforce' | 'upload';

export enum AudienceStatus {
  QUEUED = 'queued',
  PROCESSING = 'processing',
  ERROR = 'error',
  COMPLETED = 'completed',
  UNKNOWN = 'unknown',
}

export interface AudienceType extends BaseMetadataType {
  type: AudienceTypeType;
  metrics: {
    size: number;
    visits: number;
  };
  last_job?: {
    status:
      | AudienceStatus.COMPLETED
      | AudienceStatus.ERROR
      | AudienceStatus.PROCESSING
      | AudienceStatus.QUEUED
      | AudienceStatus.UNKNOWN;
    errors?: string | null;
  };
  salesforce_url?: string;
  dataset_id?: string;
}

export type AudienceDatasetResponse = {
  dataset: string;
  rows: {
    error: number;
    success: number;
  };
};

export type UploadAudienceProgressCallback = (percentComplete: number) => void;

export type AudienceListGQLResponse = {
  audiences: {
    get: {
      edges: Edge<AudienceType>[];
      totalEdges: number;
    };
  };
};

export type AudienceGQLResponse = {
  audiences: {
    getById: AudienceType;
  };
};

export type AudienceCreateType = Omit<AudienceType, 'id' | 'created_at' | 'created_by' | 'metrics'>;

export type AudienceUpdateType = Omit<
  AudienceType,
  'created_at' | 'created_by' | 'metrics' | 'type'
>;

export type AudienceCreateVariablesType = {
  audience: AudienceCreateType;
};

export type AudienceUpdateVariablesType = {
  audience: AudienceUpdateType;
};

export type AudienceCreateResponseType = {
  audiences: {
    create: AudienceType;
  };
};

export type AudienceUpdateResponseType = {
  audiences: {
    update: AudienceType;
  };
};

export type AudienceDeleteResponseType = {
  audiences: SolDeleteResponseType;
};

export interface AudienceGroupedOptionType extends AudienceOptionType {
  audienceIds: string[];
}

export interface AudienceOptionType extends OptionType {
  metrics?: {
    size: number;
  };
}

export type AudienceOptionGQLResponseType = {
  audiences: {
    get: {
      edges: {
        node: AudienceOptionType;
      }[];
      pageMeta: {
        hasNext: boolean;
      };
    };
  };
};

export type AudienceMembershipGQLResponse = {
  companies: {
    getByAudienceId: {
      edges: Edge<CompanyDetailType>[];
      totalEdges: number;
    };
  };
};

export type SalesforceAudienceReportMetadataGQLResponse = {
  audiences: {
    getAudienceReportMetadata: {
      name?: string;
    };
  };
};
