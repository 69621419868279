import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { PropsWithChildren, UIEvent, useMemo } from 'react';

import { OptionType } from '@/api/common';
import { Flex } from '@/components/Flex';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { List } from '@/components/list';
import { Link } from '@/components/typography';

import styles from './CategoryFilter.module.scss';

type Props = {
  className?: string;
  options: OptionType[] | undefined;
  selectionMode: 'none' | 'single' | 'multiple';
  value?: FilterOperatorMulti;
  isLoading?: boolean;
  isLoadingMore?: boolean;
  error?: Error | null;
  onChange?: (value: FilterOperatorValue) => void;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
  onValidChange?: (valid: boolean) => void;
};

const CategoryFilter = ({
  className,
  children,
  options,
  value,
  onChange,
  ...rest
}: PropsWithChildren<Props>) => {
  const handleSelectAll = () => {
    onChange?.({ operator: 'notIn', operand: [] });
  };

  const handleClear = () => {
    onChange?.({ operator: 'in', operand: [] });
  };

  const handleSelectedKeysChange = (keys?: string[]) => {
    const op = value?.operator ?? 'notIn';

    let newOperand: string[] = [];
    let newOperator = op;
    if (op === 'notIn') {
      newOperator = 'notIn';
      const notCheckedValues =
        options?.filter((option) => !keys?.includes(option.id)).map((option) => option.id) ?? [];
      newOperand = notCheckedValues;
    } else {
      newOperator = 'in';
      newOperand = keys ?? [];
    }
    onChange?.({ operator: newOperator, operand: [...new Set(newOperand)] });
  };

  const checkedValues = useMemo(() => {
    if (!value?.operator || value?.operator === 'notIn') {
      const operand = value?.operand ?? [];
      return (
        options?.filter((option) => !operand.includes(option.id)).map((option) => option.id) ?? []
      );
    } else {
      return value?.operand ?? [];
    }
  }, [value, options]);

  delete rest.onValidChange;

  return (
    <Flex vertical gap="small">
      <Flex gap="small">
        <Link variant="caption1" onClick={handleSelectAll}>
          <Trans>Select All</Trans>
        </Link>
        <Link variant="caption1" onClick={handleClear}>
          <Trans>Clear</Trans>
        </Link>
      </Flex>
      {children}
      <List
        {...rest}
        className={classNames(styles.list, className)}
        renderItem={(item) => <List.Item id={item.id}>{item.name}</List.Item>}
        options={options}
        selectedKeys={checkedValues}
        onSelectedKeysChange={handleSelectedKeysChange}
      />
    </Flex>
  );
};

export default CategoryFilter;
