import debounce from 'lodash/debounce';
import { ReactNode, UIEvent } from 'react';

import { OptionType } from '@/api/common';

import { Option, Select, SelectProps, getLoadingOption } from '../Select';

// We display a loading option as the last item in the list (if there are more pages to fetch).
// If the user sees this loading option, load the next page. Don't wait until the user scrolls all
// the way to the bottom.
const LOADING_OPTION_OFFSET = 30;

const defaultRenderOption = <T extends OptionType>({ id, name, ...rest }: T) => (
  <Option key={id} value={id} label={name} {...rest}>
    {name}
  </Option>
);

export interface InfiniteScrollSelectProps<T extends OptionType>
  extends Omit<SelectProps, 'options'> {
  options?: T[];
  loadMore?: () => void;
  hasMore?: boolean;
  renderOption?: (option: T, index: number, array: T[]) => ReactNode;
  notFoundContent: ReactNode;
}

const InfiniteScrollSelect = <T extends OptionType>({
  isLoading,
  options,
  hasMore,
  loadMore,
  renderOption = defaultRenderOption,
  onSearch,
  ...rest
}: InfiniteScrollSelectProps<T>) => {
  const handleScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    if (
      loadMore &&
      hasMore &&
      !isLoading &&
      target.scrollTop + target.offsetHeight >= target.scrollHeight - LOADING_OPTION_OFFSET
    ) {
      loadMore();
    }
  };

  const handleSearch = debounce((search: string) => {
    onSearch?.(search);
  }, 500);

  const handleDropdownVisibleChange = (open: boolean) => {
    if (open) {
      // Reset the search box
      onSearch?.('');
    } else {
      handleSearch.cancel();
    }
  };

  return (
    <Select
      {...rest}
      showSearch
      isLoading={isLoading}
      filterOption={false}
      onPopupScroll={handleScroll}
      onSearch={handleSearch}
      onDropdownVisibleChange={handleDropdownVisibleChange}
    >
      {options?.map(renderOption)}
      {hasMore && getLoadingOption()}
    </Select>
  );
};

export default InfiniteScrollSelect;
