import { DragOverlay } from '@dnd-kit/core';
import { PropsWithChildren, useEffect } from 'react';

import styles from './SortableListOverlay.module.scss';

const SortableListOverlay = ({ children }: PropsWithChildren) => {
  useEffect(() => {
    if (children != null) {
      document.body.style.cursor = 'grabbing';
    }

    return () => {
      document.body.style.cursor = '';
    };
  }, [children]);

  return (
    <DragOverlay adjustScale className={styles.overlay} dropAnimation={null}>
      <div className={styles.wrapper}>{children}</div>
    </DragOverlay>
  );
};

export default SortableListOverlay;
