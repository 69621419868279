import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';

import { Link } from '@/components/typography';
import { ROUTES } from '@/router';

export enum AUTH_ERROR_TYPE {
  CANNOT_CREATE_ORG_FROM_SIGN_IN = 'cannot_create_org_from_sign_in',
  CANNOT_SIGN_UP_WITH_PERSONAL_EMAIL = 'cannot_sign_up_with_personal_email',
  UNKNOWN = 'unknown',
}

type AuthErrorType = {
  type: AUTH_ERROR_TYPE;
  details: {
    email: string;
  };
};

type AuthErrorDetails = {
  email?: string;
};

class AuthError extends Error {
  type: AUTH_ERROR_TYPE = AUTH_ERROR_TYPE.UNKNOWN;
  details?: AuthErrorDetails = {};

  constructor(info: AuthErrorType) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    super('AuthErrorType');

    this.type = info.type;
    if (info.type !== 'unknown') {
      this.details = info.details;
    }

    Object.setPrototypeOf(this, AuthError.prototype);
  }

  get errorMessage(): ReactNode {
    switch (this.type) {
      case 'cannot_create_org_from_sign_in':
        return (
          <Trans>
            <b>{this.details!.email}</b>&nbsp;was not recognized. You may have used a different
            email address than the one you signed up with or haven't created an account yet.&nbsp;
            <Link variant="caption1" to={ROUTES.signUp.path} color="static-white">
              Create an Account
            </Link>
            ?
          </Trans>
        );
      case 'cannot_sign_up_with_personal_email':
        return (
          <Trans>
            <b>{this.details!.email}</b>&nbsp;appears to be a personal email address. Please use
            your work email address.
          </Trans>
        );
      default:
        return (
          <Trans>
            Oops! An unexpected error has occured. Please refresh the page and try again. If the
            error keeps happening please contact support.
          </Trans>
        );
    }
  }
}

export default AuthError;
