import { Trans } from '@lingui/macro';
import moment from 'moment';

import { IntegrationJobType, IntegrationProviderType } from '@/api/integration';
import { Text } from '@/components/typography';

type Props = {
  provider: IntegrationProviderType;
  lastJob?: IntegrationJobType;
};

const IntegrationConnectionListItemStatus = ({ provider, lastJob }: Props) => {
  const updateSyncText = (
    <Trans>
      {provider.name} sync attempts to update every {provider.syncIntervalHours} hours.
    </Trans>
  );

  if (!lastJob || lastJob?.status === 'processing') {
    return (
      <Text variant="body2" color="grey">
        <Trans>Sync in process.</Trans> {updateSyncText}
      </Text>
    );
  }
  if (lastJob?.status === 'error') {
    return (
      <Text variant="body2" color="red">
        <Trans>An error occurred during the last sync: "{lastJob?.errors}".</Trans> {updateSyncText}
      </Text>
    );
  }
  if (lastJob?.status === 'completed') {
    return (
      <Text variant="body2" color="grey">
        <Trans>Last sync completed {moment(lastJob.created_at).fromNow()}.</Trans> {updateSyncText}
      </Text>
    );
  }
};

export default IntegrationConnectionListItemStatus;
