/* eslint-disable no-restricted-imports */
import { Form as AntdForm, FormItemProps as AntdFormItemProps } from 'antd';
import { FormInstance } from 'antd/es/form';
import { NamePath, StoreValue } from 'antd/es/form/interface';
import classNames from 'classnames';

import { Flex } from '@/components/Flex';
import InfoPopover from '@/components/InfoPopover';

import styles from './Form.module.scss';

const {
  Item: DefaultFormItem,
  ErrorList,
  List,
  Provider,
  useForm,
  useFormInstance,
  useWatch,
  create,
} = { ...AntdForm };

type FormItemProps = AntdFormItemProps & {
  infoPopover?: {
    title?: React.ReactNode;
    body: React.ReactNode;
  };
};

const CustomFormItem = ({ className, label, infoPopover, ...props }: FormItemProps) => (
  <DefaultFormItem
    className={classNames(styles.formItem, className)}
    label={
      <Flex gap={4} align="center">
        {label}
        {infoPopover && <InfoPopover title={infoPopover.title} body={infoPopover.body} />}
      </Flex>
    }
    {...props}
  />
);
CustomFormItem.useStatus = DefaultFormItem.useStatus;

type FormType = typeof AntdForm & {
  Item: typeof CustomFormItem;
};

/**
 * Change the default behavior of AntD's Form components so they better suit our design pattern.
 */
const Form: FormType = ({
  layout = 'vertical', // we only use `vertical` layout, default to that instead of `horizontal`
  ...otherProps
}) => <AntdForm {...otherProps} layout={layout} />;

Form.Item = CustomFormItem as typeof CustomFormItem;
Form.ErrorList = ErrorList;
Form.List = List;
Form.Provider = Provider;
Form.useForm = useForm;
Form.useFormInstance = useFormInstance;
Form.useWatch = useWatch;
Form.create = create;

export const setFieldErrors = (form: FormInstance, name: NamePath, errors: string[]) => {
  form.setFields([
    {
      name,
      errors,
    },
  ]);
};

export const resetFieldErrors = (form: FormInstance, name: NamePath) => {
  setFieldErrors(form, name, []);
};

export const setFieldValue = (form: FormInstance, name: NamePath, value: StoreValue) => {
  form.setFields([
    {
      name,
      value,
    },
  ]);
};

type FieldError = {
  name: (string | number)[];
  errors: string[];
  warnings: string[];
};

type ValidateFieldsError = {
  errorFields: FieldError[];
};

export const isFormValid = async (form: FormInstance) => {
  try {
    await form.validateFields();
    return true;
  } catch (error) {
    return !(error as ValidateFieldsError).errorFields.some((e) => !!e.errors.length);
  }
};

export default Form;
