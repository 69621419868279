import { MenuProps, Space } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';

import ChevronDown from '@/assets/svg/chevron-down.svg?react';
import { Dropdown } from '@/components/Dropdown';

import { Button } from '../Button';
import styles from './MenuButton.module.scss';

export type MenuButtonChange = MenuProps['onClick'];

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  icon?: ReactNode;
  menuItems: MenuProps['items'];
  onChange: (key: string) => void;
};

const MenuButton = ({
  isDisabled = false,
  isLoading = false,
  icon,
  children,
  menuItems,
  onChange,
}: PropsWithChildren<Props>) => {
  const handleMenuClick: MenuProps['onClick'] = ({ key }) => {
    onChange(key);
  };

  const menuProps = {
    items: menuItems,
    onClick: handleMenuClick,
  };

  return (
    <Dropdown menu={menuProps} isDisabled={isDisabled}>
      <Button
        color="green"
        variant="primary"
        icon={icon}
        isDisabled={isDisabled}
        isLoading={isLoading}
      >
        <Space align="center">
          {children}
          {!isLoading && <ChevronDown className={styles.arrow} />}
        </Space>
      </Button>
    </Dropdown>
  );
};

export default MenuButton;
