import { Trans } from '@lingui/macro';

import { OptionType } from '@/api/common';
import ChannelSelect from '@/app/pixels/ChannelSelect';
import VendorByChannelSelect from '@/app/pixels/VendorByChannelSelect';
import CloseIcon from '@/assets/svg/bx-x.svg?react';
import Form from '@/components/Form';
import { ActionIcon, Button } from '@/components/buttons';
import { Text } from '@/components/typography';

import styles from './VendorSpendAddVendor.module.scss';

export enum FORM_FIELD {
  CHANNEL = 'channel',
  VENDOR = 'vendor',
}

export type FormType = {
  [FORM_FIELD.CHANNEL]: OptionType;
  [FORM_FIELD.VENDOR]: OptionType;
};

type Props = {
  disabledVendorIds: string[];
  onClose: () => void;
  onAddVendor: ({ channel, vendor }: FormType) => void;
};

const VendorSpendAddVendor = ({ disabledVendorIds, onClose, onAddVendor }: Props) => {
  const [form] = Form.useForm();
  const channel = Form.useWatch(FORM_FIELD.CHANNEL, form);

  const handleFinish = (values: FormType) => {
    onAddVendor(values);
  };

  return (
    <div className={styles.container}>
      <Text variant="headline" color="dark-grey" weight="light">
        <Trans>New Vendor</Trans>
      </Text>
      <Form className={styles.form} form={form} onFinish={handleFinish}>
        <Form.Item
          label={<Trans>Channel</Trans>}
          name={FORM_FIELD.CHANNEL}
          rules={[{ required: true, message: <Trans>Channel is Required</Trans> }]}
        >
          <ChannelSelect width={350} />
        </Form.Item>
        <Form.Item
          label={<Trans>Vendor</Trans>}
          name={FORM_FIELD.VENDOR}
          rules={[{ required: true, message: <Trans>Vendor is Required</Trans> }]}
        >
          <VendorByChannelSelect
            width={350}
            disabledVendorIds={disabledVendorIds}
            channelId={channel?.id}
          />
        </Form.Item>
        <div className={styles.buttonContainer}>
          <Button color="black" variant="secondary" onClick={onClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button color="black" variant="secondary" type="submit">
            <Trans>Add</Trans>
          </Button>
        </div>
      </Form>
      <ActionIcon
        className={styles.closeButton}
        color="black"
        size="medium"
        icon={<CloseIcon />}
        onClick={onClose}
      />
    </div>
  );
};

export default VendorSpendAddVendor;
