import { useWebTrafficActivityList } from '@/api/activity';
import { ALL_WEB_TRAFFIC_FIELDS } from '@/app/web-traffic-activity/webTrafficFields';
import WebTrafficCompanyActivityTable from '@/app/web-traffic/WebTrafficCompanyActivityTable';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import { InstantSearchSortOrder } from '@/components/InstantSearch';

const CompanyActivityTopVisitsWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date, audience } = pageFilters;
  const { companies, isLoading, error } = useWebTrafficActivityList(
    {
      page: {
        offset: 0,
        limit: 10,
      },
      sort: {
        field: ALL_WEB_TRAFFIC_FIELDS['metrics.visits'],
        direction: InstantSearchSortOrder.DESC,
      },
      filter: audience
        ? [{ field: ALL_WEB_TRAFFIC_FIELDS.audienceIds, operator: 'in', operand: [audience?.id] }]
        : [],
    },
    start_date,
    end_date,
  );

  return <WebTrafficCompanyActivityTable data={companies} isLoading={isLoading} error={error} />;
};

export default CompanyActivityTopVisitsWidget;
