import {
  COLOR_PURPLE_300,
  COLOR_PURPLE_400,
  COLOR_PURPLE_500,
  COLOR_PURPLE_600,
  COLOR_PURPLE_700,
  COLOR_PURPLE_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_PURPLES = [
  COLOR_PURPLE_300,
  COLOR_PURPLE_400,
  COLOR_PURPLE_500,
  COLOR_PURPLE_600,
  COLOR_PURPLE_700,
  COLOR_PURPLE_800,
];
