import { setTag } from '@sentry/browser';

import { UserProfileType } from '@/api/user';

export const updateSentryTags = (user?: UserProfileType) => {
  if (user) {
    setTag('user_email', user.email);
    setTag('instance_id', user.currentOrg.instanceId);
  }
};
