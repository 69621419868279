import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PageFiltersType } from '@/components/page';
import { NotesPanel } from '@/components/panels';

import styles from './DashboardWidget.module.scss';
import DashboardWidgetActions from './DashboardWidgetActions';
import { DashboardWidgetLibraryType, DashboardWidgetType } from './types';

const isLibraryWidget = (widget: DashboardWidgetType): widget is DashboardWidgetLibraryType => {
  return 'library' in widget;
};

type Props = {
  widget: DashboardWidgetType;
  pageFilters: PageFiltersType;
  canFullScreen?: boolean;
  isFullScreen?: boolean;
  draggableHandleSelector?: string | false;
  draggableIgnoreSelector?: string | false;
  onWidgetExpand: (isExpanded: boolean, widget: DashboardWidgetType) => void;
};

const renderLibraryWidget = (widget: DashboardWidgetLibraryType, pageFilters: PageFiltersType) => {
  const { library: LibraryComponent } = widget;

  return <LibraryComponent pageFilters={pageFilters} />;
};

const DashboardWidget = ({
  widget,
  pageFilters,
  canFullScreen = true,
  isFullScreen = false,
  draggableHandleSelector,
  draggableIgnoreSelector,
  onWidgetExpand,
}: PropsWithChildren<Props>) => {
  const navigate = useNavigate();
  const [isNotesDrawerOpen, setIsNotesDrawerOpen] = useState<boolean>(false);

  const handleLinkExternal = (link: string) => {
    navigate(link);
  };

  const handleWidgetExpand = (isExpanded: boolean) => {
    onWidgetExpand(isExpanded, widget);
  };

  return (
    <NotesPanel
      data-testid={`dashboard-widget-${widget.id}`}
      startDate={pageFilters.start_date}
      endDate={pageFilters.end_date}
      className={classNames(styles.widgetPanel, { 'c99-pull-front': isNotesDrawerOpen })}
      headerClassName={draggableHandleSelector}
      actionsClassName={draggableIgnoreSelector}
      title={widget.title}
      hideNotes={!widget.showNotes}
      actions={
        <DashboardWidgetActions
          link={widget.link}
          canExpand={canFullScreen}
          isExpanded={isFullScreen}
          onExpandToggle={handleWidgetExpand}
          onLinkExternal={handleLinkExternal}
        />
      }
      noPadding
      verifyC99Tag={widget.verifyC99Tag}
      onDrawerOpenChange={setIsNotesDrawerOpen}
    >
      {isLibraryWidget(widget) ? (
        renderLibraryWidget(widget, pageFilters)
      ) : (
        <div>
          <Trans>Dynamic widgets coming soon...</Trans>
        </div>
      )}
    </NotesPanel>
  );
};

export default DashboardWidget;
