import { InputRef } from 'antd';
import { useEffect, useMemo, useRef, useState } from 'react';

import { useVendorList } from '@/api/vendor';
import { SearchInput } from '@/components/Form';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { CategoryFilter } from '@/components/filters';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const VendorFilter = ({ onChange, ...rest }: Props) => {
  const searchRef = useRef<InputRef | null>(null);
  const { vendors, isLoading, error } = useVendorList();
  const [searchText, setSearchText] = useState<string>('');

  useEffect(() => {
    searchRef.current?.focus();
  }, [searchRef]);

  const vendorsWithChannels = useMemo(() => {
    return vendors
      ?.map((vendor) => ({
        id: vendor.id,
        name: `${vendor.name} - ${vendor.channel?.name}`,
      }))
      .filter((vendor) => vendor.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [vendors, searchText]);

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={vendorsWithChannels}
      onChange={onChange}
    >
      <SearchInput
        ref={searchRef}
        size="medium"
        value={searchText}
        onChange={setSearchText}
        autoFocus
      />
    </CategoryFilter>
  );
};

export default VendorFilter;
