import { Trans, t } from '@lingui/macro';
import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';

import { useChannelList } from '@/api/channel';
import { Option, Select, SelectProps } from '@/components/Form';

interface Props extends Omit<SelectProps, 'mode'> {
  value?: string[];
}

const ChannelMultiSelect = ({ value, onChange, ...rest }: Props) => {
  const { channels, isLoading } = useChannelList();
  const channelNames = useMemo(() => channels?.map((chan) => chan.name) || [], [channels]);

  const handleChange = (
    value: DefaultOptionType,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (onChange && Array.isArray(option)) {
      onChange(
        option.map((o) => o.value),
        option,
      );
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={value}
      mode="multiple"
      onChange={handleChange}
      placeholder={t`Select Channel...`}
      notFoundContent={<Trans>No channels were found...</Trans>}
      showSearch
      {...rest}
    >
      {channelNames.map((channel) => (
        <Option key={channel} value={channel}>
          {channel}
        </Option>
      ))}
    </Select>
  );
};

export default ChannelMultiSelect;
