import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import { Text } from '@/components/typography';

import styles from './AIMessage.module.scss';

type Props = {
  className?: string;
  isUser?: boolean;
};

const AIMessage = ({ className, isUser = false, children }: PropsWithChildren<Props>) => {
  return (
    <Text
      className={classNames(styles.message, className, { [styles['is-user']]: isUser })}
      variant="body1"
    >
      {children}
    </Text>
  );
};

export default AIMessage;
