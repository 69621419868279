import { Trans, t } from '@lingui/macro';
import { StytchAPIError } from '@stytch/vanilla-js';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '@/router';

export const isStytchAPIError = (e: StytchAPIError | Error | unknown): e is StytchAPIError =>
  (e as StytchAPIError).error_type != null;

const checkLinkRequired = (link?: string) => {
  if (!link) {
    throw new Error('Link is required to display error message');
  }
};

export enum STYTCH_API_ERROR_TYPE {
  UNABLE_TO_AUTH_MAGIC_LINK = 'unable_to_auth_magic_link',
  OAUTH_TOKEN_NOT_FOUND = 'oauth_token_not_found',
  EXPIRED_OAUTH_RESPONSE = 'expired_oauth_response',
  INVALID_MICROSOFT_TENANT_TYPE = 'invalid_microsoft_tenant_type',
  UNABLE_TO_AUTH_RESET_PASSWORD = 'unable_to_auth_reset_password',
  EMAIL_NOT_FOUND = 'email_not_found',
  EMAIL_JIT_PROVISIONING_NOT_ALLOWED = 'email_jit_provisioning_not_allowed',
  TOO_MANY_REQUESTS = 'too_many_requests',
}

export enum AUTH_ERROR_TYPE {
  CANNOT_CREATE_ORG_FROM_SIGN_IN = 'cannot_create_org_from_sign_in',
  CANNOT_SIGN_UP_WITH_PERSONAL_EMAIL = 'cannot_sign_up_with_personal_email',
}

export const getStytchAPIErrorMessage = (e: unknown, link?: string): ReactNode => {
  const unknownErrorMessage = (
    <Trans>
      Oops! An unexpected error has occured. Please refresh the page and try again. If the error
      keeps happening please contact support.
    </Trans>
  );

  if (isStytchAPIError(e)) {
    switch (e.error_type) {
      case STYTCH_API_ERROR_TYPE.UNABLE_TO_AUTH_MAGIC_LINK:
        return (
          <Trans>
            We could not sign you in because your link was either already used or expired. Please
            enter your email address and try again or have your admin re-invite you.
          </Trans>
        );
      case STYTCH_API_ERROR_TYPE.OAUTH_TOKEN_NOT_FOUND:
      case STYTCH_API_ERROR_TYPE.EXPIRED_OAUTH_RESPONSE:
        checkLinkRequired(link);
        return (
          <Trans>
            We could not sign you in because too much time has passed since the login flow started
            or the session is invalid. <Link to={link!}>{t`Try to sign in again?`}</Link>
          </Trans>
        );
      case STYTCH_API_ERROR_TYPE.INVALID_MICROSOFT_TENANT_TYPE:
        checkLinkRequired(link);
        return (
          <Trans>
            Only Microsoft Azure Active Directory (AAD) Tenants can sign in to Channel99. If you are
            attempting to sign in with an AAD-B2C account, configure that AAD-B2C tenant as an
            org-scoped OIDC or SAML connection. <Link to={link!}>{t`Try to sign in again?`}</Link>
          </Trans>
        );
      case STYTCH_API_ERROR_TYPE.UNABLE_TO_AUTH_RESET_PASSWORD:
        if (!link) {
          throw new Error('Link is required to display error message');
        }
        return (
          <Trans>
            We could not change your password because your reset password link was either already
            used or expired. <Link to={link}>{t`Send another reset password link?`}</Link>
          </Trans>
        );
      case STYTCH_API_ERROR_TYPE.EMAIL_NOT_FOUND:
      case STYTCH_API_ERROR_TYPE.EMAIL_JIT_PROVISIONING_NOT_ALLOWED:
        return (
          <Trans>
            It looks like there isn't a user in this account tied to this email address. Please try
            again with a different email address.
          </Trans>
        );
      case STYTCH_API_ERROR_TYPE.TOO_MANY_REQUESTS:
        return (
          <Trans>
            Too many requests have been made. Please wait a few minutes before retrying.
          </Trans>
        );
      default:
        console.error('Unhandled exception', e);
        return unknownErrorMessage;
    }
  }

  if (e instanceof Error) {
    switch (e.name) {
      case AUTH_ERROR_TYPE.CANNOT_CREATE_ORG_FROM_SIGN_IN:
        return (
          <Trans>
            Your credentials aren't recognized. You may not have a company account. Verify you used
            the right email address or&nbsp;<Link to={ROUTES.signUp.path}>Create an Account</Link>.
          </Trans>
        );
      case AUTH_ERROR_TYPE.CANNOT_SIGN_UP_WITH_PERSONAL_EMAIL:
        return (
          <Trans>
            You can't sign up with a personal email address. Please use your work email address.
          </Trans>
        );
    }
  }

  console.error('Unhandled exception', e);

  return unknownErrorMessage;
};
