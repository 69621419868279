import { Input, InputRef } from 'antd';
import { PasswordProps } from 'antd/es/input';
import classNames from 'classnames';
import { forwardRef } from 'react';

import ClosedEyeIcon from '@/assets/svg/eye-closed.svg?react';
import OpenEyeIcon from '@/assets/svg/eye-open.svg?react';
import { maxDefaultLength } from '@/constants/numbers';

import { TextInputProps } from '../TextInput';
import inputStyles from '../TextInput/TextInput.module.scss';

export type PasswordInputProps = Omit<TextInputProps, 'allowClear' | 'suffix'> &
  Pick<PasswordProps, 'visibilityToggle'> & {
    onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
  };

const PasswordInput = forwardRef<InputRef, PasswordInputProps>(
  (
    {
      className,
      isDisabled,
      maxLength = maxDefaultLength,
      size = 'large',
      error,
      onChange,
      ...rest
    },
    ref,
  ) => {
    return (
      <Input.Password
        {...rest}
        className={classNames(inputStyles.input, className)}
        ref={ref}
        size={size === 'medium' ? 'middle' : size}
        variant="outlined"
        disabled={isDisabled}
        maxLength={maxLength}
        status={error ? 'error' : undefined}
        iconRender={(visible) => (visible ? <OpenEyeIcon /> : <ClosedEyeIcon />)}
        onChange={(e) => onChange?.(e.target.value, e)}
      />
    );
  },
);

export default PasswordInput;
