import { Trans, t } from '@lingui/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { Organization } from '@stytch/vanilla-js';
import { useState } from 'react';

import RateLimit from '@/app/sign-in/RateLimitButton';
import SignInPanel from '@/app/sign-in/SignInPanel';
import Form, { TextInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { Link, Text } from '@/components/typography';
import { maxEmailLength } from '@/constants/numbers';
import { getStytchAPIErrorMessage } from '@/error/StytchError';
import { EMAIL_STANDARD_RULES } from '@/helper/ant-rule';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

type Props = {
  org: Organization;
};

enum FORM_FIELD {
  EMAIL = 'email',
}

type FormType = {
  [FORM_FIELD.EMAIL]: string;
};

const ForgotPasswordForm = ({ org }: Props) => {
  const stytch = useStytchB2BClient();
  const { pushNotification, removeAllNotifications } = useNotification();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleError = (e: unknown) => {
    pushNotification({
      type: 'error',
      message: getStytchAPIErrorMessage(e),
    });
  };

  const handleFinish = async (values: FormType) => {
    const email = values[FORM_FIELD.EMAIL];

    try {
      setLoading(true);
      await stytch.passwords.resetByEmailStart({
        email_address: email,
        organization_id: org.organization_id,
        reset_password_expiration_minutes: 30,
        login_redirect_url: `${location.origin}/sign-in`,
        reset_password_redirect_url: `${location.origin}/sign-in`,
      });

      removeAllNotifications();
      pushNotification({
        type: 'success',
        message: (
          <Trans>
            A one-time access link was sent to&nbsp;
            <Text variant="caption1" weight="extra-bold">
              {email}
            </Text>
            .{' '}
          </Trans>
        ),
      });
    } catch (e) {
      handleError(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form form={form} validateTrigger="onSubmit" size="large" onFinish={handleFinish}>
      <SignInPanel
        title={t`Reset your password`}
        description={<Trans>We can email you a one-time password reset link.</Trans>}
        footer={
          <Text variant="caption1">
            <Trans>
              Don't want to reset your password?{' '}
              <Link
                variant="caption1"
                color="aqua"
                to={ROUTES.tenantSignInByOrgSlug(org.organization_slug)}
              >
                {t`Sign in a different way`}
              </Link>
            </Trans>
          </Text>
        }
      >
        <Form.Item
          label={<Trans>Business email</Trans>}
          name={FORM_FIELD.EMAIL}
          rules={EMAIL_STANDARD_RULES}
          initialValue=""
          required={false}
        >
          <TextInput
            inputMode="email"
            placeholder={t`name@work-email.com`}
            autoComplete="email"
            spellCheck="false"
            autoCapitalize="none"
            autoFocus
            maxLength={maxEmailLength}
          />
        </Form.Item>
        <RateLimit>
          <Button
            color="green"
            variant="primary"
            size="large"
            type="submit"
            isLoading={isLoading}
            block
          >
            <Trans>Reset your password</Trans>
          </Button>
        </RateLimit>
      </SignInPanel>
    </Form>
  );
};

export default ForgotPasswordForm;
