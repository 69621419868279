import { Trans, t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { usePixelsByIds } from '@/api/pixel';
import { Flex } from '@/components/Flex';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Page, PageFilterProvider } from '@/components/page';
import { PIXEL_GROUP_MAX_LIMIT } from '@/constants/numbers';
import { ROUTES } from '@/router';

import PixelDetailContent from './PixelDetailContent';
import PixelDetailErrorOrChildren from './PixelDetailErrorOrChildren';
import PixelTagList from './PixelTagList';

const PixelDetail = () => {
  const { pixels: pixelIdString } = useParams();
  const pixelIds = pixelIdString?.split(',');
  const { pixels, isLoading } = usePixelsByIds(pixelIds);
  const isGrouped = !!(pixelIds && pixelIds?.length > 1);

  const pageTitle = isGrouped ? t`Grouped (${pixelIds?.length})` : pixels?.[0].name;

  return (
    <Page title={t`Pixel Reporting`} pageName={pageTitle}>
      <Flex vertical gap="large" fullWidth>
        <Flex justify="space-between" align="start" gap="xlarge">
          <ReturnToLink route={ROUTES.pixels.path} routeName={<Trans>Pixel Overview</Trans>} />
          <PixelTagList pixels={pixels} />
        </Flex>
        <PageFilterProvider>
          <PixelDetailErrorOrChildren pixelIds={pixelIds} maxLimit={PIXEL_GROUP_MAX_LIMIT}>
            <PixelDetailContent pixelIds={pixelIds} pixels={pixels} isLoading={isLoading} />
          </PixelDetailErrorOrChildren>
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default PixelDetail;
