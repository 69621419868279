import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';

import Check from '@/assets/svg/check.svg?react';
import Clone from '@/assets/svg/clone-regular.svg?react';
import { Flex } from '@/components/Flex';
import Tooltip from '@/components/Tooltip';
import { ActionIcon } from '@/components/buttons';
import { Text, TextProps } from '@/components/typography';

const CopyToClipboardText = ({ value, ...rest }: TextProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleCopy = () => {
    if (value) {
      setCopied(true);
      navigator.clipboard.writeText(value + '');
    }
  };

  return (
    <Flex align="center" justify="center" gap="small" data-testid="clipboard-copy-text">
      <Text {...rest} value={value} />
      <Tooltip open={copied ? copied : undefined} body={copied ? t`Copied!` : t`Copy to clipboard`}>
        <ActionIcon
          color="black"
          size="small"
          aria-label={t`Copy text to clipboard`}
          icon={copied ? <Check /> : <Clone />}
          onClick={handleCopy}
        />
      </Tooltip>
    </Flex>
  );
};

export default CopyToClipboardText;
