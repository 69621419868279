import { Space } from 'antd';

import { CompanyDetailType } from '@/api/activity';
import Drawer from '@/components/Drawer';
import { DrawerProps } from '@/components/Drawer';
import Tooltip from '@/components/Tooltip';
import { Link, Text } from '@/components/typography';
import { getRevenueRangeDescription } from '@/messages/revenue-range-name-messages';

import CompanyLogo from './CompanyLogo';
import CompanyTotalVisitsPanel from './CompanyTotalVisitsPanel';
import CompanyTrendPanel from './CompanyTrendPanel';
import styles from './WebTrafficActivityDetailDrawer.module.scss';

type Props = {
  data: CompanyDetailType | null;
  startDate?: string;
  endDate?: string;
} & DrawerProps;

const WebTrafficActivityDetailDrawer = ({ data, startDate, endDate, onClose }: Props) => {
  const name = data?.name;
  const tld = data?.tld;
  const displayName = name ?? tld;
  const sectorName = data?.sector?.name;
  const revRangeDesc = getRevenueRangeDescription(data?.revRange?.id);

  return (
    <Drawer open={data != null} onClose={onClose} placement="right" width={587}>
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div className={styles.header}>
          <CompanyLogo name={name} domain={tld} size="medium" />
          <div className={styles.title}>
            <Text variant="label">{displayName}</Text>
            <Text className={styles.subtitle} variant="caption1">
              {sectorName && <div>{sectorName}</div>}
              {revRangeDesc && <div>{revRangeDesc}</div>}
            </Text>
          </div>
          <Tooltip body={tld}>
            <Link
              className={styles.tld}
              to={`//${tld}`}
              target="_blank"
              variant="body2"
              color="static-grey"
            >
              {tld}
            </Link>
          </Tooltip>
        </div>
        <CompanyTrendPanel data={data} startDate={startDate} endDate={endDate} />
        <CompanyTotalVisitsPanel data={data} startDate={startDate} endDate={endDate} />
      </Space>
    </Drawer>
  );
};

export default WebTrafficActivityDetailDrawer;
