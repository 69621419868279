import {
  COLOR_AQUA_300,
  COLOR_AQUA_400,
  COLOR_AQUA_500,
  COLOR_AQUA_600,
  COLOR_AQUA_700,
  COLOR_AQUA_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_AQUAS = [
  COLOR_AQUA_300,
  COLOR_AQUA_400,
  COLOR_AQUA_500,
  COLOR_AQUA_600,
  COLOR_AQUA_700,
  COLOR_AQUA_800,
];
