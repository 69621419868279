import { Trans, t } from '@lingui/macro';
import moment from 'moment';

import { PixelType } from '@/api/pixel';
import { Text } from '@/components/typography';
import { DateFormatMD, USDateViewFormat } from '@/constants/formats';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './PixelEditDetails.module.scss';

type Props = {
  pixel: PixelType;
};

const PixelEditDetails = ({ pixel }: Props) => {
  return (
    <div>
      <Text variant="label">
        <Trans>Pixel Details:</Trans>
      </Text>
      <div className={styles.detailsContainer}>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Total Impressions:</Trans>
          </Text>{' '}
          <Text variant="body1">{numberFormat(pixel.metrics?.impressions)}</Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Created on:</Trans>
          </Text>{' '}
          <Text variant="body1">{moment(pixel.created_at).format(USDateViewFormat)}</Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Created by:</Trans>
          </Text>{' '}
          <Text variant="body1">{pixel.created_by?.name ?? t`Unknown`}</Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Status:</Trans>
          </Text>{' '}
          <Text variant="body1">
            {pixel.status === 'paused' ? <Trans>Paused</Trans> : <Trans>Active</Trans>}
          </Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Updated on:</Trans>
          </Text>{' '}
          <Text variant="body1">{moment(pixel.updated_at).format(USDateViewFormat)}</Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Last Updated by:</Trans>
          </Text>{' '}
          <Text variant="body1">{pixel.updated_by?.name || t`Unknown`}</Text>
        </div>
        <div>
          <Text variant="label" weight="bold">
            <Trans>Last Impression Date:</Trans>
          </Text>{' '}
          <Text variant="body1">
            {pixel.metrics?.lastImpressionDate
              ? moment(pixel.metrics?.lastImpressionDate).format(DateFormatMD)
              : t`N/A`}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default PixelEditDetails;
