import blockedEmailDomains from '@/constants/blockedEmailDomains';

const DOMAIN_REGEX =
  /^(((?!\\-))(xn\\-\\-)?[a-z0-9\-_]{0,63}[a-z0-9]{1,1}\.)*(xn\\-\\-)?([a-z0-9\\-]{1,63}|[a-z0-9\\-]{1,30})\.[a-zA-Z0-9]{2,}$/i;

const PASSWORD_PATTERN =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*[@,{}[\]:';<>?/!$%^&*()_+|~\-=\\.#`§]).{8,128}$/;

export const EMAIL_PATTERN =
  // eslint-disable-next-line no-control-regex
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

export const isDomainValid = (domain: string) => {
  return (
    DOMAIN_REGEX.test(domain) && !domain.trim().startsWith('www.') && !domain.trim().startsWith('-')
  );
};

export const isEmailValid = (email: string) => {
  return EMAIL_PATTERN.test(email);
};

export const isBusinessEmail = (email: string) => {
  const emailDomain = email.split('@')[1].toLowerCase();
  return blockedEmailDomains.indexOf(emailDomain) === -1;
};

export const isPasswordValid = (password: string) => {
  return PASSWORD_PATTERN.test(password);
};

export const isSalesforceReportUrlValid = (url?: string) => {
  try {
    if (!url) {
      return true;
    }

    const sfUrl = new URL(url);
    const isReportPath = /^\/lightning\/r\/Report\//.test(sfUrl.pathname);
    const isSfHost = /lightning\.force\.com$/.test(sfUrl.hostname);
    return isReportPath && isSfHost;
  } catch {
    return false;
  }
};
