import { Trans, t } from '@lingui/macro';
import { useMemo } from 'react';

import { CampaignActivityDataType, useCampaignActivityFitScore } from '@/api/campaign';
import { Flex } from '@/components/Flex';
import { Legend, LegendItem } from '@/components/Legend';
import Table, { ColumnsType } from '@/components/Table';
import { KpiMetric } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link, Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { COLOR_GREEN_100, COLOR_RED_300 } from '@/styles/palette';

import styles from './CampaignFitScoringTable.module.scss';

const getColor = ({ best, worst }: { best: boolean; worst: boolean }) => {
  if (best) {
    return COLOR_GREEN_100;
  }
  if (worst) {
    return COLOR_RED_300;
  }
  return undefined;
};

type Props = {
  campaignIds: string[];
};

const CampaignFitScoringTable = ({ campaignIds }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;

  const { fitScoreData, isLoading, error } = useCampaignActivityFitScore(
    campaignIds,
    startDate,
    endDate,
    audience?.id,
  );

  const baseAccounts = fitScoreData?.length && fitScoreData[0].baseAccounts;

  const columns: ColumnsType<CampaignActivityDataType> = useMemo(
    () => [
      {
        title: t`Campaign`,
        key: 'name',
        fixed: 'left',
        width: 300,
        render: (_, field) => (
          <Flex className={styles.titleContainer} vertical>
            <Link variant="caption1" weight="bold" to={ROUTES.campaignById(field.ad.id)}>
              {field.ad.name}
            </Link>
            <Text variant="caption1">
              {field.ad.vendor.name} - {field.ad.vendor.channel?.name}
            </Text>
          </Flex>
        ),
      },
      {
        title: t`Visits`,
        key: 'visits',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.allVisitsBest, worst: field.allVisitsWorst })}
            label={<Trans>Visits</Trans>}
            value={numberFormat(field.allVisits)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.engagedCompaniesBest,
              worst: field.engagedCompaniesWorst,
            })}
            label={<Trans>Engaged Companies</Trans>}
            value={numberFormat(field.percentOfEngagedCompanies, { isPercent: true, precision: 1 })}
            caption={numberFormat(field.engagedCompanies)}
            captionLink={ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              campaignIds: field.ad.id,
              'metrics.visits': '[1,]',
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Visit`,
        key: 'spendPerVisit.total',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.spendPerVisitBest, worst: field.spendPerVisitWorst })}
            label={<Trans>$/Visit</Trans>}
            value={numberFormat(field.spendPerVisit, { isCurrency: true, precision: 2 })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Pipeline Influenced`,
        key: 'pipelineInfluenced.perSpend',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.pipelineInfluencedBest,
              worst: field.pipelineInfluencedWorst,
            })}
            label={<Trans>$/Pipeline Influenced</Trans>}
            value={numberFormat(field.pipelineInfluencedPerSpend, {
              isMultiplier: true,
              precision: 1,
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`% Opps Influenced`,
        key: 'opportunityInfluenced.percent',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.opportunityInfluencedBest,
              worst: field.opportunityInfluencedWorst,
            })}
            label={<Trans>% Opps Influenced</Trans>}
            value={numberFormat(field.percentOpportunitiesInfluenced, {
              isPercent: true,
              precision: 0,
            })}
            caption={numberFormat(field.opportunitiesInfluenced)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Fit Score`,
        key: 'fitScore.score',
        fixed: 'right',
        sorter: true,
        sortOrder: 'descend',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.fitScoreBest, worst: field.fitScoreWorst })}
            label={<Trans>Fit Score</Trans>}
            value={numberFormat(field.fitScore, { precision: 1 })}
            error={error}
            isLoading={false}
          />
        ),
      },
    ],
    [audience],
  );

  return (
    <Panel
      title={
        <Trans>
          Fit Score - {audience ? audience.name : t`TAM`}
          {baseAccounts != null ? ' ' + t`(${numberFormat(baseAccounts)} Companies)` : ''}
        </Trans>
      }
      infoPopover={{
        title: t`Fit Score`,
        body: (
          <Trans>
            The Campaign Fit Score is an analysis of 5 major metrics used to calculate campaign
            performance from 1-100.{' '}
            <Link
              variant="body2"
              color="static-white"
              to="https://support.channel99.com/hc/en-us/articles/21697895853083-C99-Platform-Definitions#h_01HXYZVHZYTEZ1HTMG79PCB1DJ"
              target="_blank"
            >
              Learn More
            </Link>
          </Trans>
        ),
      }}
      noPadding
    >
      <Legend noToggleVisibility>
        <LegendItem value="highest" color={COLOR_GREEN_100} name={t`Highest Performer`} />
        <LegendItem value="lowest" color={COLOR_RED_300} name={t`Lowest Performer`} />
      </Legend>
      <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            columns={columns}
            dataSource={fitScoreData}
            emptyMessage={t`Please select rows in the table above and click the Score button to see your fit scores.`}
            loading={isLoading}
            error={error}
            showHeader={false}
            pagination={false}
            rowKey={(record) => record.ad.id}
            scroll={{ x: 1300 }}
          />
        </div>
      </div>
    </Panel>
  );
};

export default CampaignFitScoringTable;
