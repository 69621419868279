import { Col, Row } from 'antd';

import { Flex } from '@/components/Flex';

import CampaignDetailKPIMetrics from './CampaignDetailKPIMetrics';
import CampaignEfficiencyChart from './CampaignEfficiencyChart';
import CampaignOpportunityInfluenceChartTable from './CampaignOpportunityInfluenceChartTable';
import CampaignReturnOnMarketingSpendChart from './CampaignReturnOnMarketingSpendChart';
import CampaignTotalVisitsChart from './CampaignTotalVisitsChart';

type Props = {
  campaignName?: string;
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignDetailContent = ({ campaignName, campaignIds, isLoading }: Props) => {
  return (
    <Flex vertical gap="large" fullWidth>
      <CampaignDetailKPIMetrics campaignIds={campaignIds} isLoading={isLoading} />
      <CampaignTotalVisitsChart campaignIds={campaignIds} isLoading={isLoading} />
      <Row gutter={[24, 24]}>
        <Col md={12} sm={24} xs={24}>
          <CampaignReturnOnMarketingSpendChart
            campaignName={campaignName}
            campaignIds={campaignIds}
            isLoading={isLoading}
          />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <CampaignEfficiencyChart campaignIds={campaignIds} isLoading={isLoading} />
        </Col>
      </Row>
      <CampaignOpportunityInfluenceChartTable campaignIds={campaignIds} isLoading={isLoading} />
    </Flex>
  );
};

export default CampaignDetailContent;
