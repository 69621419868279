import { Trans } from '@lingui/macro';

import { useEventLog, useEventLogCSVDownload } from '@/api/eventLog';
import EventLogActionMenu from '@/app/event-log/EventLogActionMenu';
import { useInstantSearchState } from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { FileDownloadModal } from '@/features/FileDownloadModal';

import EventLogTable from './EventLogTable';

const EventLogContent = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const { queryState } = useInstantSearchState();

  const { eventLog, totalResults, isLoading, error } = useEventLog(
    getSolQueryParamsNewFromQueryState(queryState),
    startDate,
    endDate,
  );

  const {
    isLoading: isDownloadingFile,
    beginDownload,
    cancelDownload,
  } = useEventLogCSVDownload(getSolQueryParamsNewFromQueryState(queryState), startDate, endDate);

  return (
    <Panel
      title={<Trans>Event Log Entries</Trans>}
      actions={
        <EventLogActionMenu
          totalResults={totalResults}
          isLoading={isLoading}
          isDownloadingCSV={false}
          onDownloadCSV={beginDownload}
        />
      }
    >
      <EventLogTable
        data={eventLog}
        totalResults={totalResults}
        isLoading={isLoading}
        error={error}
      />
      <FileDownloadModal open={isDownloadingFile} onAbortDownload={cancelDownload} />
    </Panel>
  );
};

export default EventLogContent;
