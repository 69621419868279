/* eslint-disable no-restricted-imports */
import { Switch as AntdSwitch, SwitchProps as AntdSwitchProps } from 'antd';
import DisabledContext from 'antd/es/config-provider/DisabledContext';
import classNames from 'classnames';
import { useContext, useEffect, useRef } from 'react';

import styles from './Switch.module.scss';
import SwitchGroup from './SwitchGroup';
import SwitchGroupContext from './SwitchGroupContext';

interface SwitchProps extends AntdSwitchProps {
  value?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  name?: string;
  isDisabled?: boolean;
  skipGroup?: boolean;
  required?: boolean;
}

const Switch = ({ className, isDisabled, skipGroup, ...rest }: SwitchProps) => {
  const contextDisabled = useContext(DisabledContext);
  const switchGroup = useContext(SwitchGroupContext);
  const prevValue = useRef(rest.value);
  const mergedDisabled = (switchGroup?.isDisabled || isDisabled) ?? contextDisabled;

  if (
    import.meta.env.NODE_ENV !== 'production' &&
    'checked' in rest &&
    !!switchGroup &&
    !('value' in rest)
  ) {
    console.warn('`value` is not a valid prop, do you mean `checked`?');
  }

  useEffect(() => {
    switchGroup?.registerValue(rest.value);
  }, []);

  useEffect(() => {
    if (skipGroup) {
      return;
    }

    if (rest.value !== prevValue.current) {
      switchGroup?.cancelValue(prevValue.current);
      switchGroup?.registerValue(rest.value);
      prevValue.current = rest.value;
    }

    return () => switchGroup?.cancelValue(rest.value);
  }, [rest.value]);

  const switchProps: SwitchProps = { ...rest };
  if (switchGroup && !skipGroup) {
    switchProps.onChange = (...args) => {
      rest?.onChange?.(...args);
      switchGroup.toggleOption?.({ value: rest.value });
    };
    switchProps.name = switchGroup.name;
    switchProps.checked = switchGroup.value.includes(rest.value);
  }

  return (
    <AntdSwitch
      className={classNames(styles.switch, className)}
      disabled={mergedDisabled}
      {...switchProps}
    />
  );
};

Switch.Group = SwitchGroup;

export default Switch;
