import { t } from '@lingui/macro';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import MenuCloseIcon from '@/assets/svg/menu-close-icon.svg?react';
import { ActionIcon } from '@/components/buttons';
import { Text } from '@/components/typography';

import styles from './MenuGroup.module.scss';

type Props = {
  className?: string;
  onMenuClose: () => void;
};

const MenuGroup = ({ className, children, onMenuClose, ...rest }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.group, className)} {...rest}>
      <ActionIcon
        className={styles.closeButton}
        color="black"
        size="small"
        icon={<MenuCloseIcon />}
        aria-label={t`Close Menu`}
        onClick={onMenuClose}
      />
      <Text variant="subhead" weight="bold">
        {children}
      </Text>
    </div>
  );
};

export default MenuGroup;
