import { Menu as AntdMenu, MenuProps } from 'antd';
import { ItemType } from 'antd/es/menu/interface';
import classNames from 'classnames';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useFlags } from '@/constants/featureFlags';
import { useMe } from '@/providers/User';

import styles from './Menu.module.scss';
import MenuGroup from './MenuGroup';
import { getBottomMenuItems, getTopMenuItems } from './constants';
import { MenuItemType } from './types';

const getSelectedKey = (flags: LDFlagSet, pathname: string) => {
  const [, appPath] = pathname.split('app/');

  if (appPath) {
    const objectPath = appPath.split('/')[0];
    return `/app/${objectPath}`;
  }

  return `/app/`;
};

/**
 * strip out feature-flagged menu items or menu items which cannot be accessed by non-privileged
 * users
 */
const filterMenuItems = (
  menuItems: ItemType<MenuItemType>[],
  flags: LDFlagSet,
  isPaidUser: boolean,
  isAdminUser: boolean,
) => {
  return menuItems.filter((item) => {
    if (item == null) {
      return item;
    }

    if (item.type === 'item') {
      return (
        item.hidden?.(flags) !== true &&
        (!item.isPaid || (isPaidUser && item.isPaid)) &&
        (!item.isAdmin || (isAdminUser && item.isAdmin))
      );
    }

    return item;
  });
};

const wrapMenuItems = (
  menuItems: ItemType<MenuItemType>[],
  flags: LDFlagSet,
  isPaidUser: boolean,
  isAdminUser: boolean,
  handleMenuClose: () => void,
) => {
  return filterMenuItems(menuItems, flags, isPaidUser, isAdminUser).map(
    (item: ItemType<MenuItemType>) => {
      if (item == null) {
        return item;
      }

      // delete since featureFlag and isPaid are not valid DOM attributes
      if (item.type === 'item') {
        delete item?.isPaid;
        delete item?.hidden;
      }

      if (item.type === 'submenu') {
        const { children, ...rest } = item;

        // Wrap children in a group with same name and modify styles a bit
        return {
          ...rest,
          popupOffset: [1, 0],
          popupClassName: styles.popup,
          children: [
            {
              type: 'group',
              label: <MenuGroup onMenuClose={handleMenuClose}>{rest.label}</MenuGroup>,
              children: filterMenuItems(children, flags, isPaidUser, isAdminUser),
            },
          ] as ItemType<MenuItemType>[],
        };
      }

      return item;
    },
  );
};

const Menu = () => {
  const user = useMe();
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const subMenu = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const flags = useFlags();

  const isAdminUser = !!user?.isAdmin;
  const isPaidUser = !!user?.isPaid;

  const handleOpenChange = (openKeys: string[]) => {
    setOpenKeys([...openKeys]);
  };

  const handleMenuClose = () => {
    setOpenKeys([]);
  };

  const topMenuItems = useMemo(
    () => wrapMenuItems(getTopMenuItems(), flags, isPaidUser, isAdminUser, handleMenuClose),
    [flags],
  );
  const bottomMenuItems = useMemo(
    () => wrapMenuItems(getBottomMenuItems(), flags, isPaidUser, isAdminUser, handleMenuClose),
    [flags],
  );

  const handleSelect: MenuProps['onClick'] = ({ key }) => {
    navigate(key);
  };

  return (
    <div className={styles.container}>
      <div
        ref={subMenu}
        className={classNames(styles.subMenu, { [styles.isVisible]: openKeys.length > 0 })}
        data-testid="subMenu"
      />
      <div className={styles.menuContainer}>
        <AntdMenu<MenuItemType>
          className={styles.menu}
          selectedKeys={[getSelectedKey(flags, pathname)]}
          openKeys={openKeys}
          mode="vertical"
          triggerSubMenuAction="click"
          items={topMenuItems}
          getPopupContainer={() => subMenu.current || document.body}
          onClick={handleSelect}
          onOpenChange={handleOpenChange}
        />
        <AntdMenu<MenuItemType>
          className={styles.menu}
          selectedKeys={[getSelectedKey(flags, pathname)]}
          openKeys={openKeys}
          mode="vertical"
          triggerSubMenuAction="click"
          items={bottomMenuItems}
          getPopupContainer={() => subMenu.current || document.body}
          onClick={handleSelect}
          onOpenChange={handleOpenChange}
        />
      </div>
    </div>
  );
};

export default Menu;
