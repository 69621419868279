import { Trans } from '@lingui/macro';
import { useStytchMemberSession } from '@stytch/react/dist/b2b';
import { Row, Space } from 'antd';
import { useState } from 'react';

import { GlobalLoader } from '@/components/GlobalLoader';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { useLogOut } from '@/hooks/useLogOut';
import { ROUTES } from '@/router';

import UpdatePasswordModal from './UpdatePasswordModal';

type Props = {
  isC99Superuser: boolean;
  isEditing: boolean;
  isSaving: boolean;
  onEditChange: (isEditing: boolean) => void;
};

const ProfileHeader = ({ isC99Superuser, isEditing, isSaving, onEditChange }: Props) => {
  const { session } = useStytchMemberSession();
  const { logOut } = useLogOut();
  const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(false);
  const [isLoggingOut, setLoggingOut] = useState(false);

  const userUsedPasswordAuth = (session?.authentication_factors ?? []).some(
    (authFactor) => authFactor.type === 'password',
  );

  const handleLogOut = async () => {
    setLoggingOut(true);
    await logOut();
    setLoggingOut(false);
  };

  if (isLoggingOut) {
    return <GlobalLoader isLoggingOut />;
  }

  return (
    <Row align="middle" justify="space-between">
      <ReturnToLink previousPage />
      <Space size={16}>
        {isEditing ? (
          <>
            <Button
              color="black"
              variant="secondary"
              isDisabled={isSaving}
              onClick={() => onEditChange(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button color="green" variant="primary" type="submit" isLoading={isSaving}>
              <Trans>Save</Trans>
            </Button>
          </>
        ) : (
          <>
            <Button color="black" variant="secondary" onClick={() => onEditChange(true)}>
              <Trans>Edit Profile</Trans>
            </Button>
            {userUsedPasswordAuth && (
              <Button
                color="black"
                variant="secondary"
                onClick={() => setShowUpdatePasswordModal(true)}
              >
                <Trans>Update Password</Trans>
              </Button>
            )}
            {isC99Superuser && (
              <Button color="black" variant="secondary" to={ROUTES.loginAs.path}>
                <Trans>Login As</Trans>
              </Button>
            )}
            <Button color="black" variant="secondary" onClick={handleLogOut}>
              <Trans>Log Out</Trans>
            </Button>
          </>
        )}
      </Space>
      <UpdatePasswordModal
        open={showUpdatePasswordModal}
        onClose={() => setShowUpdatePasswordModal(false)}
      />
    </Row>
  );
};

export default ProfileHeader;
