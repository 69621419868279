import { t } from '@lingui/macro';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import { Button } from '@/components/buttons';
import { Title } from '@/components/typography';

import styles from './ConfirmModal.module.scss';
import Modal from './Modal';
import { ModalProps } from './types';

type Props = ModalProps & {
  okText?: ReactNode;
  cancelText?: ReactNode;
  isLoading?: boolean;
  textAlign?: 'center' | 'left' | 'right';
};

const ConfirmModal = ({
  open,
  width = 667,
  okText = t`Ok`,
  cancelText = t`Cancel`,
  children,
  isLoading = false,
  textAlign = 'center',
  onOk,
  onCancel,
  ...rest
}: PropsWithChildren<Props>) => {
  return (
    <Modal
      {...rest}
      className={classNames(styles.confirm, { [styles[textAlign]]: textAlign })}
      open={open}
      width={width}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancelButton"
          color="black"
          variant="secondary"
          size="large"
          isDisabled={isLoading}
          onClick={onCancel}
        >
          {cancelText}
        </Button>,
        <Button
          key="okButton"
          color="black"
          variant="primary"
          size="large"
          isLoading={isLoading}
          onClick={onOk}
        >
          {okText}
        </Button>,
      ]}
    >
      <Title level={4} weight="light">
        {children}
      </Title>
    </Modal>
  );
};

export default ConfirmModal;
