import {
  COLOR_PINK_300,
  COLOR_PINK_400,
  COLOR_PINK_500,
  COLOR_PINK_600,
  COLOR_PINK_700,
  COLOR_PINK_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_PINKS = [
  COLOR_PINK_300,
  COLOR_PINK_400,
  COLOR_PINK_500,
  COLOR_PINK_600,
  COLOR_PINK_700,
  COLOR_PINK_800,
];
