import {
  COLOR_BLUE_300,
  COLOR_BLUE_400,
  COLOR_BLUE_500,
  COLOR_BLUE_600,
  COLOR_BLUE_700,
  COLOR_BLUE_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_BLUES = [
  COLOR_BLUE_300,
  COLOR_BLUE_400,
  COLOR_BLUE_500,
  COLOR_BLUE_600,
  COLOR_BLUE_700,
  COLOR_BLUE_800,
];
