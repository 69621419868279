import { t } from '@lingui/macro';

import { InstanceDetailDataType } from '@/api/organization';
import Form, { TextInput } from '@/components/Form';
import { Panel } from '@/components/panels';
import { Link, Text } from '@/components/typography';
import { maxObjectNameLength } from '@/constants/numbers';

import { FORM_FIELD } from './CompanySettings';
import styles from './CompanySettingsGeneralInfo.module.scss';

type Props = {
  instance: InstanceDetailDataType;
  isEditMode: boolean;
  isSaving: boolean;
};

const CompanySettingsGeneralInfo = ({ instance, isEditMode, isSaving }: Props) => {
  return (
    <Panel title={t`General Information`} style={{ height: '100%' }}>
      <Form.Item
        name={FORM_FIELD.COMPANY_NAME}
        label={t`Company Name`}
        initialValue={instance!.name}
        rules={[{ required: true, message: t`Company Name is required` }]}
        required={false}
      >
        {isEditMode ? (
          <TextInput
            className={styles.input}
            maxLength={maxObjectNameLength}
            isDisabled={isSaving}
          />
        ) : (
          <Text variant="body1">{instance?.name}</Text>
        )}
      </Form.Item>
      <Form.Item label={t`Company Website`}>
        <Link to={`//${instance?.domain}`} color="aqua" target="_blank">
          {instance?.domain}
        </Link>
      </Form.Item>
    </Panel>
  );
};

export default CompanySettingsGeneralInfo;
