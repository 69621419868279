import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './KpiMetricGroup.module.scss';

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const KpiMetricGroup = ({ className = '', ...rest }: PropsWithChildren<Props>) => (
  <div {...rest} className={classNames(styles.group, className)} />
);

export default KpiMetricGroup;
