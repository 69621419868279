import { Trans, t } from '@lingui/macro';
import { Row, Space } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PixelType, mutatePixelUpdate, usePixelById } from '@/api/pixel';
import Form from '@/components/Form';
import PageSpinner from '@/components/PageSpinner';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import Error404 from '@/components/errors/Error404';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import SolGraphQLError from '@/error/SolGraphQLError';
import useErrorDisplay from '@/error/useErrorDisplay';
import { ROUTES } from '@/router';

import PixelForm, { FormType } from '../PixelForm';
import PixelEditDetails from './PixelEditDetails';

const PixelEdit = () => {
  const { pixel: pixelId } = useParams();
  const navigate = useNavigate();
  const { pixel, isLoading, error, mutate: mutatePixelById } = usePixelById(pixelId);
  const [isUpdatingPixel, setIsUpdatingPixel] = useState<boolean>();
  const [form] = Form.useForm();
  const displayErrors = useErrorDisplay();

  const handleReturnToListView = () => {
    navigate(ROUTES.pixels.path);
  };

  const handleFinish = async (values: FormType) => {
    if (pixel) {
      setIsUpdatingPixel(true);

      try {
        const { audience, channel, vendor, name, description } = values;
        const updatedPixel = { ...pixel, ...values } as PixelType;
        await mutatePixelUpdate({
          id: pixel.id,
          name,
          description,
          channel_id: channel.id,
          vendor_id: vendor.id,
          audience_id: audience.id,
        });
        mutatePixelById({
          pixels: {
            getById: updatedPixel,
          },
        });
        navigate(ROUTES.pixels.path, {
          state: {
            successMessage: t`Pixel was successfully updated.`,
            optimisticUpdate: updatedPixel,
          },
        });
      } catch (e) {
        displayErrors({ error: e, form });
      }

      setIsUpdatingPixel(false);
    }
  };

  if (error instanceof SolGraphQLError && error.is404()) {
    return <Error404 message={<Trans>The audience you're looking for does not exist</Trans>} />;
  }

  return (
    <Page title={t`Edit Pixel`}>
      <Form form={form} validateTrigger="onBlur" onFinish={handleFinish}>
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <ReturnToLink route={ROUTES.pixels.path} routeName={t`Pixel Overview`} />
            <Space size={16}>
              <Button color="black" variant="secondary" onClick={handleReturnToListView}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                color="green"
                variant="primary"
                type="submit"
                isDisabled={isUpdatingPixel}
                isLoading={isUpdatingPixel}
              >
                <Trans>Save</Trans>
              </Button>
            </Space>
          </Row>
          {isLoading && !pixel ? (
            <PageSpinner />
          ) : (
            <Panel size="L" title={<Trans>Edit Pixel</Trans>}>
              <Space direction="vertical" size="large">
                <PixelForm pixel={pixel} disabled={isUpdatingPixel} isEdit />
                {pixel && <PixelEditDetails pixel={pixel} />}
              </Space>
            </Panel>
          )}
        </Space>
      </Form>
    </Page>
  );
};

export default PixelEdit;
