import clamp from 'lodash/clamp';

import { ALL_SEMANTIC_COLORS } from '@/constants/colors';
import { COLOR_GRAY_700 } from '@/styles/palette';

/**
 * Generates a consistent, random background color for a given name.
 */
export const stringToColor = (str?: string) => {
  // if no value is passed, always return transparent color otherwise a rerender would show a new
  // color which would will give strange effects when an interface is loading and gets rerendered a
  // few consecutive times
  if (!str) {
    return COLOR_GRAY_700;
  }

  // Rather than use a random number, we want to make sure we'll get the same color assigned to the
  // same name each time.
  // https://en.wikipedia.org/wiki/Linear_congruential_generator
  const numColors = ALL_SEMANTIC_COLORS.length;
  const charCodes = [...str].map((letter) => letter.charCodeAt(0));
  const len = charCodes.length;

  const a = (len % (numColors - 1)) + 1;
  const c = charCodes.reduce((current, next) => current + next) % numColors;

  let random = charCodes[0] % numColors;
  for (let i = 0; i < len; i++) {
    random = (a * random + c) % numColors;
  }

  return ALL_SEMANTIC_COLORS[random];
};

export const isLowLuminance = (color: string) => {
  if (color.startsWith('#') && color.length === 7) {
    const r = parseInt(color.substring(1, 3), 16);
    const g = parseInt(color.substring(3, 5), 16);
    const b = parseInt(color.substring(5, 7), 16);

    const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;
    return luminance < 0.5;
  }
  return false;
};

export const getOpacityInHex = (opacity: number) =>
  Math.round(clamp(opacity, 0, 1) * 255)
    .toString(16)
    .padStart(2, '0');
