import { t } from '@lingui/macro';
import classNames from 'classnames';

import LoadingIndicatorIcon from '@/assets/svg/loading.svg?react';

import styles from './LoadingIndicator.module.scss';

type Props = {
  className?: string;
  center?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const LoadingIndicator = ({ className, center = false, size = 'medium' }: Props) => (
  <div className={styles.wrapper} aria-label={t`Loading...`}>
    <LoadingIndicatorIcon
      className={classNames(styles.indicator, styles[`size-${size}`], className, {
        [styles.center]: center,
      })}
      aria-label={t`Loading...`}
    />
  </div>
);

export default LoadingIndicator;
