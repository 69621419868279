import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';

import { HealthCenterPodType } from '@/api/healthCenter';
import { Flex } from '@/components/Flex';
import InfoPopover from '@/components/InfoPopover';
import { Button } from '@/components/buttons';
import { Text, Title } from '@/components/typography';
import { useMe } from '@/providers/User';

import styles from './HealthPod.module.scss';
import HealthPodStatusTag from './HealthPodStatusTag';

type HealthPodProps = {
  icon: ReactElement;
  title: string;
  titleInfoPopover?: {
    title: React.ReactNode;
    body: React.ReactNode;
  };
  instructions: string;
  link?: string;
  data?: HealthCenterPodType;
};

const HealthPod = ({
  icon,
  title,
  titleInfoPopover,
  instructions,
  link,
  data,
  children,
}: PropsWithChildren<HealthPodProps>) => {
  const user = useMe();
  const isPaidUser = user?.isPaid;
  const { isAvailable, isComplete, isFree } = data ?? {};
  const notIncludedInPlan = !isPaidUser && !isFree;

  const disabled = notIncludedInPlan || !isAvailable;

  return (
    <div className={classNames(styles.panel, { [styles.disabled]: disabled })}>
      <div className={styles.heading}>
        <div className={styles.icon}>{icon}</div>
        <Title level={3} weight="bold" className={styles.title}>
          <Flex gap={4} align="center">
            {title}
            {titleInfoPopover && (
              <InfoPopover title={titleInfoPopover.title} body={titleInfoPopover.body} />
            )}
          </Flex>
        </Title>
        <Button color="black" variant="secondary" to={link} isDisabled={disabled}>
          <Trans>Take Me There</Trans>
        </Button>
      </div>
      <div className={styles.content}>
        <Text variant="footnote">{instructions}</Text>
        <Text variant="body2" className={styles.detailContainer}>
          {children}
        </Text>
      </div>
      <div className={styles.footer}>
        <HealthPodStatusTag
          isAvailable={isAvailable}
          notIncludedInPlan={notIncludedInPlan}
          isComplete={isComplete}
          isDisabled={disabled}
          link={link}
        />
      </div>
    </div>
  );
};

export default HealthPod;
