import { UserType } from '../user/userType';

export interface BaseMetadataType {
  id: string;
  name: string;
  description?: string;
  created_at?: string;
  created_by?: UserType;
  updated_at?: string;
  updated_by?: UserType;
}

export type VisitTrafficType = {
  allVisits: number;
  botVisits: number;
  otherVisits: number;
  targetVisits: number;
  unresolvedVisits: number;
};

export type OptionType = {
  id: string;
  name: string;
};

export type PagingOptionType = {
  results: OptionType[];
  hasNext: boolean;
};

export type CompanyType = {
  id: string;
  name: string;
  tld: string;
};

export type SectorType = OptionType;

export type RevenueRangeType = OptionType & {
  id: REVENUE_RANGE_ID;
  shortName?: string;
  name: string;
};

export type RegionType = OptionType;

export enum TRAFFIC_TYPES {
  ALL = 'all',
  AUDIENCE = 'audience',
  BOT = 'bot',
  OTHER = 'other',
  TAM = 'tam',
  TARGET = 'target',
  UNRESOLVED = 'unresolved',
}

export enum REVENUE_RANGE_ID {
  ENTERPRISE = 'rrng_enterprise',
  MID_MARKET = 'rrng_mid_market',
  SMALL_MEDIUM = 'rrng_small_medium',
  VERY_SMALL = 'rrng_very_small',
}

export type DisplayNamesGQLResponse = {
  displayNames: {
    get: {
      id: string;
      name: string;
    }[];
  };
};

export type CSVJobStatus = {
  id: string;
  status: 'completed' | 'error' | 'processing' | 'unknown';
  url: string;
};

export type DownloadCSVBeginGQLResponse = {
  csv: {
    begin: CSVJobStatus;
  };
};

export type DownloadCSVStatusGQLResponse = {
  csv: {
    status: CSVJobStatus;
  };
};

export type OpportunitiesTrendData = {
  sundayOfWeek: string;
  opportunities: number;
  revenue: number;
};

export type OpportunitiesInfluencedDataType = {
  pipeline: {
    data: OpportunitiesTrendData[];
    totalOpportunities: number;
    totalRevenue: number;
  };
  recentDeals: {
    data: OpportunitiesTrendData[];
    totalOpportunities: number;
    totalRevenue: number;
  };
};

export type VisitTrafficTrendType = VisitTrafficType & {
  sundayOfWeek: string;
};

export type TrafficVisitTrendGQLResponse = {
  visualization: {
    trafficVisitTrend2: VisitTrafficTrendType[];
  };
};
