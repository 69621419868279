import {
  useStytchB2BClient,
  useStytchMember,
  useStytchMemberSession,
  useStytchOrganization,
} from '@stytch/react/dist/b2b';
import { Member, Organization } from '@stytch/vanilla-js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

import { UserInstanceType } from '@/api/organization';
import { UserProfileType } from '@/api/user';
import { sessionDurationMinutes } from '@/constants/numbers';
import { getForeignSuperuserInstance, setLastVisitedOrgSlug } from '@/helper/common';
import { updateUserContext } from '@/helper/launchdarkly';
import { updatePendoUser } from '@/helper/pendo';
import { updateSentryTags } from '@/helper/sentry';

import { EnhancedMember, UserContextType } from './types';

export const UserContext = createContext<UserContextType | undefined>(undefined);

export const createUserContext = (member: Member, organization: Organization) => {
  const instanceId = organization.organization_slug.replace(/^inst~/, 'inst:');

  const roles = member.roles.map((role) => role.role_id);
  const isC99Superuser = roles.some((role: string) => role === 'c99.role.superuser');

  let currentOrg: UserInstanceType;
  const foreignInstance = getForeignSuperuserInstance();
  // C99 superusers can login as superuser to other orgs
  if (isC99Superuser && foreignInstance != null) {
    currentOrg = foreignInstance;
  } else {
    currentOrg = {
      id: organization.organization_id,
      name: organization.organization_name,
      slug: organization.organization_slug,
      instanceId,
    } as UserInstanceType;
  }

  let pictureUrl = null;
  const registrationWithPicture = ((member as EnhancedMember)?.oauth_registrations ?? []).find(
    (registration) => registration.profile_picture_url != null,
  );
  if (registrationWithPicture) {
    pictureUrl = registrationWithPicture?.profile_picture_url;
  }

  return {
    id: `user:${member.email_address.replace('.', '~')}`,
    stytchId: member.member_id,
    email: member.email_address,
    name: member.name,
    pictureUrl,
    roles,
    currentOrg,
    isC99Superuser,
    isAdmin: roles.some((role: string) => role === 'c99.role.admin'),
    // TODO: Setting `isPaid` to true for now. We intend to eventually rework roles/permissions
    isPaid: true,
    status: member.status,
  } as UserProfileType;
};

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [foreignInstance, setForeignInstance] = useState<UserInstanceType | null>(null);
  const stytch = useStytchB2BClient();
  const { member } = useStytchMember();
  const { session } = useStytchMemberSession();
  const { organization } = useStytchOrganization();
  const ldClient = useLDClient();

  const user = useMemo(() => {
    if (member && organization && session) {
      return createUserContext(member, organization);
    } else {
      return undefined;
    }
  }, [member, session, organization, foreignInstance?.instanceId]);

  useEffect(() => {
    if (user) {
      // if user changes, update LD, Pendo, and Sentry contexts
      updateUserContext(user, ldClient);
      updatePendoUser(user);
      updateSentryTags(user);

      // cache the logged-in org so the user jumps straight there from /sign-in next time.
      setLastVisitedOrgSlug(user.currentOrg.slug);

      // Extend the session
      stytch.session.authenticate({
        session_duration_minutes: sessionDurationMinutes,
      });
    }
  }, [stytch, ldClient, user?.id, foreignInstance?.instanceId]);

  return (
    <UserContext.Provider value={{ user, setForeignInstance }}>{children}</UserContext.Provider>
  );
};
