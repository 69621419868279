import { Trans, t } from '@lingui/macro';
import { useMemo, useState } from 'react';

import { OptionType } from '@/api/common';
import {
  VendorSpendCellData,
  VendorSpendDataType,
  endSession,
  mutateVendorSpend,
  startOrContinueSession,
  useVendorSpend,
} from '@/api/vendorSpend';
import EditAddIcon from '@/assets/svg/edit-add-box.svg?react';
import PlusIcon from '@/assets/svg/plus.svg?react';
import PageSpinner from '@/components/PageSpinner';
import Prompt from '@/components/Prompt';
import Tooltip from '@/components/Tooltip';
import { ActionButton, Button } from '@/components/buttons';
import PageError from '@/components/errors/PageError';
import { Page } from '@/components/page';
import { addMissingDates } from '@/helper/vendorSpendHelper';
import useSessionLock from '@/hooks/useSessionLock';
import { useNotification } from '@/providers/Notification';

import styles from './VendorSpend.module.scss';
import VendorSpendAddVendor from './VendorSpendAddVendor';
import VendorSpendTable from './VendorSpendTable';

// 30 seconds
const REFRESH_SESSION_LOCK_TIME = 30000;

const VendorSpend = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { pushNotification, removeNotification } = useNotification();
  const { data, tagInstallationDate, isLoading, error, mutate } = useVendorSpend(isEditMode);
  const [changedData, setChangedData] = useState<VendorSpendDataType[] | undefined>();
  const [highlightedRowKeys, setHighlightedRowKeys] = useState<string[]>([]);
  const [showAddVendor, setShowAddVendor] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { startSessionLock, endSessionLock } = useSessionLock({
    startSession: startOrContinueSession,
    refreshSession: startOrContinueSession,
    endSession,
    keepAlivePingInterval: REFRESH_SESSION_LOCK_TIME,
  });
  const localData = useMemo(() => changedData ?? data ?? [], [changedData, data]);

  const tableHasData = localData.some((row) => row.data.length > 0);

  // Don't let users create more than 1 manual rows. Imported rows (userSupplied = false) can still
  // have a manual row alongside them.
  const disabledVendorIds = (changedData ?? data ?? [])
    ?.filter((d) => d.data?.[0].userSupplied === true)
    .map((d) => d.vendor.id);

  const totalsData = useMemo(() => {
    return localData.reduce<VendorSpendCellData[]>((acc, row) => {
      if (acc.length) {
        return row.data.map<VendorSpendCellData>((item) => {
          const accItem = acc.find((val) => val.firstDayOfMonth === item.firstDayOfMonth);
          if (accItem) {
            return { ...item, amount: accItem.amount + item.amount };
          } else {
            return { ...item };
          }
        });
      } else {
        return row.data;
      }
    }, []);
  }, [localData]);

  const changeEditMode = async (desiresEditMode: boolean): Promise<boolean> => {
    if (desiresEditMode) {
      const success = await startSessionLock();
      if (success) {
        removeNotification({ type: 'edit' });
        pushNotification({
          type: 'edit',
          message: t`You are currently editing your Vendor Spend, please click Save and Run to process changes`,
        });
        setIsEditMode(true);
      }
      return success;
    } else {
      // we don't care about errors on a lock release, Sol releases locks after 10 mins.
      endSessionLock();
      removeNotification({ type: ['edit', 'error'] });
      setIsEditMode(false);
      setShowAddVendor(false);
      return true;
    }
  };

  const handleBeforeUnload = () => {
    endSessionLock();
  };

  const handleShowAddVendorControls = async () => {
    // if we're already editing, allow it.
    if (isEditMode) {
      setShowAddVendor(true);
    } else {
      const success = await changeEditMode(true);
      const shouldShowAddVendor = !showAddVendor;
      if (shouldShowAddVendor && success) {
        setShowAddVendor(true);
      } else {
        setShowAddVendor(false);
      }
    }
  };

  const handleAddVendorRow = ({ vendor, channel }: { vendor: OptionType; channel: OptionType }) => {
    const rowId = crypto.randomUUID();

    // Use the same range of data as the computed totals, but starting with an amount of $0.
    let cellData = totalsData.map((item) => ({ ...item, amount: 0, userSupplied: true }));

    if (!cellData.length) {
      // We don't have any data from the server. No totals were calculated. This means the user is
      // adding the first row of the table. Fill in the data range starting from tag creation date.
      cellData = addMissingDates(tagInstallationDate);
    }

    setChangedData([
      {
        id: rowId,
        vendor: {
          id: vendor.id,
          name: vendor.name,
          channel: {
            id: channel.id,
            name: channel.name,
          },
        },
        data: cellData,
      },
      ...localData,
    ]);
    setHighlightedRowKeys((prev) => [...prev, rowId]);
    setShowAddVendor(false);
  };

  const handleCancel = () => {
    changeEditMode(false);
    setChangedData(undefined);
  };

  const handleSave = async () => {
    if (changedData != null) {
      setIsSaving(true);

      try {
        const response = await mutateVendorSpend(changedData);
        if (response.success) {
          pushNotification({
            type: 'success',
            message: t`Success! Vendor Spend data has been saved.`,
          });
        } else {
          throw new Error();
        }
      } catch {
        pushNotification({
          type: 'error',
          message: t`Apologies, but something went wrong. Please try to Save again.`,
        });
      }

      setIsSaving(false);
      changeEditMode(false);

      // drop our saved data once we've had a chance to resync with the DB
      await mutate();
      setChangedData(undefined);
    } else {
      changeEditMode(false);
    }
  };

  const handleDataChange = (newData: VendorSpendDataType[]) => {
    setChangedData(newData);
  };

  return (
    <Page title={t`Configure`} pageName={t`Vendor Spend`}>
      {isLoading ? (
        <PageSpinner />
      ) : error ? (
        <PageError
          message={<Trans>An error occurred while loading Vendor Spend</Trans>}
          detail={<Trans>Please refresh the page and try again</Trans>}
        />
      ) : (
        <div className={styles.content}>
          <Prompt
            message={t`Are you sure you want to exit without saving your Vendor Spend changes?`}
            shouldPreventNavigation={changedData != null}
            onBeforeUnload={handleBeforeUnload}
          />
          <div className={styles.actionBar}>
            <Button
              color="black"
              variant="secondary"
              icon={<PlusIcon />}
              isSelected={showAddVendor}
              isDisabled={isSaving}
              onClick={handleShowAddVendorControls}
            >
              <Trans>Vendor</Trans>
            </Button>
            <div className={styles.primaryActionBar}>
              {isEditMode && (
                <>
                  <Button
                    color="black"
                    variant="secondary"
                    isDisabled={isSaving}
                    onClick={handleCancel}
                  >
                    <Trans>Cancel</Trans>
                  </Button>
                  <Button
                    color="black"
                    variant="secondary"
                    isDisabled={changedData == null}
                    isLoading={isSaving}
                    onClick={handleSave}
                  >
                    <Trans>Save & Run</Trans>
                  </Button>
                </>
              )}
              {tableHasData && (
                <Tooltip
                  placement="topRight"
                  title={<Trans>Add/Edit</Trans>}
                  body={<Trans>Add or Edit Vendor Spend</Trans>}
                >
                  <ActionButton
                    color="black"
                    isSelected={isEditMode}
                    icon={<EditAddIcon />}
                    isDisabled={isSaving}
                    onClickCapture={() => changeEditMode(!isEditMode)}
                  />
                </Tooltip>
              )}
            </div>
          </div>
          {showAddVendor && (
            <VendorSpendAddVendor
              disabledVendorIds={disabledVendorIds}
              onClose={() => setShowAddVendor(false)}
              onAddVendor={handleAddVendorRow}
            />
          )}
          {data && (
            <VendorSpendTable
              data={localData}
              totalsData={totalsData}
              highlightedRowKeys={highlightedRowKeys}
              editMode={isEditMode}
              onDataChange={handleDataChange}
              onHighlightedRowKeysChange={setHighlightedRowKeys}
            />
          )}
        </div>
      )}
    </Page>
  );
};

export default VendorSpend;
