import { Trans, t } from '@lingui/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { Organization } from '@stytch/vanilla-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Form, { PasswordInput, TextInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { maxEmailLength, sessionDurationMinutes } from '@/constants/numbers';
import { getStytchAPIErrorMessage, isStytchAPIError } from '@/error/StytchError';
import { EMAIL_STANDARD_RULES } from '@/helper/ant-rule';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import RateLimit from '../RateLimitButton';
import styles from './TenantPasswordForm.module.scss';

type Props = {
  org: Organization;
  supportsPasswordAuth?: boolean;
};

enum FORM_FIELD {
  EMAIL = 'email',
  PASSWORD = 'password',
}

type FormType = {
  [FORM_FIELD.EMAIL]: string;
  [FORM_FIELD.PASSWORD]: string;
};

const TenantPasswordForm = ({ org }: Props) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const stytch = useStytchB2BClient();
  const [isLoading, setLoading] = useState(false);
  const { pushNotification } = useNotification();

  const handleFinish = async (values: FormType) => {
    const email = values[FORM_FIELD.EMAIL];
    const password = values[FORM_FIELD.PASSWORD];

    setLoading(true);
    try {
      await stytch.passwords.authenticate({
        email_address: email,
        password,
        organization_id: org.organization_id,
        session_duration_minutes: sessionDurationMinutes,
      });

      await stytch.self.get();

      navigate(ROUTES.app.path, { replace: true });
    } catch (e) {
      if (isStytchAPIError(e)) {
        if (e.error_type === 'unauthorized_credentials' || e.error_type === 'email_not_found') {
          form.setFields([
            {
              name: FORM_FIELD.PASSWORD,
              errors: [t`Sorry, you entered an incorrect email address or password.`],
            },
          ]);
        } else {
          pushNotification({
            type: 'error',
            message: getStytchAPIErrorMessage(e),
          });
        }
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      className={styles.form}
      form={form}
      onFinish={handleFinish}
      validateTrigger="onSubmit"
      size="large"
    >
      <Form.Item
        label={<Trans>Business email</Trans>}
        name={FORM_FIELD.EMAIL}
        rules={EMAIL_STANDARD_RULES}
        initialValue=""
        required={false}
      >
        <TextInput
          inputMode="email"
          placeholder={t`name@work-email.com`}
          autoComplete="email"
          spellCheck="false"
          autoCapitalize="none"
          autoFocus
          maxLength={maxEmailLength}
        />
      </Form.Item>
      <Form.Item
        label={<Trans>Password</Trans>}
        name={FORM_FIELD.PASSWORD}
        rules={[{ required: true, message: <Trans>Password is required</Trans> }]}
        initialValue=""
        required={false}
      >
        <PasswordInput placeholder={t`Password`} spellCheck="false" autoCapitalize="none" />
      </Form.Item>
      <RateLimit>
        <Button
          className={styles.submitButton}
          color="green"
          variant="primary"
          size="large"
          type="submit"
          isLoading={isLoading}
          block
        >
          <Trans>Sign in</Trans>
        </Button>
      </RateLimit>
    </Form>
  );
};

export default TenantPasswordForm;
