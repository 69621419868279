import { Trans, t } from '@lingui/macro';
import { Row, Space } from 'antd';
import { RuleObject } from 'antd/es/form';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { mutateAudienceCreate, useSalesforceAudienceReportMetadata } from '@/api/audience';
import Form, { TextInput, setFieldErrors } from '@/components/Form';
import { ReportName } from '@/components/ReportName';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import { Text } from '@/components/typography';
import { getMessageByCode } from '@/error/messages';
import { INTEGRATION_ERROR_CODES } from '@/error/messages/integration-standard-errors';
import useErrorDisplay from '@/error/useErrorDisplay';
import { isSalesforceReportUrlValid } from '@/helper/validator';
import useDebounce from '@/hooks/useDebounce';
import { ROUTES } from '@/router';

import AudienceBaseForm from '../AudienceBaseForm';

enum FORM_FIELD {
  SALESFORCE_URL = 'salesforce_url',
  NAME = 'name',
  DESCRIPTION = 'description',
}

type FormType = {
  [FORM_FIELD.SALESFORCE_URL]: string;
  [FORM_FIELD.NAME]: string;
  [FORM_FIELD.DESCRIPTION]: string;
};

const ImportSalesforceAudience = () => {
  const navigate = useNavigate();
  const [isCreatingAudience, setIsCreatingAudience] = useState<boolean>();
  const [form] = Form.useForm<FormType>();
  const salesforceReportURL = Form.useWatch([FORM_FIELD.SALESFORCE_URL], form);
  const debouncedURL = useDebounce(salesforceReportURL, 1000);
  const url = debouncedURL ?? salesforceReportURL;
  const { reportMetadata, isLoading, error } = useSalesforceAudienceReportMetadata(
    isSalesforceReportUrlValid(url) ? url : undefined,
  );
  const displayErrors = useErrorDisplay();

  useEffect(() => {
    setFieldErrors(
      form,
      FORM_FIELD.SALESFORCE_URL,
      error?.getFirstError ? [error.getFirstError().message] : [],
    );
  }, [error]);

  const handleReturnToListView = () => {
    navigate(ROUTES.audiences.path);
  };

  const handleFinish = async (values: FormType) => {
    setIsCreatingAudience(true);

    try {
      await mutateAudienceCreate({
        type: 'salesforce',
        ...values,
      });

      navigate(ROUTES.audiences.path, {
        state: {
          successMessage: t`Audience was successfully created.`,
        },
      });
    } catch (e) {
      displayErrors({ error: e, form });
    }

    setIsCreatingAudience(false);
  };

  return (
    <Page title={t`New Audience`}>
      <Form form={form} validateTrigger="onBlur" onFinish={handleFinish}>
        <Space size={24} direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <ReturnToLink route={ROUTES.audiences.path} routeName={t`Audiences`} />
            <Space size={16}>
              <Button color="black" variant="secondary" onClick={handleReturnToListView}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                color="green"
                variant="primary"
                type="submit"
                isDisabled={isCreatingAudience}
                isLoading={isCreatingAudience}
              >
                <Trans>Save</Trans>
              </Button>
            </Space>
          </Row>
          <Panel size="L" title={<Trans>Import Salesforce Report</Trans>}>
            <Space direction="vertical" size={16} style={{ width: '100%' }}>
              <Trans>
                <Text variant="label">Note: </Text>
                <Text variant="body1">
                  Your Salesforce Accounts report must include the standard Account object field "
                  <Text variant="label">Website</Text>" as a column, and must not contain groupings.
                </Text>
              </Trans>
              <Form.Item
                label={<Trans>Salesforce Report Link</Trans>}
                name={FORM_FIELD.SALESFORCE_URL}
                initialValue=""
                validateTrigger={['onBlur', 'onChange']}
                extra={<ReportName reportName={reportMetadata?.name} isLoading={isLoading} />}
                rules={[
                  {
                    required: true,
                    message: <Trans>Salesforce Report Link is required</Trans>,
                  },
                  {
                    message: getMessageByCode(INTEGRATION_ERROR_CODES.SALESFORCE_INVALID_URL)!,
                    validator: (_: RuleObject, value: string) =>
                      isSalesforceReportUrlValid(value) ? Promise.resolve() : Promise.reject(),
                  },
                  {
                    validator: () =>
                      error ? Promise.reject(error.getFirstError().message) : Promise.resolve(),
                  },
                ]}
              >
                <TextInput isDisabled={isCreatingAudience} />
              </Form.Item>
            </Space>
            <AudienceBaseForm isDisabled={isCreatingAudience} />
          </Panel>
        </Space>
      </Form>
    </Page>
  );
};

export default ImportSalesforceAudience;
