import { Trans, t } from '@lingui/macro';

import ImportIcon from '@/assets/svg/bx-import.svg?react';
import { Flex } from '@/components/Flex';
import Tooltip from '@/components/Tooltip';
import { csvDownloadRowLimit } from '@/constants';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  downloadRowLimitReached: boolean;
};

const ExportCSVAction = ({ downloadRowLimitReached }: Props) => {
  if (downloadRowLimitReached) {
    return (
      <Tooltip
        title={t`Record Limit`}
        body={t`Export limited to ${numberFormat(csvDownloadRowLimit)} records`}
      >
        <Flex gap="small">
          <ImportIcon />
          <Trans>Export CSV</Trans>
        </Flex>
      </Tooltip>
    );
  } else {
    return (
      <Flex gap="small">
        <ImportIcon />
        <Trans>Export CSV</Trans>
      </Flex>
    );
  }
};

export default ExportCSVAction;
