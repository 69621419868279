import { Trans, t } from '@lingui/macro';
import { Col, Row, Space } from 'antd';

import { OptionType } from '@/api/common';
import { PixelType } from '@/api/pixel';
import Form, { TextInput } from '@/components/Form';
import { Link, Text } from '@/components/typography';
import { maxDescriptionLength, maxObjectNameLength } from '@/constants/numbers';
import { AudienceSelect } from '@/features/AudienceSelect';

import ChannelSelect from './ChannelSelect';
import styles from './PixelForm.module.scss';
import PixelURLInput from './PixelURLInput';
import VendorByChannelSelect from './VendorByChannelSelect';

export enum FORM_FIELD {
  NAME = 'name',
  DESCRIPTION = 'description',
  AUDIENCE = 'audience',
  CHANNEL = 'channel',
  VENDOR = 'vendor',
  CODE = 'code',
}

export type FormType = {
  [FORM_FIELD.NAME]: string;
  [FORM_FIELD.DESCRIPTION]: string;
  [FORM_FIELD.AUDIENCE]: OptionType;
  [FORM_FIELD.CHANNEL]: OptionType;
  [FORM_FIELD.VENDOR]: OptionType;
  [FORM_FIELD.CODE]: string;
};

type Props = {
  pixel?: PixelType;
  disabled?: boolean;
  isEdit?: boolean;
};

const PixelForm = ({ pixel, disabled = false, isEdit = false }: Props) => {
  const form = Form.useFormInstance<FormType>();
  const channel = Form.useWatch(FORM_FIELD.CHANNEL, form);
  const vendor = Form.useWatch(FORM_FIELD.VENDOR, form);
  const audience = Form.useWatch(FORM_FIELD.AUDIENCE, form);

  const handleChannelChange = (newChannel: OptionType) => {
    const isChangingBackToSavedChannel = pixel?.channel.id === newChannel.id;

    form.setFieldValue(FORM_FIELD.VENDOR, isChangingBackToSavedChannel ? pixel.vendor : undefined);
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Row gutter={[16, 16]}>
        <Col lg={7} md={24} sm={24} xs={24}>
          <Form.Item
            label={<Trans>Pixel Name</Trans>}
            name={FORM_FIELD.NAME}
            initialValue={pixel?.name || ''}
            rules={[{ required: true, message: <Trans>Name is Required</Trans> }]}
          >
            <TextInput
              placeholder={t`Name`}
              isDisabled={disabled}
              maxLength={maxObjectNameLength}
            />
          </Form.Item>
        </Col>
        <Col lg={17} md={24} sm={24} xs={24}>
          <Form.Item
            label={<Trans>Pixel Description</Trans>}
            name={FORM_FIELD.DESCRIPTION}
            initialValue={pixel?.description || ''}
            rules={[{ max: 2000 }]}
          >
            <TextInput
              placeholder={t`Description`}
              isDisabled={disabled}
              maxLength={maxDescriptionLength}
            />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label={<Trans>Channel</Trans>}
            name={FORM_FIELD.CHANNEL}
            initialValue={pixel?.channel}
            rules={[{ required: true, message: <Trans>Channel is Required</Trans> }]}
          >
            <ChannelSelect isDisabled={isEdit || disabled} onChange={handleChannelChange} />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label={<Trans>Vendor</Trans>}
            name={FORM_FIELD.VENDOR}
            initialValue={pixel?.vendor}
            rules={[{ required: true, message: <Trans>Vendor is Required</Trans> }]}
          >
            <VendorByChannelSelect channelId={channel?.id} isDisabled={isEdit || disabled} />
          </Form.Item>
        </Col>
        <Col md={8} sm={24} xs={24}>
          <Form.Item
            label={<Trans>Audience</Trans>}
            name={FORM_FIELD.AUDIENCE}
            initialValue={pixel?.audience}
            rules={[{ required: true, message: <Trans>Audience is Required</Trans> }]}
          >
            <AudienceSelect isDisabled={disabled} />
          </Form.Item>
        </Col>
        {vendor?.id && audience?.id && (
          <>
            <Col xxl={14} xl={16} lg={24} md={24} sm={24} xs={24}>
              <Form.Item
                label={<Trans>Pixel Code</Trans>}
                name={FORM_FIELD.CODE}
                initialValue={pixel?.code}
              >
                <PixelURLInput vendorId={vendor.id} />
              </Form.Item>
            </Col>
            <Col xs={24} className={styles.instructionsColumn}>
              <Text variant="body2">
                <Trans>
                  Success! To start tracking impressions, follow these simple{' '}
                  <Link
                    variant="body2"
                    to="https://support.channel99.com/hc/en-us/sections/18811026107803-Pixel"
                    target="_blank"
                  >
                    instructions
                  </Link>{' '}
                  on how to place it within your digital content.
                </Trans>
              </Text>
            </Col>
          </>
        )}
      </Row>
    </Space>
  );
};

export default PixelForm;
