import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './SemanticColor.module.scss';

enum SemanticColorType {
  green = 'green',
  yellow = 'yellow',
  red = 'red',
}

type Props = {
  className?: string;
  type: keyof typeof SemanticColorType;
};

const SemanticColor = ({ children, className, type }: PropsWithChildren<Props>) => {
  return (
    <span
      className={classNames(styles.semanticColor, className, {
        [styles.green]: type === SemanticColorType.green,
        [styles.yellow]: type === SemanticColorType.yellow,
        [styles.red]: type === SemanticColorType.red,
      })}
    >
      {children}
    </span>
  );
};

export default SemanticColor;
