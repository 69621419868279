import { useEffect } from 'react';

import { CopyToClipboardTextInput, TextInputProps } from '@/components/Form';

import useGeneratePixelURL from './useGeneratePixelURL';

interface Props extends Omit<TextInputProps, 'onChange'> {
  value?: string;
  vendorId: string;
  onUrlChange?: (pixelUrl: string) => void;
  onChange?: (code: string) => void;
}

const PixelURLInput = ({ value, onChange, onUrlChange, vendorId, ...props }: Props) => {
  const { code, url } = useGeneratePixelURL(vendorId, value) || {};

  useEffect(() => {
    // Let the parent form know we have a new code we generated. The input component is readOnly
    // so let's manually fire change callbacks when we create a new tagId.
    if (code && onChange) {
      onChange(code);
    }
    if (url && onUrlChange) {
      onUrlChange(url);
    }
  }, [code, url]);

  return <CopyToClipboardTextInput {...props} value={url} />;
};

export default PixelURLInput;
