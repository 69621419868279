import { useContext } from 'react';

import { ThemeContext } from '../Theme';

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('ThemeContext is outside of its Provider');
  }
  return context;
};
