import { PickerProps } from 'antd/es/date-picker/generatePicker';
import classNames from 'classnames';
import moment from 'moment';
import { PickerRef } from 'rc-picker';
import { forwardRef, useState } from 'react';

import PrevIcon from '@/assets/svg/bx-chevron-left.svg?react';
import NextIcon from '@/assets/svg/bx-chevron-right.svg?react';
import SuperPrevIcon from '@/assets/svg/bx-chevrons-left.svg?react';
import SuperNextIcon from '@/assets/svg/bx-chevrons-right.svg?react';
import Chevron from '@/assets/svg/chevron-left.svg?react';
import { USDateViewFormat } from '@/constants/formats';
import { dataRetentionPolicy } from '@/constants/numbers';

import { InternalMomentDatePicker } from '../InternalMomentDatePicker';
import commonStyles from '../InternalMomentDatePicker/InternalMomentDatePicker.module.scss';

export type DatePickerProps = Omit<PickerProps, 'disabled' | 'size'> & {
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const DatePicker = forwardRef<PickerRef, DatePickerProps>(
  (
    {
      className,
      popupClassName,
      allowClear = false,
      isDisabled,
      format = USDateViewFormat,
      size = 'medium',
      ...rest
    },
    ref,
  ) => {
    const [open, setOpen] = useState(false);

    const handleArrowClick = () => {
      if (open) {
        // let clicking the arrow close the picker if it's open
        setOpen(false);
      }
    };

    return (
      <InternalMomentDatePicker
        ref={ref}
        className={classNames(commonStyles.picker, commonStyles[`size-${size}`], className)}
        popupClassName={classNames(commonStyles.pickerPopup, popupClassName)}
        allowClear={allowClear}
        format={format}
        {...rest}
        size={size === 'medium' ? 'middle' : size}
        disabled={isDisabled}
        open={open}
        prevIcon={<PrevIcon />}
        nextIcon={<NextIcon />}
        superPrevIcon={<SuperPrevIcon />}
        superNextIcon={<SuperNextIcon />}
        suffixIcon={
          <Chevron
            className={classNames(commonStyles.suffixIcon, { [commonStyles.open]: open })}
            onClick={handleArrowClick}
          />
        }
        getPopupContainer={() => document.getElementById('pageRoot') as HTMLElement}
        minDate={moment().subtract(dataRetentionPolicy, 'd').startOf('d')}
        onOpenChange={setOpen}
      />
    );
  },
);

export default DatePicker;
