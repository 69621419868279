import { UserRoleType } from '@/api/user';

export enum USER_FORM_FIELD {
  NAME = 'name',
  EMAIL = 'email',
  ROLE = 'role',
}

export type UserFormType = {
  [USER_FORM_FIELD.NAME]: string;
  [USER_FORM_FIELD.EMAIL]: string;
  [USER_FORM_FIELD.ROLE]: UserRoleType;
};
