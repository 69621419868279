import { Trans, t } from '@lingui/macro';
import { DiscoveredOrganization } from '@stytch/vanilla-js';

import { Button } from '@/components/buttons';
import { Text } from '@/components/typography';

import SignInPanel from '../SignInPanel';
import styles from './DiscoveryForm.module.scss';

type Props = {
  email: string;
  discoveredOrgs: DiscoveredOrganization[];
  onOrgSelect: (orgId: string) => void;
};

/**
 * When a user can sign in to multiple orgs they use this component to choose one.
 */
const DiscoveryForm = ({ email, discoveredOrgs, onOrgSelect }: Props) => {
  return (
    <SignInPanel title={t`Choose an Organization`}>
      <div className={styles.container}>
        <Text variant="body1">
          <Trans>
            <Text variant="body1" weight="bold">
              {email}
            </Text>{' '}
            is part of multiple accounts. Please choose one to continue.
          </Trans>
        </Text>
        <div className={styles.orgChooser}>
          {discoveredOrgs.map((org) => (
            <Button
              key={org.organization.organization_id}
              color="black"
              variant="secondary"
              size="large"
              block
              onClick={() => onOrgSelect(org.organization.organization_id)}
            >
              {org.organization.organization_logo_url}
              {org.organization.organization_name}
            </Button>
          ))}
        </div>
      </div>
    </SignInPanel>
  );
};

export default DiscoveryForm;
