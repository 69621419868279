import { PropsWithChildren } from 'react';

import Tooltip from '@/components/Tooltip';
import { Text } from '@/components/typography';

import styles from './TooltipTitle.module.scss';

const TooltipTitle = ({ children }: PropsWithChildren) => {
  const tooltipTitle = typeof children === 'string' ? children : undefined;

  return (
    <tr>
      <th className={styles.tooltipHeading} colSpan={3}>
        <Tooltip body={tooltipTitle}>
          <Text variant="caption1" color="light-green" title={tooltipTitle}>
            {children || ''}
          </Text>
        </Tooltip>
      </th>
    </tr>
  );
};

export default TooltipTitle;
