import { Trans } from '@lingui/macro';
import { Space } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import Warning from '@/assets/svg/warning.svg?react';
import { Text } from '@/components/typography';

import styles from './WidgetError.module.scss';

type Props = {
  className?: string;
  message?: ReactNode;
  detail?: ReactNode;
};

const WidgetError = ({
  className = '',
  message = <Trans>Sorry, something went wrong</Trans>,
  detail,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Space className={classNames(styles.container, className)} align="center" direction="vertical">
      <Space direction="vertical" className={styles.content} align="center" wrap size="large">
        <Warning className={styles.icon} />
        <Space direction="vertical" size="small">
          <Text variant="headline" weight="light">
            {message ? message : <Trans>An unknown error occurred.</Trans>}
          </Text>
          {detail != null && (
            <Text className={styles.detail} variant="body2" weight="light">
              {detail}
            </Text>
          )}
        </Space>
        {children}
      </Space>
    </Space>
  );
};

export default WidgetError;
