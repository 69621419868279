import { Trans, t } from '@lingui/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { TRAFFIC_TYPES, useTrafficVisitTrend } from '@/api/common';
import Flare, { Area, Axis, FlareSeriesOptions, Legend, Notes, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { NotesPanel } from '@/components/panels';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  DateFormatMD,
  MonthDateYearFormat,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignTotalVisitsChart = ({ campaignIds, isLoading: isCampaignLoading }: Props) => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();

  const {
    trafficTrendData: data,
    isLoading: isChartDataLoading,
    error,
  } = useTrafficVisitTrend(startDate, endDate, { campaignIds, audienceId: audience?.id });

  const isLoading = isChartDataLoading || isCampaignLoading;

  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: TRAFFIC_TYPES.BOT,
        name: t`Bot`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.botVisits })),
      },
      {
        id: TRAFFIC_TYPES.UNRESOLVED,
        name: t`Unknown`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.unresolvedVisits })),
      },
      {
        id: TRAFFIC_TYPES.OTHER,
        name: t`Other Companies`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.otherVisits })),
      },
      {
        id: TRAFFIC_TYPES.TARGET,
        name: audience?.id ? audience.name : t`TAM`,
        zIndex: 5,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.targetVisits })),
      },
    ];
  }, [data, audience]);

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      size="L"
      title={<Trans>Total Visits</Trans>}
      noPadding
      style={{ height: 579 }}
    >
      <Flare
        data={seriesData}
        width="auto"
        height="auto"
        colors={[
          BOT_PRIMARY,
          UNKNOWN_PRIMARY,
          VISITS_PRIMARY,
          audience?.id ? AUDIENCE_PRIMARY : TAM_PRIMARY,
        ]}
        parseX={getUTCTime}
        description={t`A trend chart showing the number of visits by traffic type: Audience or TAM, Other, Bot, and Unknown`}
        isLoading={isLoading}
        error={error}
      >
        <Legend reversed />
        <Area x="date" y="amount" position="stack" fillOpacity={0.85} />
        <Axis position="left" labelFormat={(item) => numberFormat(item.value)} title={t`Visits`} />
        <Axis
          type="datetime"
          position="bottom"
          crosshair="line"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
        />
        <Tooltip
          shared
          titleFormat={(item) => moment(item?.x).format(MonthDateYearFormat)}
          rowValueFormat={(item) => numberFormat(item?.y)}
          rowSecondaryValueFormat={(item) => {
            if (item != null && item.total && item.y) {
              return t`(${numberFormat(item.y / item.total, { isPercent: true, precision: 1 })})`;
            }
            return undefined;
          }}
          valueLink={(item) => {
            const seriesId = item?.series?.userOptions?.id;
            if (seriesId === TRAFFIC_TYPES.TARGET) {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                audienceIds: audience?.id,
                inTam: audience?.id ? undefined : 'true',
                'metrics.visits': '[1,]',
              });
            } else if (seriesId === TRAFFIC_TYPES.OTHER) {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                'audienceIds!': audience?.id,
                inTam: audience?.id ? undefined : 'false',
                'metrics.visits': '[1,]',
              });
            } else if (item?.key === 'total') {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                'metrics.visits': '[1,]',
              });
            }
          }}
          showTotalRow
          unstable_hasRouterContext={false}
        />
        <Notes />
      </Flare>
    </NotesPanel>
  );
};

export default CampaignTotalVisitsChart;
