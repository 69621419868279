import {
  Chart,
  ChartRedrawCallbackFunction,
  Options,
  PointOptionsObject,
  SVGElement,
  SVGPathArray,
  SeriesColumnOptions,
} from 'highcharts';
import { useEffect, useRef } from 'react';

import Flare, { FlareError, Legend, Tooltip } from '@/components/Flare';
import { AUDIENCE_PRIMARY, AUDIENCE_SECONDARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { COLOR_GRAY_400 } from '@/styles/palette';

import styles from './DonutGauge.module.scss';
import DonutGaugeLoader from './DonutGaugeLoader';

const SERIES_1_PLACEMENT_OFFSET = -0.25;
const SERIES_2_PLACEMENT_OFFSET = -0.5;
const SERIES_PADDING = 0.05;

const TOOLTIP_X_OFFSET_FROM_CENTER = 40;

const getPosition = (centerX: number, centerY: number, angle: number, radius: number) => ({
  xPos: centerX + radius * Math.cos(angle),
  yPos: centerY + radius * Math.sin(angle),
});

const addLabel = (
  element: SVGElement | undefined,
  chart: Chart,
  centerX: number,
  centerY: number,
  value: number,
  radius: number,
) => {
  const { yAxis, renderer } = chart;

  let text = numberFormat(value / 100, { isPercent: true });
  if (Math.min(centerX, centerY) < 100) {
    text = ''; // don't draw labels if the donut is too small
  }

  const labelOffsetAngle = 4;
  const angleInRadians = yAxis[0].toPixels(value + labelOffsetAngle, true) - Math.PI / 2;
  const { xPos, yPos } = getPosition(centerX, centerY, angleInRadians, radius);

  if (element) {
    return element.attr({ text, x: xPos, y: yPos });
  } else {
    return renderer
      .text(text, xPos, yPos, true)
      .addClass(styles.animateChartElement)
      .addClass(styles.textElement)
      .add();
  }
};

const addLine = (
  element: SVGElement | undefined,
  chart: Chart,
  centerX: number,
  centerY: number,
  value: number,
  radius: number,
  color: string,
  animate = true,
) => {
  const { chartWidth, chartHeight, renderer, yAxis } = chart;

  const angleInRadians = yAxis[0].toPixels(value, true) - Math.PI / 2;
  const { xPos, yPos } = getPosition(centerX, centerY, angleInRadians, radius);
  const svgPath: SVGPathArray = [
    ['M', chartWidth / 2, chartHeight / 2],
    ['L', xPos, yPos],
  ];

  if (element) {
    return element.attr({ d: svgPath.map((seg) => seg.join(' ')).join(' ') });
  } else {
    return renderer
      .path(svgPath)
      .attr({ stroke: color })
      .addClass(animate ? styles.animateChartElement : '')
      .addClass(styles.lineElement)
      .add();
  }
};

const addCenterCircle = (
  element: SVGElement | undefined,
  chart: Chart,
  centerX: number,
  centerY: number,
  radius: number,
) => {
  const { renderer } = chart;

  if (element) {
    return element.attr({ cx: centerX, cy: centerY, r: radius });
  } else {
    return renderer.circle(centerX, centerY, radius).attr({ fill: COLOR_GRAY_400 }).add();
  }
};

type Props = {
  title?: string;
  outerValue?: number;
  innerValue?: number;
  outerCount?: number | null;
  innerCount?: number | null;
  unitLabel?: string;
  outerSeriesName: string;
  innerSeriesName: string;
  hideLegend?: boolean;
  isLoading?: boolean;
  error: FlareError;
  className?: string;
};

const DonutGauge = ({
  title = '',
  outerValue = 0,
  innerValue = 0,
  outerCount,
  innerCount,
  unitLabel = '',
  outerSeriesName,
  innerSeriesName,
  hideLegend = false,
  isLoading = false,
  error,
  className = '',
}: Props) => {
  const outerLine = useRef<SVGElement>();
  const innerLine = useRef<SVGElement>();
  const baseLine = useRef<SVGElement>();
  const outerLabel = useRef<SVGElement>();
  const innerLabel = useRef<SVGElement>();
  const centerCircle = useRef<SVGElement>();

  const removeDrawnElements = () => {
    outerLine.current = undefined;
    innerLine.current = undefined;
    baseLine.current = undefined;
    outerLabel.current = undefined;
    innerLabel.current = undefined;
    centerCircle.current = undefined;
  };

  useEffect(() => {
    return () => {
      // Avoid memory leaks by removing references to SVGElements so they can be garbage collected
      removeDrawnElements();
    };
  }, []);

  const onHighchartLoad = function () {
    removeDrawnElements();
  };

  const onHighchartCreate: ChartRedrawCallbackFunction = function () {
    const { chartWidth, chartHeight, xAxis } = this;
    const radius0 = xAxis[0].toPixels(0, true);
    const radius1 = xAxis[0].toPixels(1, true);
    const radius2 = xAxis[0].toPixels(2, true);

    const centerX = chartWidth / 2;
    const centerY = chartHeight / 2;
    const seriesDistance = radius2 - radius1;
    const paddingThickness = seriesDistance * SERIES_PADDING;

    // the radius which goes to the center-point of each arc
    const centerRadius2 = radius2 + seriesDistance * SERIES_2_PLACEMENT_OFFSET;
    const centerRadius1 = radius1 + seriesDistance * SERIES_1_PLACEMENT_OFFSET;

    outerLine.current = addLine(
      outerLine.current,
      this,
      centerX,
      centerY,
      outerValue,
      radius2 - paddingThickness,
      AUDIENCE_PRIMARY,
    );

    innerLine.current = addLine(
      innerLine.current,
      this,
      centerX,
      centerY,
      innerValue,
      centerRadius1 - paddingThickness,
      AUDIENCE_SECONDARY,
    );

    baseLine.current = addLine(
      baseLine.current,
      this,
      centerX,
      centerY,
      0,
      radius2,
      COLOR_GRAY_400,
      false,
    );

    outerLabel.current = addLabel(
      outerLabel.current,
      this,
      centerX,
      centerY,
      outerValue,
      centerRadius2,
    );

    innerLabel.current = addLabel(
      innerLabel.current,
      this,
      centerX,
      centerY,
      innerValue,
      centerRadius1,
    );

    centerCircle.current = addCenterCircle(centerCircle.current, this, centerX, centerY, radius0);
  };

  const DUAL_SERIES_RACETRACK_OPTIONS: Options = {
    chart: {
      type: 'column',
      inverted: true,
      polar: true,
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
      events: {
        redraw: onHighchartCreate,
        load: onHighchartLoad,
      },
      style: {
        fontFamily: '"Inter", sans-serif',
      },
    },
    pane: {
      size: '120%',
    },
    xAxis: {
      tickInterval: 1,
      lineWidth: 0,
      minorGridLineWidth: 0,
      lineColor: 'transparent',
      minorTickLength: 0,
      tickLength: 0,
      labels: {
        enabled: false,
      },
    },
    yAxis: {
      min: 0,
      max: 100,
      lineWidth: 0,
      visible: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        borderWidth: 0,
        borderRadius: 0,
        pointPadding: 0,
        groupPadding: 0,
        dataLabels: {
          enabled: false, // we draw these manually
        },
      },
      series: {
        className: styles.arc,
        states: {
          select: {
            enabled: true,
            color: undefined,
            borderWidth: 1,
            borderColor: '#191919',
          },
        },
      },
    },
    series: [
      {
        type: 'column',
        name: outerSeriesName,
        pointPadding: 0.05,
        pointPlacement: SERIES_2_PLACEMENT_OFFSET,
        color: AUDIENCE_PRIMARY,
        data: [
          { y: outerValue, colorIndex: 0, custom: { count: outerCount } },
          { y: 0, colorIndex: 1 },
          { y: 0 },
        ],
      },
      {
        type: 'column',
        name: innerSeriesName,
        pointPadding: 0.3,
        pointPlacement: SERIES_1_PLACEMENT_OFFSET,
        color: AUDIENCE_SECONDARY,
        data: [
          { y: 0, colorIndex: 0 },
          { y: innerValue, colorIndex: 1, custom: { count: innerCount } },
          { y: 0 },
        ],
      },
    ],
    legend: {
      enabled: false,
    },
  };

  if (isLoading) {
    removeDrawnElements();
  }

  return (
    <Flare
      className={className}
      defaultOptions={DUAL_SERIES_RACETRACK_OPTIONS}
      width="auto"
      height="auto"
      isLoading={isLoading}
      error={error}
    >
      <Tooltip
        shared
        positioner={(tooltip, labelWidth, labelHeight, point) => {
          const { chartWidth, chartHeight } = tooltip.chart;
          const { plotX } = point;
          const y = chartHeight / 2 - labelHeight / 2;

          if (plotX < chartWidth / 2) {
            return { x: chartWidth / 2 + TOOLTIP_X_OFFSET_FROM_CENTER, y };
          }
          return { x: chartWidth / 2 - TOOLTIP_X_OFFSET_FROM_CENTER - labelWidth, y };
        }}
        titleFormat={() => title}
        rowValueFormat={(item) => {
          const series = item?.series?.chart.series.find((s) => s.color === item.color);
          if (series) {
            const { index, options } = series || {};
            const data = (options as SeriesColumnOptions)?.data as PointOptionsObject[];
            const val = data[index].y;
            const custom = data[index].custom;

            if (val != null) {
              let countLabel = '';
              if (custom?.count != null) {
                countLabel = `${numberFormat(custom.count)}`;
                if (unitLabel) {
                  countLabel += ` ${unitLabel}`;
                }
              }

              const percentLabel = numberFormat(val / 100, { isPercent: true });
              if (countLabel.length > 0) {
                return `${countLabel}<br/>(${percentLabel})`;
              }
              return percentLabel;
            }
          }
        }}
      />
      {!hideLegend && <Legend noToggleVisibility />}
      {isLoading && <DonutGaugeLoader style={{ flex: 1 }} />}
    </Flare>
  );
};

export default DonutGauge;
