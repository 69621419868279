import { useWebTrafficBreakdownBySector } from '@/api/webTraffic';
import WebTrafficIndustriesChart from '@/app/web-traffic/WebTrafficIndustriesChart';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';

const WebTrafficBreakdownByIndustryWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date, audience } = pageFilters;
  const { webTrafficIndustrySummary, isLoading, error } = useWebTrafficBreakdownBySector(
    start_date,
    end_date,
    audience?.id,
  );

  return (
    <WebTrafficIndustriesChart
      data={webTrafficIndustrySummary}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficBreakdownByIndustryWidget;
