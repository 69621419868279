import {
  COLOR_ORANGE_300,
  COLOR_ORANGE_400,
  COLOR_ORANGE_500,
  COLOR_ORANGE_600,
  COLOR_ORANGE_700,
  COLOR_ORANGE_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_ORANGES = [
  COLOR_ORANGE_300,
  COLOR_ORANGE_400,
  COLOR_ORANGE_500,
  COLOR_ORANGE_600,
  COLOR_ORANGE_700,
  COLOR_ORANGE_800,
];
