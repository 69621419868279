import { Trans } from '@lingui/macro';

import Google from '@/assets/svg/google.svg?react';
import Microsoft from '@/assets/svg/microsoft.svg?react';
import { Button } from '@/components/buttons';

import RateLimit from './RateLimitButton';
import { OAuthProviderType } from './type';

type Props = {
  type: 'sign-in' | 'sign-up';
  supportsGoogleOAuth?: boolean;
  supportsMicrosoftOAuth?: boolean;
  onOAuthProviderClick: (provider: OAuthProviderType) => void;
};

const OAuthLogin = ({
  type,
  supportsGoogleOAuth: hasGoogleOAuth = true,
  supportsMicrosoftOAuth: hasMicrosoftOAuth = true,
  onOAuthProviderClick,
}: Props) => {
  const isSignUp = type === 'sign-up';

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
      {hasGoogleOAuth && (
        <RateLimit>
          <Button
            color="black"
            variant="secondary"
            size="large"
            icon={<Google />}
            block
            onClick={() => onOAuthProviderClick('google')}
          >
            {isSignUp ? <Trans>Sign up with Google</Trans> : <Trans>Sign in with Google</Trans>}
          </Button>
        </RateLimit>
      )}
      {hasMicrosoftOAuth && (
        <RateLimit>
          <Button
            color="black"
            variant="secondary"
            size="large"
            icon={<Microsoft />}
            block
            onClick={() => onOAuthProviderClick('microsoft')}
          >
            {isSignUp ? (
              <Trans>Sign up with Microsoft</Trans>
            ) : (
              <Trans>Sign in with Microsoft</Trans>
            )}
          </Button>
        </RateLimit>
      )}
    </div>
  );
};

export default OAuthLogin;
