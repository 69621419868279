export const enum InstantSearchSortOrder {
  ASC = 'asc',
  DESC = 'desc',
}

export type InstantSearchSortOrderType = InstantSearchSortOrder.ASC | InstantSearchSortOrder.DESC;

export interface QueryState {
  page: number;
  size: number;
  sortBy: string;
  sortOrder: InstantSearchSortOrderType;
  filters: QueryStateFilter[];
  search: string;
}

export type SolQueryFilterOperand = string | string[];

export type SolQueryFilterOperator =
  | 'eq'
  | 'ne'
  | 'in'
  | 'notIn'
  | 'gt'
  | 'gte'
  | 'lt'
  | 'lte'
  | 'isNull'
  | 'isNotNull'
  | 'like'
  | 'iLike'
  | 'notLike';

export type FilterOperatorMulti =
  | (Omit<QueryStateFilterMulti, 'field'> & {
      operand: (string | number)[];
    })
  | undefined
  | null;
export type FilterOperatorRange =
  | {
      min?: {
        inclusive: boolean;
        operand: string | number | undefined | null;
      };
      max?: {
        inclusive: boolean;
        operand: string | number | undefined | null;
      };
    }
  | undefined
  | null;
export type FilterOperatorValue = FilterOperatorMulti | FilterOperatorRange;

export type QueryStateFilterOperator = SolQueryFilterOperator;

export type QueryStateFilterMulti = {
  field: string;
  operator: QueryStateFilterOperator;
  operand: string[];
};

export type QueryStateFilterRange = {
  field: string;
  min?: {
    inclusive: boolean;
    operand: string;
  };
  max?: {
    inclusive: boolean;
    operand: string;
  };
};

export type QueryStateFilter = QueryStateFilterMulti | QueryStateFilterRange;

export type SolQueryFilter = {
  field: string;
  operator: SolQueryFilterOperator;
  operand: SolQueryFilterOperand;
};

export interface SolQueryParamsNew {
  page: {
    offset: number;
    limit: number;
  };
  sort: {
    field: string;
    direction: InstantSearchSortOrderType;
  };
  filter: SolQueryFilter[];
  searchQuery?: string;
}

export interface SolQueryParamsVariablesNew extends Omit<SolQueryParamsNew, 'filter' | 'sort'> {
  sort:
    | {
        field: string;
        direction: InstantSearchSortOrderType;
      }[]
    | [];
  filter:
    | {
        field: string;
        operator: string;
        operand: SolQueryFilterOperand;
      }[]
    | [];
}

/**
 * @deprecated This uses old Sol Query Params. Update to use the new one.
 */
export interface SolQueryParams {
  page: {
    offset: number;
    limit: number;
  };
  sort: {
    field: string;
    direction: InstantSearchSortOrderType;
    nullsFirst?: boolean;
  };
  filter?: string;
}

/**
 * @deprecated Use SolQueryParamsVariablesNew
 */
export interface SolQueryParamsVariables extends Omit<SolQueryParams, 'filter' | 'sort'> {
  sort:
    | {
        field: string;
        direction: InstantSearchSortOrderType;
      }[]
    | [];
  filter:
    | {
        field: string;
        operator: string;
        operand: SolQueryFilterOperand;
      }[]
    | [];
}
