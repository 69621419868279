import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';

import { ProgressBarGoal } from '@/components/ProgressBar';
import ProgressBar from '@/components/ProgressBar/ProgressBar';
import { Text } from '@/components/typography';
import { COLOR_AQUA_400 } from '@/styles/palette';

import styles from './BenchmarksBar.module.scss';

type Props = {
  label: string;
  barLabel?: string;
  value: number;
  target: number;
  isMuted?: boolean;
  width: number;
} & HTMLAttributes<HTMLDivElement>;

const BenchmarksBar = ({
  className,
  label,
  barLabel,
  value,
  target,
  isMuted,
  children,
  width,
  ...rest
}: PropsWithChildren<Props>) => {
  const isMounted = useRef(false);
  const [renderValue, setRenderValue] = useState<number>(0);
  const [renderTarget, setRenderTarget] = useState<number>(0);

  // Render a compact version for when we have limited width
  const isCompact = width < 400;

  // Animate from 0 to the initial value on mount
  useLayoutEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (isMounted.current) {
      setRenderValue(value);
      setRenderTarget(target);
    } else {
      timeoutId = setTimeout(() => {
        setRenderValue(value);
        setRenderTarget(target);
      }, 15);
      isMounted.current = true;
    }

    return () => clearTimeout(timeoutId);
  }, [value]);

  return (
    <div
      className={classNames(styles.container, className, {
        [styles.muted]: isMuted,
        [styles.compact]: isCompact,
      })}
      {...rest}
    >
      <Text className={styles.label} variant="caption1">
        {label}
      </Text>
      <ProgressBar className={styles.bar} value={renderValue} label={barLabel} green>
        <ProgressBarGoal
          value={renderTarget}
          orientation="above"
          color={COLOR_AQUA_400}
          shouldAnimate
        />
        {children}
      </ProgressBar>
    </div>
  );
};

export default BenchmarksBar;
