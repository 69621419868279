import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';

import { InstantSearchSortOrder, QueryState } from './types';
import {
  getQueryStateFromURLSearchParams,
  getURLSearchParamsFromQueryState,
  isQueryStateEqual,
  isSearchParamsOutOfSync,
} from './util/search-util';

interface InstanceSearchContextType {
  queryState: QueryState;
  updateQueryState: (newQueryState: Partial<QueryState>) => void;
}

interface InstantSearchProviderProps {
  defaultQueryState?: Partial<QueryState>;
  ignoredFields?: (keyof QueryState)[];

  /**
   * Ignore any query param keys in the URL that are in this list. This is useful for when you have
   * a filter that is not part of the query state, but is still in the URL.
   */
  ignoredFilters?: string[];
  onQueryStateChange?: (newQueryState: QueryState) => void;
}

const InstantSearchContext = createContext<InstanceSearchContextType | undefined>(undefined);

const InstantSearch = ({
  defaultQueryState = {},
  ignoredFields = [],
  ignoredFilters = [],
  onQueryStateChange,
  children,
}: PropsWithChildren<InstantSearchProviderProps>) => {
  const initialQueryState: QueryState = useMemo(
    () => ({
      page: 1,
      size: 20,
      sortOrder: InstantSearchSortOrder.ASC,
      sortBy: '',
      search: '',
      filters: [],
      ...defaultQueryState,
    }),
    [defaultQueryState],
  );
  const navigate = useNavigate();
  const [searchParams] = useSearchParams('');
  const [queryState, setQueryState] = useState<QueryState>(() =>
    getQueryStateFromURLSearchParams(searchParams, initialQueryState, ignoredFilters),
  );

  const handleQueryStateChange = (updatedQueryState: QueryState) => {
    // We need to be careful not to needlessly add to the history stack by needlessly setting
    // searchParams on to the URL. Only push on to the stack when the queryState changes. For
    // initial render, we may need to patch the url to add on searchParams to the URL, but should
    // replace if we have to do this.

    if (!isQueryStateEqual(queryState, updatedQueryState)) {
      // State changed, so push on to the history stack
      updateSearchParams(
        getURLSearchParamsFromQueryState(updatedQueryState, ignoredFields),
        ignoredFilters,
      );
      setQueryState(updatedQueryState);
    } else if (isSearchParamsOutOfSync(searchParams, updatedQueryState)) {
      // An initial render when we have no query search params, we'll add those params on now.
      // While doing this, don't add on to the history stack, just do a replace.
      updateSearchParams(
        getURLSearchParamsFromQueryState(updatedQueryState, ignoredFields),
        ignoredFilters,
        {
          replace: true,
        },
      );
    }
  };

  const updateSearchParams = (
    updatedSearchParams: URLSearchParams,
    ignoredFilters: string[] = [],
    options: NavigateOptions = {},
  ) => {
    const searchParamsCopy = new URLSearchParams(searchParams.toString());

    // only update the search params if they have actually changed.
    if (updatedSearchParams.toString() !== searchParamsCopy.toString()) {
      let string = '';
      let numParams = 0;
      for (const [key, value] of updatedSearchParams.entries()) {
        string += numParams > 0 ? '&' : '?';
        string += `${key}=${value}`;
        numParams = numParams + 1;
        searchParamsCopy.delete(key);
      }

      // add any extra search params that happen to be in the URL, but are not filters
      for (const [key, value] of searchParamsCopy.entries()) {
        if (ignoredFilters.includes(key)) {
          string += numParams > 0 ? '&' : '?';
          string += `${key}=${value}`;
          numParams = numParams + 1;
        }
      }

      navigate(string, options);
    }
  };

  const updateQueryState = (newQueryState: Partial<QueryState>) => {
    handleQueryStateChange({ ...queryState, ...newQueryState });
  };

  // The query state changed due to user interaction
  useEffect(() => {
    onQueryStateChange?.(queryState);
  }, [queryState]);

  // The page reloaded, or user edited the url params by hand
  useEffect(() => {
    const updatedQueryState = getQueryStateFromURLSearchParams(
      searchParams,
      initialQueryState,
      ignoredFilters,
    );
    handleQueryStateChange(updatedQueryState);
  }, [searchParams, initialQueryState]);

  return (
    <InstantSearchContext.Provider value={{ queryState, updateQueryState }}>
      {children}
    </InstantSearchContext.Provider>
  );
};

export const useInstantSearchState = () => {
  const context = useContext(InstantSearchContext);
  if (!context) {
    throw new Error('useInstantSearchState must be used with an InstanceSearchProvider');
  }
  return context;
};

export default InstantSearch;
