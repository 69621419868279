import { Trans, t } from '@lingui/macro';
import moment from 'moment';
import { useMemo, useState } from 'react';

import { AudienceStatus, AudienceType } from '@/api/audience';
import Edit from '@/assets/svg/edit.svg?react';
import Delete from '@/assets/svg/trashcan.svg?react';
import { Flex } from '@/components/Flex';
import InfoPopover from '@/components/InfoPopover';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, MoreActionsCell } from '@/components/Table';
import { ConfirmModal } from '@/components/modals';
import { Link } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import SolGraphQLError from '@/error/SolGraphQLError';
import { numberFormat } from '@/helper/numberFormatter';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';

type Props = {
  data?: AudienceType[];
  error?: SolGraphQLError;
  totalResults?: number;
  isLoading?: boolean;
  onAudienceDelete: (deleteId: string) => void;
};

enum ActionKey {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

const AudiencesListTable = ({
  data,
  error,
  totalResults,
  isLoading = false,
  onAudienceDelete,
}: Props) => {
  const user = useMe();
  const [deleteId, setDeleteId] = useState<string>();

  const sortFields = ROUTES.audiences.searchParams.sortBy.values;

  const handleAudienceConfirmDelete = (field: AudienceType) => {
    setDeleteId(field.id);
  };

  const handleAudienceDeleteCancel = () => {
    setDeleteId(undefined);
  };

  const handleAudienceDelete = async () => {
    if (deleteId) {
      onAudienceDelete(deleteId);
      setDeleteId(undefined);
    }
  };

  const handleActionSelect = (actionKey: ActionKey, field: AudienceType) => {
    if (actionKey === ActionKey.DELETE) {
      handleAudienceConfirmDelete(field);
    }
  };

  const columns: ColumnsType<AudienceType> = useMemo(() => {
    const cols: ColumnsType<AudienceType> = [
      {
        title: t`Audience Name`,
        key: 'name',
        fixed: 'left',
        sorter: sortFields.indexOf('name') >= 0,
        render: (text, field) =>
          field.last_job?.status === AudienceStatus.COMPLETED ? (
            <Link variant="caption1" to={ROUTES.audienceById(field.id)}>
              {text}
            </Link>
          ) : (
            text
          ),
      },
      {
        title: t`Audience Description`,
        key: 'description',
        ellipsis: true,
        responsive: ['xxl'],
      },
      {
        title: t`Companies`,
        key: 'metrics.size',
        sorter: sortFields.indexOf('metrics.size') >= 0,
        align: 'right',
        render: (text, field) => numberFormat(field.metrics.size),
        responsive: ['sm'],
      },
      {
        title: t`Visits (Last 30d)`,
        key: 'metrics.visits',
        sorter: sortFields.indexOf('metrics.visits') >= 0,
        align: 'right',
        render: (text, field) => numberFormat(field.metrics.visits),
      },
      {
        title: t`Created On`,
        key: 'created_at',
        sorter: sortFields.indexOf('created_at') >= 0,
        align: 'right',
        render: (text, field) => moment(field.created_at).format(USDateViewFormat),
        responsive: ['lg'],
      },
      {
        title: t`Created By`,
        key: 'created_by.name',
        sorter: sortFields.indexOf('created_by.name') >= 0,
        render: (text, field) => field.created_by?.name ?? t`Unknown User`,
        responsive: ['xl'],
      },
      {
        title: t`Source`,
        key: 'type',
        sorter: sortFields.indexOf('type') >= 0,
        render: (text) => (text === 'upload' ? t`CSV/File` : t`CRM`),
        responsive: ['xl'],
      },
      {
        title: t`Status`,
        key: 'last_job.status',
        render: (text, field) => {
          const { status } = field.last_job || {};

          let label = t`Unknown`;
          switch (status) {
            case AudienceStatus.COMPLETED:
              label = t`Complete`;
              break;
            case AudienceStatus.ERROR:
              label = t`Error`;
              break;
            case AudienceStatus.PROCESSING:
              label = t`Processing`;
              break;
            case AudienceStatus.QUEUED:
              label = t`Queued`;
              break;
            default:
          }

          return (
            <Flex align="center" gap={4}>
              {label}
              {field.last_job?.errors && (
                <InfoPopover trigger="hover" title={t`Error`} body={field.last_job?.errors} />
              )}
            </Flex>
          );
        },
      },
    ];

    if (user?.isAdmin) {
      cols.push({
        title: '',
        key: 'action',
        width: 100,
        fixed: 'right',
        align: 'right',
        render: (text, field) => (
          <MoreActionsCell
            menuItems={[
              {
                key: ActionKey.EDIT,
                label: t`Edit`,
                icon: <Edit />,
                to: ROUTES.audienceEditById(field.id),
              },
              {
                key: ActionKey.DELETE,
                label: t`Delete`,
                color: 'red',
                icon: <Delete />,
              },
            ]}
            onSelect={(key) => handleActionSelect(key as ActionKey, field)}
          />
        ),
      });
    }

    return cols;
  }, [sortFields]);

  return (
    <>
      <InstantSearchTable<AudienceType>
        columns={columns}
        dataSource={data}
        totalResults={totalResults}
        loading={isLoading}
        emptyMessage={<Trans>No audiences found</Trans>}
        error={error}
        rowKey={(record) => record.id}
        data-testid="audiences-table"
        sticky
      />
      <ConfirmModal
        open={deleteId != null}
        okText={<Trans>Yes, Delete</Trans>}
        onOk={handleAudienceDelete}
        onCancel={handleAudienceDeleteCancel}
      >
        <Trans>Are you sure you want to delete this Audience?</Trans>
      </ConfirmModal>
    </>
  );
};

export default AudiencesListTable;
