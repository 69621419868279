/* eslint-disable no-restricted-imports */
import { Spin as AntdSpin, SpinProps as AntdSpinProps } from 'antd';

import LoadingIndicator from '@/components/LoadingIndicator';

export type SpinProps = AntdSpinProps;

const Spin = (props: SpinProps) => {
  return <AntdSpin indicator={<LoadingIndicator center />} {...props} />;
};

export default Spin;
