import { Trans } from '@lingui/macro';
import classNames from 'classnames';
import { ReactNode } from 'react';

import { numberFormat } from '@/helper/numberFormatter';
import { COLOR_GREEN_400 } from '@/styles/palette';

import styles from './ProgressBarGoal.module.scss';

type Props = {
  /**
   * A percentage value from 0-1 which indicates where it should be placed on the Progress Bar
   */
  value: number;
  /**
   * Optional text displayed next to the goal marker
   */
  label?: ReactNode;
  /**
   * Optional goal color. Primary green is the default
   */
  color?: string;
  /**
   * Whether the goal and its label should extend above the progress bar or below it
   */
  orientation?: 'above' | 'below';
  /**
   * Whether the goal should animate from left to right as `value` changes
   */
  shouldAnimate?: boolean;
};

const ProgressBarGoal = ({
  value,
  label,
  color = COLOR_GREEN_400,
  shouldAnimate = false,
  orientation = 'above',
}: Props) => {
  return (
    <div
      className={classNames(styles.container, styles[`${orientation}Orientation`], {
        [styles.animate]: shouldAnimate,
      })}
      style={{ left: numberFormat(value, { isPercent: true }) }}
    >
      <div className={styles.dashed} style={{ borderLeftColor: color }} />
      <div className={styles.dot} style={{ backgroundColor: color }} />
      {label && (
        <div className={styles.goalLabel}>
          <Trans>100% Complete</Trans>
        </div>
      )}
    </div>
  );
};

export default ProgressBarGoal;
