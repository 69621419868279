/* eslint-disable no-restricted-imports */
import {
  Radio as AntdRadio,
  RadioGroupProps as AntdRadioGroupProps,
  RadioProps as AntdRadioProps,
  ConfigProvider,
} from 'antd';
import { RadioButtonProps as AntdRadioButtonProps } from 'antd/es/radio/radioButton';
import classNames from 'classnames';

import { COLOR_GRAY_100 } from '@/styles/palette';

import styles from './Radio.module.scss';

export type RadioProps = Omit<AntdRadioProps, 'disabled'> & {
  isDisabled?: boolean;
};

export type RadioButtonProps = Omit<AntdRadioButtonProps, 'disabled'> & {
  isDisabled?: boolean;
};

export type RadioGroupProps = Omit<AntdRadioGroupProps, 'disabled' | 'buttonStyle'> & {
  isDisabled?: boolean;
};

const Radio = ({ children, className, isDisabled, ...rest }: RadioProps) => (
  <AntdRadio {...rest} className={classNames(styles.radio, className)} disabled={isDisabled}>
    {children}
  </AntdRadio>
);

Radio.Group = ({ className, size, isDisabled, ...rest }: RadioGroupProps) => (
  <AntdRadio.Group
    className={classNames(styles.group, className)}
    {...rest}
    size={size}
    disabled={isDisabled}
    buttonStyle="solid"
  />
);

Radio.Button = ({ children, className, isDisabled, ...rest }: RadioButtonProps) => (
  <ConfigProvider theme={{ token: { colorPrimary: COLOR_GRAY_100 } }}>
    <AntdRadio.Button
      {...rest}
      className={classNames(styles.radioButton, className)}
      disabled={isDisabled}
    >
      {children}
    </AntdRadio.Button>
  </ConfigProvider>
);

export default Radio;
