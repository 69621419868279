import { Trans } from '@lingui/macro';
import moment from 'moment';
import { useState } from 'react';

import { CompanyDetailType, useCompanySummaryById } from '@/api/activity';
import { Legend, LegendItem } from '@/components/Legend';
import { Panel } from '@/components/panels';
import { getChannelColor } from '@/constants/colors/channel';

import styles from './CompanyTotalVisitsPanel.module.scss';
import CompanyVisitChartCaption from './CompanyVisitChartCaption';
import CompanyVisitDonutChart from './CompanyVisitDonutChart';

type Props = {
  data: CompanyDetailType | null;
  startDate?: string;
  endDate?: string;
};

const CompanyTotalVisitsPanel = ({ data, startDate, endDate }: Props) => {
  const { companySummary, isLoading, error } = useCompanySummaryById(data?.id, startDate, endDate);
  const [disabledSeries, setDisabledSeries] = useState<string[]>([]);

  const numDays = moment(endDate).diff(moment(startDate), 'days');

  return (
    <Panel size="L" title={<Trans>Total Visits ({numDays} Days)</Trans>} noPadding>
      <Legend defaultDisabledValues={disabledSeries} onDisabledSeriesChange={setDisabledSeries}>
        {companySummary?.trafficVisitBreakdown.map((d) => (
          <LegendItem
            key={d.vendor.id}
            value={d.vendor.id}
            color={getChannelColor(d.vendor.channel)}
            name={d.vendor.displayName}
          />
        ))}
      </Legend>
      <div className={styles.content}>
        <CompanyVisitDonutChart
          data={companySummary?.trafficVisitBreakdown}
          isLoading={isLoading}
          error={error}
          disabledSeries={disabledSeries}
        />
        <CompanyVisitChartCaption data={companySummary?.companySummary} />
      </div>
    </Panel>
  );
};

export default CompanyTotalVisitsPanel;
