import { Trans } from '@lingui/macro';

import CheckIcon from '@/assets/svg/check.svg?react';
import CrossIcon from '@/assets/svg/cross.svg?react';
import { Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './IntegrationStatus.module.scss';

type IntegrationStatus = 'active' | 'notConnected' | 'broken';

type Props = {
  status: IntegrationStatus;
  count?: number;
};

const getStatusText = (status: IntegrationStatus, count?: number) => {
  const statusText =
    status === 'active' ? (
      <Trans>Connected</Trans>
    ) : status === 'notConnected' ? (
      <Trans>Not Connected</Trans>
    ) : (
      <Trans>Error</Trans>
    );

  if (count == null || status === 'notConnected') {
    return statusText;
  }

  return (
    <>
      {statusText} ({numberFormat(count)})
    </>
  );
};

const IntegrationStatus = ({ status, count }: Props) => {
  const statusContent = getStatusText(status, count);

  return (
    <Text
      className={styles.status}
      variant="footnote"
      color={status === 'active' ? 'green' : 'red'}
    >
      <span className={styles.icon}>{status === 'active' ? <CheckIcon /> : <CrossIcon />}</span>
      {statusContent}
    </Text>
  );
};

export default IntegrationStatus;
