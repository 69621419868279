import { Trans } from '@lingui/macro';

import { HealthCenterUserType } from '@/api/healthCenter';

type Props = {
  user?: HealthCenterUserType;
};

const HealthPodUser = ({ user }: Props) => {
  if (!user) {
    return <Trans>N/A</Trans>;
  }

  return <>{user.name}</>;
};

export default HealthPodUser;
