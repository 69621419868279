import once from 'lodash/once';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

import { ColumnType, ColumnsType } from '@/components/Table';

type TableStateContextType<T> = {
  columns: ColumnsType<T>;
  columnsMap: Record<string, ColumnType<T>>;
  sortKeyColumns: string[];
  visibleKeyColumns: string[];
  onSortKeyColumnsChange: (sortKeyColumns: string[]) => void;
  onVisibleColumnsChange: (visibleColumns: string[]) => void;
};

const createTableStateContext = once(<T,>() => createContext({} as TableStateContextType<T>));

export const useTableState = <T extends object>() => {
  const context = useContext(createTableStateContext<T>());
  if (!context) {
    throw new Error('useTableState must be used within a TableState');
  }
  return context;
};

type Props<T> = {
  columns: ColumnType<T>[];
};

const TableState = <T extends object>({ columns, children }: PropsWithChildren<Props<T>>) => {
  const TableStateContext = createTableStateContext<T>();

  const columnsMap = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        acc[column.key] = column;
        return acc;
      },
      {} as Record<string, ColumnType<T>>,
    );
  }, [columns]);

  const [sortKeyColumns, setSortKeyColumns] = useState<string[]>(() =>
    columns.map((column) => `${column.key}`),
  );
  const [visibleKeyColumns, setVisibleKeyColumns] = useState<string[]>(() =>
    Object.keys(columnsMap),
  );

  const sortedColumns = useMemo(() => {
    sortKeyColumns.forEach((key, index) => {
      columnsMap[key].order = index;
    });

    return [...columns].sort((a, b) => {
      if (a?.order != null && b?.order != null) {
        return a?.order - b.order;
      }
      return 0;
    });
  }, [columnsMap, sortKeyColumns]);

  const filteredAndSortedColumns = useMemo(() => {
    return sortedColumns.map((column) => {
      column.hidden = !visibleKeyColumns.includes(`${column.key}`);
      return column;
    });
  }, [columnsMap, sortedColumns, visibleKeyColumns]);

  return (
    <TableStateContext.Provider
      value={{
        columns: filteredAndSortedColumns,
        columnsMap,
        sortKeyColumns,
        visibleKeyColumns,
        onSortKeyColumnsChange: setSortKeyColumns,
        onVisibleColumnsChange: setVisibleKeyColumns,
      }}
    >
      {children}
    </TableStateContext.Provider>
  );
};

export default TableState;
