import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';

import PageError from './PageError';

const Error404 = ({ message }: { message?: ReactNode }) => {
  return (
    <PageError
      message={message ? message : <Trans>The page you're looking for can't be found.</Trans>}
      detail={
        <Trans>
          Check that you typed the address correctly, go back to your previous page or return to the
          home page.
        </Trans>
      }
    />
  );
};

export default Error404;
