import { useTrafficVisitTrend } from '@/api/common';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import WebTrafficVisitByTypeTrendChart from '@/features/charts/WebTrafficVisitByTypeTrendChart';

const WebTrafficVisitByTypeTrendWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const {
    trafficTrendData: data,
    isLoading,
    error,
  } = useTrafficVisitTrend(startDate, endDate, { audienceId: audience?.id });

  return (
    <WebTrafficVisitByTypeTrendChart
      data={data}
      audience={audience}
      isLoading={isLoading}
      error={error}
    />
  );
};

export default WebTrafficVisitByTypeTrendWidget;
