import { Trans, t } from '@lingui/macro';
import { useMemo } from 'react';

import { usePipelineReturnOnSpend } from '@/api/pipeline';
import Flare, { Axis, Column, Tooltip } from '@/components/Flare';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { TAM_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';

const PipelineReturnOnSpendChart = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const {
    pipelineReturnOnSpend: data,
    isLoading,
    error,
  } = usePipelineReturnOnSpend(startDate, endDate);

  const filteredData = useMemo(
    () =>
      data?.filter((d) => d.returnOnSpend > 0)?.sort((a, b) => b.returnOnSpend - a.returnOnSpend),
    [data],
  );

  const seriesData = useMemo(() => {
    if (!filteredData || filteredData.length === 0) {
      return undefined;
    }

    return [
      {
        id: 'ros',
        name: t`Return on Spend`,
        data: filteredData.map((d) => ({ amount: d.returnOnSpend })),
      },
    ];
  }, [filteredData]);

  return (
    <Panel
      title={<Trans>Channel Return on Spend</Trans>}
      size="L"
      noPadding
      style={{ height: 390 }}
    >
      <Flare
        data={seriesData}
        description={t`Return on spend broken down by channel`}
        colors={[TAM_PRIMARY]}
        isLoading={isLoading}
        error={error}
        defaultOptions={{ legend: { enabled: false } }}
      >
        <Column
          y="amount"
          position="overlap"
          showDataLabels
          dataLabelFormat={(label) => numberFormat(label.y, { precision: 0, isMultiplier: true })}
          skeletonLoaderOptions={{ numCategories: 4, numSeries: 1, sortDescending: true }}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value, { precision: 0, isMultiplier: true })}
        />
        <Axis
          position="bottom"
          categories={filteredData?.map((d) => d.channel.name)}
          crosshair="rect"
        />
        <Tooltip
          titleFormat={(item) => item?.x}
          rowValueFormat={(item) => numberFormat(item?.y, { precision: 0, isMultiplier: true })}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PipelineReturnOnSpendChart;
