import { t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import PageSpinner from '@/components/PageSpinner';
import { FullPage } from '@/components/page';

import Organization404 from '../Organization404';
import useOrganizationMetadataBySlug from '../useOrganizationMetadataBySlug';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPassword = () => {
  const { orgId } = useParams();
  const { data, isLoading, error } = useOrganizationMetadataBySlug({ slug: orgId });

  return (
    <FullPage title={t`Channel99`}>
      {isLoading && <PageSpinner />}
      {error && <Organization404 />}
      {data && <ForgotPasswordForm org={data} />}
    </FullPage>
  );
};

export default ForgotPassword;
