import { Trans, t } from '@lingui/macro';
import { DefaultOptionType } from 'antd/es/select';

import { useChannelList } from '@/api/channel';
import { OptionType } from '@/api/common';
import { Option, Select, SelectProps } from '@/components/Form';

interface Props extends Omit<SelectProps, 'mode'> {
  value?: OptionType;
}

const ChannelSelect = ({ value, onChange, ...rest }: Props) => {
  const { channels, isLoading } = useChannelList();

  const handleChange = (
    { value, label }: DefaultOptionType,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (onChange) {
      onChange(
        {
          id: value,
          name: label,
        },
        option,
      );
    }
  };

  return (
    <Select
      isLoading={isLoading}
      value={{ value: value?.id, label: value?.name }}
      onChange={handleChange}
      placeholder={t`Select Channel...`}
      notFoundContent={<Trans>No channels were found...</Trans>}
      labelInValue
      showSearch
      {...rest}
    >
      {(channels || [])?.map((channel) => (
        <Option key={channel.id} value={channel.id}>
          {channel.name}
        </Option>
      ))}
    </Select>
  );
};

export default ChannelSelect;
