import { Trans, t } from '@lingui/macro';
import { useState } from 'react';

import { IntegrationOrgType } from '@/api/integration';
import { Checkbox } from '@/components/Form';
import Table, { ColumnsType } from '@/components/Table';
import { Button } from '@/components/buttons';
import { Modal } from '@/components/modals';
import { Title } from '@/components/typography';

import styles from './SelectIntegrationOrgModal.module.scss';

type Props = {
  orgs?: IntegrationOrgType[];
  isLoading?: boolean;
  onCancel: () => void;
  onOk: (orgId: string) => void;
};

const SelectIntegrationOrgModal = ({ orgs, isLoading = false, onCancel, onOk }: Props) => {
  const [selectedOrgId, setSelectedOrgId] = useState<string | undefined>();

  const columns: ColumnsType<IntegrationOrgType> = [
    {
      title: '',
      key: 'id',
      width: 40,
      render: (text, field) => <Checkbox checked={field.id === selectedOrgId} />,
    },
    {
      title: t`Account`,
      key: 'name',
    },
    {
      title: t`Account ID`,
      key: 'id',
    },
  ];

  return (
    <Modal
      open={orgs != null}
      onCancel={onCancel}
      footer={[
        <Button
          key="cancelButton"
          color="black"
          variant="secondary"
          size="large"
          onClick={onCancel}
        >
          <Trans>Cancel</Trans>
        </Button>,
        <Button
          key="okButton"
          color="black"
          variant="primary"
          size="large"
          isLoading={isLoading}
          isDisabled={selectedOrgId == null}
          onClick={() => onOk(selectedOrgId!)}
        >
          <Trans>Connect</Trans>
        </Button>,
      ]}
    >
      <div className={styles.body}>
        <Title level="4" weight="light">
          <Trans>Please choose the Account you would like to connect</Trans>
        </Title>
        <Table<IntegrationOrgType>
          className={styles.table}
          columns={columns}
          dataSource={orgs}
          loading={false}
          error={null}
          emptyMessage={<Trans>No accounts exist</Trans>}
          pagination={false}
          rowKey={(record) => record.id}
          onRow={(record) => ({ onClick: () => setSelectedOrgId(record.id) })}
        />
      </div>
    </Modal>
  );
};

export default SelectIntegrationOrgModal;
