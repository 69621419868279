import { Trans, t } from '@lingui/macro';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { ChannelWeeklyRowDataType, useChannelWeeklyVisits } from '@/api/channel';
import Flare, { Axis, FlareSeriesOptions, Legend, Notes, Tooltip } from '@/components/Flare';
import Line from '@/components/Flare/marks/Line';
import { Radio } from '@/components/Form';
import { usePageFilterContext } from '@/components/page';
import { NotesPanel } from '@/components/panels';
import { getVendorColor } from '@/constants/colors/vendor';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  channelId?: string;
};

enum CHART_MODE {
  audienceVisits,
  allVisits,
  tamVisits,
  costPerVisit,
  costPerTamVisit,
}

const getAmountByChartMode = (chartMode: CHART_MODE, object: ChannelWeeklyRowDataType) => {
  switch (chartMode) {
    case CHART_MODE.costPerVisit:
      return object.spendPerVisit.all;
    case CHART_MODE.costPerTamVisit:
      return object.spendPerVisit.tam;
    case CHART_MODE.audienceVisits:
      return object.visits.audience;
    case CHART_MODE.tamVisits:
      return object.visits.tam;
    default:
    case CHART_MODE.allVisits:
      return object.visits.all;
  }
};

const getChartModeOptions = (audience?: AudienceOptionType) => {
  const defaultOptions = [
    { value: CHART_MODE.allVisits, label: t`Visits` },
    { value: CHART_MODE.tamVisits, label: t`TAM Visits` },
    { value: CHART_MODE.costPerVisit, label: t`$/Visit` },
    { value: CHART_MODE.costPerTamVisit, label: t`$/TAM Visit` },
  ];

  if (audience?.name) {
    return [{ value: CHART_MODE.audienceVisits, label: audience?.name }, ...defaultOptions];
  }
  return defaultOptions;
};

const ChannelWeeklyVisitsChart = ({ channelId }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const [chartMode, setChartMode] = useState<CHART_MODE>(CHART_MODE.allVisits);
  const { channelWeeklyVisits, isLoading, error } = useChannelWeeklyVisits(
    channelId,
    startDate,
    endDate,
    audience?.id,
  );

  // If an audience is added or removed, change the chart mode accordingly.
  useEffect(() => {
    if (audience?.name) {
      setChartMode(CHART_MODE.audienceVisits);
    } else {
      setChartMode(CHART_MODE.allVisits);
    }
  }, [audience?.name]);

  const sanitizedData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (channelWeeklyVisits == null) {
      return undefined;
    }

    return channelWeeklyVisits.map((visitsByVendor) => ({
      id: visitsByVendor.vendor.id,
      name: visitsByVendor.vendor.name,
      data: visitsByVendor.data.map((d) => ({
        date: d.sundayOfWeek,
        amount: getAmountByChartMode(chartMode, d),
      })),
    }));
  }, [channelWeeklyVisits, chartMode]);

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      size="L"
      title={<Trans>Total Visits</Trans>}
      actions={
        <Radio.Group
          value={chartMode}
          optionType="button"
          onChange={(e) => setChartMode(e.target.value)}
          options={getChartModeOptions(audience)}
        />
      }
      noPadding
      style={{ height: 565 }}
      verifyC99Tag
    >
      <Flare
        data={sanitizedData}
        width="auto"
        height="auto"
        parseX={getUTCTime}
        colors={channelWeeklyVisits?.map((visitsByVendor) => getVendorColor(visitsByVendor.vendor))}
        isLoading={isLoading}
        error={error}
      >
        <Legend />
        <Line x="date" y="amount" />
        <Axis position="left" labelFormat={(item) => numberFormat(item.value)} title={t`Visits`} />
        <Axis
          type="datetime"
          crosshair="line"
          position="bottom"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
        />
        <Tooltip
          titleFormat={(item) => moment(item?.x).format(MonthDateYearFormat)}
          rowValueFormat={(item) => numberFormat(item?.point?.y)}
          showTotalRow={sanitizedData && sanitizedData.length > 1}
          shared
        />
        <Notes />
      </Flare>
    </NotesPanel>
  );
};

export default ChannelWeeklyVisitsChart;
