import { i18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { mutateIntegrationConnectionCreate, mutateIntegrationTokenCreate } from '@/api/integration';
import PageSpinner from '@/components/PageSpinner';
import { Page } from '@/components/page';
import SolGraphQLError from '@/error/SolGraphQLError';
import { INTERNAL_OAUTH_FAILURE } from '@/error/messages/integration-standard-errors';
import getSolStandardError from '@/error/sol-standard-errors';
import { ROUTES } from '@/router';

import SelectIntegrationOrgModal from './SelectIntegrationOrgModal';
import styles from './VerifyIntegration.module.scss';

const VerifyIntegration = () => {
  const { provider: providerInUrl } = useParams();
  const [status, setStatus] = useState<'success' | 'error' | undefined>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [tokenId, setTokenId] = useState<string | undefined>();
  const [orgs, setOrgs] = useState<{ id: string; name: string }[] | undefined>();
  const [creatingConnection, setCreatingConnection] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const providerId = providerInUrl ?? searchParams.get('state');
  const createTokenCalled = useRef(false);

  if (!searchParams.size || !providerId) {
    return null;
  }

  const handleCreateOAuthToken = async () => {
    try {
      const response = await mutateIntegrationTokenCreate(providerId, searchParams);

      if (response.connection?.id) {
        // There was only a single integration org and the user didn't have to select one. The
        // connection was automatically created.
        setStatus('success');
        setErrorMessage(undefined);
      } else if (response.orgs?.length) {
        // Not done yet, the user needs to choose an org associated with their integration account
        setTokenId(response.id);
        setOrgs(response.orgs);
      } else {
        // Something unexpected happened
        setStatus('error');
        setErrorMessage(i18n._(INTERNAL_OAUTH_FAILURE));
      }
    } catch (e) {
      let errMessage;
      if (e instanceof SolGraphQLError) {
        const solError = getSolStandardError(e.getFirstError());
        errMessage = solError.message;
      } else {
        errMessage = i18n._(INTERNAL_OAUTH_FAILURE);
      }
      setStatus('error');
      setErrorMessage(errMessage);
    }
  };

  const handleSelectOrgId = async (orgId: string) => {
    try {
      setCreatingConnection(true);
      await mutateIntegrationConnectionCreate(tokenId!, orgId);
      setStatus('success');
    } catch {
      setStatus('error');
    }
    setCreatingConnection(false);
  };

  useEffect(() => {
    if (providerId && searchParams.size && !createTokenCalled.current) {
      createTokenCalled.current = true;
      handleCreateOAuthToken();
    }
  }, [code, providerId]);

  if (status != null && providerId) {
    return (
      <Navigate
        to={ROUTES.integrationById(providerId)!}
        state={{ connectionSavedSuccess: status === 'success', connectionSavedError: errorMessage }}
      />
    );
  }

  return (
    <Page title={t`Settings`} pageName={t`Integrations`} className={styles.content}>
      <PageSpinner text={t`Creating connection`} />
      <SelectIntegrationOrgModal
        orgs={orgs}
        isLoading={creatingConnection}
        onCancel={() => setStatus('error')}
        onOk={handleSelectOrgId}
      />
    </Page>
  );
};

export default VerifyIntegration;
