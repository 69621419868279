import { NotificationType, useNotification } from '@/providers/Notification';

import useEffectOnceWhenDepsExist from './useEffectOnceWhenDepsExist';

type Props = {
  type: NotificationType;
  message: string;
};

const useDisplayNotificationOnLoad = ({ type, message }: Props) => {
  const { pushNotification } = useNotification();

  useEffectOnceWhenDepsExist(() => {
    pushNotification({ type, message });
  }, [type, message]);
};

export default useDisplayNotificationOnLoad;
