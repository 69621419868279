import { Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import SidebarIcon from '@/assets/svg/bx-sidebar.svg?react';
import Chevron from '@/assets/svg/chevron.left.svg?react';
import Logo from '@/assets/svg/logo.svg?react';
import { Switch } from '@/components/Form';
import { ProfileAvatar } from '@/components/ProfileAvatar';
import Tooltip from '@/components/Tooltip';
import { Title } from '@/components/typography';
import { HEADER_HEIGHT } from '@/constants/dimensions';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import { useHeaderContext } from '@/hooks/header/HeaderContext';
import { useTheme } from '@/providers/Theme';
import { THEMES } from '@/providers/Theme/types';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';

import AISearch from './AISearch';
import styles from './PageHeader.module.scss';

const DEFAULT_TITLE = 'DEFAULT_PAGE_TITLE';

type Props = {
  isMenuOpen?: boolean;
  whiteLogo?: boolean;
  blackLogo?: boolean;
  expandedOnResponsive?: boolean;
  onClickCallback?: () => void;
  onToggleMenu?: () => void;
};

const PageHeader = ({
  isMenuOpen,
  whiteLogo,
  blackLogo,
  expandedOnResponsive,
  onClickCallback,
  onToggleMenu,
}: Props) => {
  const user = useMe();
  const { title: menuTitle } = useHeaderContext();
  const isAIChatEnabled = useFeatureFlag(FEATURE_FLAG.aiChat);
  const isDarkThemeEnabled = useFeatureFlag(FEATURE_FLAG.darkTheme);
  const { setTheme } = useTheme();

  return (
    <Header
      className={classNames(styles.header, !menuTitle && styles.transparent, {
        [styles.bigHeader]: expandedOnResponsive,
      })}
      style={{ height: HEADER_HEIGHT }}
    >
      <a
        className={styles.sidebarToggle}
        role="button"
        tabIndex={0}
        aria-pressed={isMenuOpen}
        onClick={onToggleMenu}
        onKeyDown={(e) => (e.code === 'Enter' || e.code === 'Space') && onToggleMenu?.()}
      >
        <SidebarIcon />
      </a>
      <div
        className={classNames(styles.logoWrapper, {
          [styles.white]: whiteLogo,
          [styles.black]: blackLogo,
        })}
      >
        <Link
          className={classNames(styles.logoLink)}
          to={menuTitle ? ROUTES.dashboard.path : ROUTES.signIn.path}
          onClick={onClickCallback}
        >
          <Logo />
        </Link>
      </div>
      {!!menuTitle && (
        <div className={styles.titleWrapper}>
          <div className={styles.title}>
            <Tooltip
              body={menuTitle || DEFAULT_TITLE}
              mouseEnterDelay="slow"
              placement="bottomLeft"
            >
              <Title className={styles.titleText} level="3" weight="semi-bold">
                {menuTitle || DEFAULT_TITLE}
              </Title>
            </Tooltip>
            <Chevron className={styles.titleChevron} />
          </div>
          <div className={styles.actions}>
            {isAIChatEnabled && <AISearch />}
            {isDarkThemeEnabled && (
              <Switch
                defaultChecked={true}
                onChange={(checked) => setTheme(checked ? THEMES.LIGHT : THEMES.DARK)}
              />
            )}
            <Link className={styles.avatarLink} to={ROUTES.profile.path}>
              <ProfileAvatar name={user?.name} pictureUrl={user?.pictureUrl} />
            </Link>
          </div>
        </div>
      )}
    </Header>
  );
};

export default PageHeader;
