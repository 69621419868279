import { Modal as ModalAntd } from 'antd';
import classNames from 'classnames';

import Cross from '@/assets/svg/cross.svg?react';

import styles from './Modal.module.scss';
import { MODAL_DEFAULT_WIDTH } from './constants';
import { ModalProps } from './types';

const Modal = ({
  open,
  onCancel,
  onOk,
  children,
  footer,
  width = MODAL_DEFAULT_WIDTH,
  className,
  forceRender,
  destroyOnClose,
  closable,
}: ModalProps) => {
  return (
    <ModalAntd
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      footer={footer}
      forceRender={forceRender}
      className={classNames(styles.modal, className)}
      wrapClassName={styles.modalWrap}
      width={width}
      destroyOnClose={destroyOnClose}
      closeIcon={<Cross />}
      closable={closable}
      getContainer={document.getElementById('root') || undefined}
    >
      {children}
    </ModalAntd>
  );
};

export default Modal;
