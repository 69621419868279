import {
  COLOR_YELLOW_300,
  COLOR_YELLOW_400,
  COLOR_YELLOW_500,
  COLOR_YELLOW_600,
  COLOR_YELLOW_700,
  COLOR_YELLOW_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_YELLOWS = [
  COLOR_YELLOW_300,
  COLOR_YELLOW_400,
  COLOR_YELLOW_500,
  COLOR_YELLOW_600,
  COLOR_YELLOW_700,
  COLOR_YELLOW_800,
];
