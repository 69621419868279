import { Trans } from '@lingui/macro';
import { Row, Space } from 'antd';

import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { ROUTES } from '@/router';

type Props = {
  isDeletable: boolean;
  isEditable: boolean;
  isEditing: boolean;
  isSaving: boolean;
  onEditChange: (isEditing: boolean) => void;
  onDelete: () => void;
};

const UserDetailHeader = ({
  isDeletable,
  isEditable,
  isEditing,
  isSaving,
  onEditChange,
  onDelete,
}: Props) => {
  return (
    <Row align="middle" justify="space-between">
      <ReturnToLink route={ROUTES.users.path} routeName={<Trans>Users & Roles</Trans>} />
      <Space size={16}>
        {isEditable && isEditing ? (
          <>
            <Button
              color="black"
              variant="secondary"
              isDisabled={isSaving}
              onClick={() => onEditChange(false)}
            >
              <Trans>Cancel</Trans>
            </Button>
            <Button color="green" variant="primary" type="submit" isLoading={isSaving}>
              <Trans>Save</Trans>
            </Button>
          </>
        ) : (
          <>
            {isEditable && (
              <Button color="black" variant="secondary" onClick={() => onEditChange(true)}>
                <Trans>Edit User</Trans>
              </Button>
            )}
            {isDeletable && (
              <Button color="red" variant="secondary" onClick={() => onDelete()}>
                <Trans>Delete User</Trans>
              </Button>
            )}
          </>
        )}
      </Space>
    </Row>
  );
};

export default UserDetailHeader;
