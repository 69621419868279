import { Cell, CellTemplate, Compatible, Span, Uncertain } from '@silevis/reactgrid';
import * as React from 'react';

import styles from './IconCell.module.scss';

export interface IconCellInterface extends Cell, Span {
  type: 'icon';
  iconSrc?: string;
  onIconClick?: () => void;
}

class IconCell implements CellTemplate<IconCellInterface> {
  getCompatibleCell(uncertainCell: Uncertain<IconCellInterface>): Compatible<IconCellInterface> {
    return { ...uncertainCell, text: '', value: 0 };
  }

  isFocusable = (): boolean => false;

  getClassName(cell: Compatible<IconCellInterface>): string {
    return cell.className ? cell.className : '';
  }

  render(cell: Compatible<IconCellInterface>): React.ReactNode {
    return (
      <div onClick={cell?.onIconClick} className={styles.cellWrapper}>
        {cell?.iconSrc ? <img src={cell.iconSrc} /> : <></>}
      </div>
    );
  }
}

export default IconCell;
