import {
  COLOR_RED_300,
  COLOR_RED_400,
  COLOR_RED_500,
  COLOR_RED_600,
  COLOR_RED_700,
  COLOR_RED_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_REDS = [
  COLOR_RED_300,
  COLOR_RED_400,
  COLOR_RED_500,
  COLOR_RED_600,
  COLOR_RED_700,
  COLOR_RED_800,
];
