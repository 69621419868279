import gql from 'graphql-tag';
import moment from 'moment';
import { useMemo } from 'react';
import useSWR from 'swr';

import SolGraphQLError from '@/error/SolGraphQLError';
import { addMissingDates, calculateBeginningDate } from '@/helper/vendorSpendHelper';

import { fetcherSol } from '../swr-fetcher';
import {
  EndSessionGQLResponse,
  StartOrContinueSessionGQLResponse,
  VendorSpendCellData,
  VendorSpendDataType,
  VendorSpendGQLResponse,
  VendorSpendUpdateGQLResponse,
  VendorSpendUpdatePayload,
  VendorSpendUpdateVariables,
} from './vendorSpendType';

export const useVendorSpend = (isEditMode: boolean) => {
  // it's safe to revalidate data when we're in read-only mode. But, when the user starts editing
  // we'll want to stop that from happening so values aren't being shifted out from beneath them.
  const shouldRevalidate = !isEditMode;

  const { data, isLoading, error, mutate } = useSWR<VendorSpendGQLResponse, SolGraphQLError>(
    {
      query: gql`
        query VendorSpends($page: Pagination!, $mode: FileCreateModeEnum!) {
          vendorSpends {
            get(page: $page, mode: $mode) {
              tagInstallationDate
              edges {
                node {
                  id
                  provider {
                    id
                    name
                  }
                  vendor {
                    id
                    name
                    channel {
                      id
                      name
                    }
                  }
                  data {
                    amount
                    firstDayOfMonth
                    userSupplied
                  }
                }
              }
              totalEdges
            }
          }
        }
      `,
      variables: {
        page: {
          limit: 100,
          offset: 0,
        },
        mode: 'active',
      },
    },
    fetcherSol,
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: shouldRevalidate,
    },
  );

  const vendorSpendRows = useMemo(() => {
    const rows = data?.vendorSpends.get.edges.map((d) => d.node);
    return rows?.map((d) => ({
      ...d,
      data: addMissingDates(
        moment(data?.vendorSpends.get.tagInstallationDate || calculateBeginningDate(rows))
          .utc()
          .startOf('month')
          .toISOString(),
        d.data,
      ),
    }));
  }, [data]);

  return {
    data: vendorSpendRows,
    tagInstallationDate: data?.vendorSpends.get.tagInstallationDate,
    isLoading,
    error,
    mutate,
  };
};

export const mutateVendorSpend = async (vendorSpendData: VendorSpendDataType[]) => {
  // Send all cell values that are non-zero and only send rows with userSupplied data.
  const vendorSpendPayload = vendorSpendData
    .filter((item) => item.data[0].userSupplied !== false)
    .map<VendorSpendUpdatePayload>((item) => {
      let changedRow: VendorSpendCellData[] = item.data.filter((item) => item.amount !== 0);
      if (!changedRow.length) {
        changedRow = [item.data[0]] as VendorSpendCellData[];
      }

      return {
        vendorId: item.vendor.id,
        data: changedRow,
      };
    });

  const response = await fetcherSol<VendorSpendUpdateVariables, VendorSpendUpdateGQLResponse>({
    query: gql`
      mutation UpdateVendorSpends($vendors: [VendorSpendInput!]!, $mode: FileCreateModeEnum!) {
        vendorSpends {
          setAll(vendors: $vendors, mode: $mode) {
            success
          }
        }
      }
    `,
    variables: {
      vendors: vendorSpendPayload,
      mode: 'active',
    },
  });

  return response.vendorSpends.setAll;
};

export const startOrContinueSession = async () => {
  const response = await fetcherSol<unknown, StartOrContinueSessionGQLResponse>({
    query: gql`
      mutation StartOrContinueSession {
        vendorSpends {
          startOrContinueSession {
            success
          }
        }
      }
    `,
  });

  return response.vendorSpends.startOrContinueSession;
};

export const endSession = async () => {
  const response = await fetcherSol<unknown, EndSessionGQLResponse>({
    query: gql`
      mutation EndSession {
        vendorSpends {
          endSession {
            success
          }
        }
      }
    `,
  });

  return response.vendorSpends.endSession;
};
