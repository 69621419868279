import { Trans, t } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';

import { NumberInput } from '@/components/Form';
import { FilterOperatorRange, FilterOperatorValue } from '@/components/InstantSearch';
import { Link, Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './RangeFilter.module.scss';

type Props = {
  type?: 'number' | 'percent';
  value?: FilterOperatorRange;
  onChange?: (value: FilterOperatorValue) => void;
  onValidChange?: (valid: boolean) => void;
};

const formattersByType = {
  number: (value?: string | number) => (value ? numberFormat(value) : ''),
  percent: (value?: string | number) =>
    value != null && value !== '' ? numberFormat(value, { isPercent: true }) : '',
};

const RangeFilter = ({ type = 'number', value, onChange, onValidChange }: Props) => {
  const minInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [min, setMin] = useState<string | number | undefined | null>(value?.min?.operand);
  const [max, setMax] = useState<string | number | undefined | null>(value?.max?.operand);

  useEffect(() => {
    minInputRef.current?.focus();
  }, [minInputRef]);

  const handleRangeChange = (
    min: number | string | undefined | null,
    max: number | string | undefined | null,
  ) => {
    if (min != null && max != null && +min > +max) {
      setError(t`Min value should be less than the max`);
      onValidChange?.(false);
    } else {
      setError(null);
      onValidChange?.(true);

      if (min == null && max == null) {
        onChange?.(null);
      } else {
        onChange?.({
          min: min == null ? undefined : { inclusive: true, operand: `${min}` },
          max: max == null ? undefined : { inclusive: true, operand: `${max}` },
        });
      }
    }
  };

  const handleMinChange = (newMin: string | number | null) => {
    setMin(newMin);
    handleRangeChange(newMin, max);
  };

  const handleMaxChange = (newMax: string | number | null) => {
    setMax(newMax);
    handleRangeChange(min, newMax);
  };

  const handleClear = () => {
    setMin(null);
    setMax(null);
    handleRangeChange(null, null);
  };

  return (
    <div className={styles.container}>
      <Link variant="caption1" onClick={handleClear}>
        <Trans>Clear</Trans>
      </Link>
      <Text variant="body1" weight="bold">
        <Trans>Between</Trans>
      </Text>
      <div className={styles.inputContainer}>
        <NumberInput
          ref={minInputRef}
          className={styles.input}
          aria-label={t`Change minimum value in range`}
          placeholder="min"
          value={min}
          min="0"
          max="999999999"
          step={type === 'percent' ? 0.01 : 1}
          parser={
            type === 'percent'
              ? (value) =>
                  value !== '' ? (value?.replace('%', '') as unknown as number) / 100 : ''
              : undefined
          }
          formatter={formattersByType[type]}
          onChange={handleMinChange}
        />
        <NumberInput
          className={styles.input}
          aria-label={t`Change maximum value in range`}
          placeholder="max"
          value={max}
          min="0"
          max="999999999"
          step={type === 'percent' ? 0.01 : 1}
          parser={
            type === 'percent'
              ? (value) =>
                  value !== '' ? (value?.replace('%', '') as unknown as number) / 100 : ''
              : undefined
          }
          formatter={formattersByType[type]}
          onChange={handleMaxChange}
        />
      </div>
      {error && (
        <Text variant="body2" color="red" italic>
          {error}
        </Text>
      )}
    </div>
  );
};

export default RangeFilter;
