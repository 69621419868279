import { Trans, t } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { clearLastVisitedOrgSlug } from '@/helper/common';
import { ROUTES } from '@/router';

import SignInPanel from '../SignInPanel';

const Organization404 = () => {
  const handleChangeOrg = () => {
    clearLastVisitedOrgSlug();
  };

  return (
    <SignInPanel
      title={<Trans>We couldn't find your account</Trans>}
      description={
        <Trans>
          If you can't remember your account's sign in URL, we can{' '}
          <Link onClick={handleChangeOrg} to={ROUTES.signIn.path}>{t`send you a reminder`}</Link>.
        </Trans>
      }
    />
  );
};

export default Organization404;
