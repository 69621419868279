import { UserType } from '../user/userType';

export enum INTEGRATION_PROVIDER_ID {
  SALESFORCE = 'oprv:salesforce',
  LINKEDIN = 'oprv:linkedin',
  GOOGLE_ADS = 'oprv:google',
  BING_ADS = 'oprv:bing',
  FACEBOOK_ADS = 'oprv:facebook',
  X_ADS = 'oprv:twitter',
  ADROLL_ADS = 'oprv:adroll',
  REDDIT_ADS = 'oprv:reddit',
  ROLLWORKS_ADS = 'oprv:rollworks',
  SLACK_AI = 'oprv:slack',
  TIKTOK_ADS = 'oprv:tiktok',
  DEMANDBASE_ADS = 'oprv:demandbase',
}

export type IntegrationProviderType = {
  id: INTEGRATION_PROVIDER_ID;
  name: string;
  startUrl: string;
  type: string;
  multiConnection: boolean;
  syncIntervalHours: number;
  connections: IntegrationConnectionType[];
};

export type IntegrationJobType = {
  created_at: string;
  status?: 'error' | 'completed' | 'processing';
  errors?: string | null;
} | null;

export type IntegrationConnectionType = {
  id: string;
  createdBy?: UserType;
  lastJob?: IntegrationJobType;
  organizationId?: string;
  organizationName?: string;
  status: 'active' | 'inactive';
};

export type IntegrationOrgType = {
  id: string;
  name: string;
};

export type IntegrationProviderListGQLResponse = {
  oAuthProviders: {
    get: {
      edges: {
        node: Omit<IntegrationProviderType, 'connections'> & {
          connections: {
            edges: {
              node: IntegrationConnectionType;
            }[];
          };
        };
      }[];
    };
  };
};

export type IntegrationTokenCreateVariablesType = {
  oAuthProviderId: string;
  params: {
    name: string;
    value: string;
  }[];
};

export type IntegrationTokenCreateGQLResponse = {
  oAuthTokens: {
    create: {
      id: string;
      orgs?: IntegrationOrgType[];
      connection?: {
        id: string;
      };
    };
  };
};

export type IntegrationConnectionCreateVariablesType = {
  oAuthTokenId: string;
  orgId: string;
};

export type IntegrationConnectionCreateGQLResponse = {
  oAuthConnections: {
    create: {
      id: string;
    };
  };
};

export type IntegrationConnectionDeleteVariablesType = {
  id: string;
};

export type IntegrationConnectionDeleteGQLResponse = {
  oAuthConnections: {
    delete: {
      success: boolean;
      id: string;
    };
  };
};
