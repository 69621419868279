import { t } from '@lingui/macro';
import { TableRef } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useMemo, useRef } from 'react';

import { EventLogType } from '@/api/eventLog';
import Edit from '@/assets/svg/edit.svg?react';
import Delete from '@/assets/svg/trashcan.svg?react';
import Table, { ColumnsType, MoreActionsCell } from '@/components/Table';
import { USDateViewFormat } from '@/constants/formats';

import styles from './NotesPanelDrawerTable.module.scss';

enum ActionKey {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

type Props = {
  isOpen?: boolean;
  data?: EventLogType[];
  isLoading?: boolean;
  error?: Error;
  selectedItem?: EventLogType;
  onSelectionChange?: (note?: EventLogType) => void;
  onNoteEdit: (note: EventLogType) => void;
  onNoteDelete: (note: EventLogType) => void;
};

const NotesPanelDrawerTable = ({
  data,
  isLoading,
  error,
  selectedItem,
  onSelectionChange,
  onNoteEdit,
  onNoteDelete,
}: Props) => {
  const tableWrapperRef = useRef<TableRef>(null);
  useEffect(() => {
    if (selectedItem && tableWrapperRef.current) {
      const selectedRowEl =
        tableWrapperRef.current.nativeElement.querySelector<HTMLTableRowElement>(
          `.${styles.selectedRow}`,
        );
      if (selectedRowEl) {
        selectedRowEl.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
      }
    }
  }, [selectedItem]);

  const columns = useMemo<ColumnsType<EventLogType>>(
    () => [
      {
        title: t`Date`,
        key: 'date',
        width: 100,
        render: (text, record) => moment(record.date).utc().format(USDateViewFormat),
      },
      {
        title: t`Details`,
        key: 'rawDetails',
        width: '50%',
        render: (text, record) => record.rawDetails,
      },
      {
        title: t`Source`,
        key: 'source',
        width: '20%',
        render: (text, record) => record.source,
      },
      {
        title: t`Actions`,
        key: 'actions',
        width: '10%',
        render: (text, record) =>
          record.type === 'Note' && (
            <MoreActionsCell
              size="small"
              menuItems={[
                {
                  key: ActionKey.EDIT,
                  label: t`Edit`,
                  icon: <Edit />,
                },
                {
                  key: ActionKey.DELETE,
                  label: t`Delete`,
                  color: 'red',
                  icon: <Delete />,
                },
              ]}
              onSelect={(key) => handleActionSelect(key as ActionKey, record)}
            />
          ),
      },
    ],
    [],
  );

  const handleActionSelect = (key: ActionKey, note: EventLogType) => {
    if (key === ActionKey.EDIT) {
      onNoteEdit(note);
    } else if (key === ActionKey.DELETE) {
      onNoteDelete(note);
    }
  };

  return (
    <Table<EventLogType>
      ref={tableWrapperRef}
      data-testid="notes-table"
      className={styles.noteTable}
      columns={columns}
      dataSource={data}
      loading={isLoading}
      error={error}
      emptyMessage={t`No notes were found`}
      showHeader={false}
      size="small"
      rowKey={(record) => record.id}
      onRow={(record) => ({
        className: record === selectedItem ? styles.selectedRow : undefined,
        onMouseEnter: () => onSelectionChange?.(record),
        onMouseLeave: () => onSelectionChange?.(undefined),
      })}
    />
  );
};

export default NotesPanelDrawerTable;
