import { Trans, t } from '@lingui/macro';
import { Row, Space } from 'antd';
import { RuleObject } from 'antd/es/form';
import { useCallback, useState } from 'react';
import { FileRejection } from 'react-dropzone-esm';
import { useNavigate } from 'react-router-dom';

import { mutateAudienceCreate, uploadAudienceDomains } from '@/api/audience';
import Form, {
  isFormValid,
  resetFieldErrors,
  setFieldErrors,
  setFieldValue,
} from '@/components/Form';
import Prompt from '@/components/Prompt';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link, Text } from '@/components/typography';
import useErrorDisplay from '@/error/useErrorDisplay';
import useGlobalPaste from '@/hooks/useClipboard';
import { ROUTES } from '@/router';

import AudienceBaseForm from '../AudienceBaseForm';
import AudienceTextArea from './AudienceTextArea';
import AudienceUpload from './AudienceUpload';
import { splitBySeparators } from './util';
import { isDomainValid } from './validators';

const MODE_DROPZONE = 'dropzone';
const MODE_TEXTAREA = 'textarea';
type Mode = 'dropzone' | 'textarea';

const MAX_FILE_SIZE = 6_291_456; // 6MB
const MAX_FILE_SIZE_TEXT = '6MB';

const FORM_NAME_DATASET_ID = 'dataset_id';
const FORM_NAME_DOMAINS = 'domains';

const TOKEN_SEPARATORS = [',', ' ', '\n'];

type FormType = {
  name: string;
  description: string;
  [FORM_NAME_DATASET_ID]?: string;
  [FORM_NAME_DOMAINS]?: string[];
};

const CreateCustomAudience = () => {
  const [mode, setMode] = useState<Mode>('dropzone');
  const [isValid, setIsValid] = useState<boolean>(false);
  const displayErrors = useErrorDisplay();
  const [isCreatingAudience, setIsCreatingAudience] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useGlobalPaste((pastedText) => {
    // If the user has already uploaded a file, we don't want copy/paste to allow them to also
    // manually enter domains into a textarea.
    const isFileUploaded = form.getFieldValue(FORM_NAME_DATASET_ID) != null;
    const isInDropzoneMode = mode === MODE_DROPZONE;

    if (isInDropzoneMode && !isFileUploaded) {
      // domains must be unique to match manual-entry behavior in <TagTextArea />
      setFieldValue(
        form,
        FORM_NAME_DOMAINS,
        [...new Set(splitBySeparators(pastedText, TOKEN_SEPARATORS))].map((value) => value.trim()),
      );

      // switch views to show textarea with values pasted into it.
      setMode(MODE_TEXTAREA);

      // validate the recently passed list of domains. Need to wait a frame for the values to be
      // processed by the component first before asking for validation. Otherwise, no validation
      // on the values occurs.
      setTimeout(() => form.validateFields([FORM_NAME_DOMAINS]), 0);
    }
  });

  const handleReturnToListView = useCallback(() => {
    navigate(ROUTES.audiences.path);
  }, []);

  const handleFinish = async (values: FormType) => {
    setIsCreatingAudience(true);

    try {
      const { name, description, dataset_id, domains } = values;
      let audienceDatasetId = dataset_id;

      if (!audienceDatasetId && domains) {
        audienceDatasetId = await uploadAudienceDomains(domains);
      }

      if (audienceDatasetId) {
        await mutateAudienceCreate({
          type: 'upload',
          name,
          description,
          dataset_id: audienceDatasetId,
        });
        navigate(ROUTES.audiences.path, {
          state: {
            successMessage: t`Audience was successfully created.`,
          },
        });
      }
    } catch (e) {
      displayErrors({ error: e, form });
    }
    setIsCreatingAudience(false);
  };

  const handleFileAccepted = () => {
    resetFieldErrors(form, FORM_NAME_DATASET_ID);
  };

  const handleFileRejection = (fileRejections: FileRejection[]) => {
    if (fileRejections.length) {
      const errors = fileRejections[0].errors.map((error) => {
        if (error.code === 'file-too-large') {
          return t`File is larger than ${MAX_FILE_SIZE_TEXT}. Try removing any extra columns besides a single "Website" or "Domain" column to reduce the file size and try again.`;
        }
        return t`File is not valid`;
      });

      setFieldErrors(form, FORM_NAME_DATASET_ID, errors);
    }
  };

  const handleUploadError = (errorMessage: string) => {
    setFieldErrors(form, FORM_NAME_DATASET_ID, [errorMessage]);
  };

  const handleValuesChange = async () => {
    const valid = await isFormValid(form);
    setIsValid(valid);
  };

  return (
    <Page title={t`New Audience`}>
      <Form
        form={form}
        validateTrigger="onBlur"
        onFinish={handleFinish}
        onValuesChange={handleValuesChange}
      >
        <Prompt
          message={t`Are you sure you want to exit without saving your new Audience?`}
          shouldPreventNavigation={isValid && !isCreatingAudience}
        />
        <Space size={24} direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <ReturnToLink route={ROUTES.audiences.path} routeName={t`Audiences`} />
            <Space size={16}>
              <Button color="black" variant="secondary" onClick={handleReturnToListView}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                color="green"
                variant="primary"
                isDisabled={isCreatingAudience}
                isLoading={isCreatingAudience}
                type="submit"
              >
                <Trans>Save</Trans>
              </Button>
            </Space>
          </Row>
          <Panel
            title={t`Custom Audience Upload`}
            size="L"
            data-testid="custom-audience-upload-panel"
          >
            {mode === MODE_DROPZONE && (
              <>
                <div>
                  <Trans>
                    <Text variant="label">Note: </Text>
                    <Text variant="body1">
                      Your file must contain a column named "<Text variant="label">Website</Text>"
                      or "<Text variant="label">Domain</Text>", all accounts must have a valid
                      domain to correctly match and fill must be a .csv or .xls format.
                    </Text>
                  </Trans>
                </div>
                <Form.Item
                  label={<Trans>CSV, XLS, or Copy & Paste</Trans>}
                  name={[FORM_NAME_DATASET_ID]}
                  initialValue={null}
                  rules={[{ required: true, message: <Trans>Audience file is Required</Trans> }]}
                >
                  <AudienceUpload
                    disabled={isCreatingAudience}
                    maxFileSize={MAX_FILE_SIZE}
                    onFileAccepted={handleFileAccepted}
                    onFileRejected={handleFileRejection}
                    onUploadError={handleUploadError}
                  >
                    <Trans>
                      Drag and drop file,{' '}
                      <Link variant="body2" onClick={() => setMode(MODE_TEXTAREA)}>
                        {t`Copy and Paste`}
                      </Link>
                    </Trans>
                  </AudienceUpload>
                </Form.Item>
              </>
            )}
            {mode === MODE_TEXTAREA && (
              <>
                <div>
                  <Trans>
                    <Text variant="label">Note: </Text>
                    <Text variant="body1">
                      All accounts must have a valid domain to match correctly.
                    </Text>
                  </Trans>
                </div>
                <Form.Item
                  label={<Trans>Copy & Paste or Input Text</Trans>}
                  name={FORM_NAME_DOMAINS}
                  initialValue={[]}
                  validateTrigger={['onChange', 'onBlur']}
                  rules={[
                    { required: true, message: <Trans>At least one domain is required</Trans> },
                    {
                      message: (
                        <Trans>
                          Values should be valid domain names (e.g. example.com), contain no spaces,
                          and must be separated by commas
                        </Trans>
                      ),
                      warningOnly: true,
                      validator: (_: RuleObject, values: string[]) => {
                        return values.some((val) => isDomainValid(val) === false)
                          ? Promise.reject()
                          : Promise.resolve();
                      },
                    },
                  ]}
                >
                  <AudienceTextArea
                    isDisabled={isCreatingAudience}
                    tokenSeparators={TOKEN_SEPARATORS}
                  />
                </Form.Item>
              </>
            )}
            <AudienceBaseForm isDisabled={isCreatingAudience} />
          </Panel>
        </Space>
      </Form>
    </Page>
  );
};

export default CreateCustomAudience;
