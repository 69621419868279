import gql from 'graphql-tag';
import useSWR from 'swr';

import SolGraphQLError from '@/error/SolGraphQLError';

import { fetcherSol } from '../swr-fetcher';
import {
  ChannelMappingRuleDataType,
  ChannelMappingRulesGQLResponse,
  ChannelMappingRulesUpdateGQLResponse,
  ChannelMappingRulesUpdateVariables,
  EndSessionGQLResponse,
  StartOrContinueSessionGQLResponse,
} from './channelMappingRulesType';

export const useChannelMappingRules = (isEditMode: boolean) => {
  // it's safe to revalidate data when we're in read-only mode. But, when the user starts editing
  // we'll want to stop that from happening so values aren't being shifted out from beneath them.
  const shouldRevalidate = !isEditMode;

  const { data, error, isLoading, mutate } = useSWR<
    ChannelMappingRulesGQLResponse,
    SolGraphQLError
  >(
    {
      query: gql`
        query ChannelMappingRule(
          $page: Pagination!
          $filter: [FilterParamInput]!
          $mode: FileCreateModeEnum!
        ) {
          channelMappingRules {
            get(page: $page, filter: $filter, mode: $mode) {
              edges {
                node {
                  id
                  vendor {
                    id
                    name
                    channel {
                      id
                      name
                    }
                  }
                  referralDomains
                  media
                  sources
                  customParameters {
                    name
                    values
                  }
                  priority
                  isC99
                }
              }
            }
          }
        }
      `,
      variables: {
        page: {
          limit: 1000000,
          offset: 0,
        },
        filter: [], // not enabled for this query, filtering happens client-side
        mode: 'active',
      },
    },
    fetcherSol,
    {
      revalidateIfStale: shouldRevalidate,
      revalidateOnFocus: shouldRevalidate,
      revalidateOnReconnect: shouldRevalidate,
    },
  );

  return {
    data: data?.channelMappingRules.get.edges.map((edge) => edge.node),
    isLoading,
    error,
    mutate,
  };
};

export const mutateChannelMappingRules = async (
  channelMappingRules: ChannelMappingRuleDataType[],
) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const rules = channelMappingRules.map(({ vendor, id, priority, ...rest }) => ({
    // client-side validation should prevent us from sending incomplete vendor/channel metadata
    vendorId: vendor!.id!,
    channelId: vendor!.channel!.id,
    ...rest,
  }));

  const response = await fetcherSol<
    ChannelMappingRulesUpdateVariables,
    ChannelMappingRulesUpdateGQLResponse
  >({
    query: gql`
      mutation UpdateChannelMappingRules(
        $rules: [ChannelMappingRuleInput!]!
        $mode: FileCreateModeEnum!
      ) {
        channelMappingRules {
          setAll(rules: $rules, mode: $mode) {
            success
          }
        }
      }
    `,
    variables: {
      rules,
      mode: 'active',
    },
  });

  return response.channelMappingRules.setAll;
};

export const startOrContinueSession = async () => {
  const response = await fetcherSol<unknown, StartOrContinueSessionGQLResponse>({
    query: gql`
      mutation StartOrContinueSession {
        channelMappingRules {
          startOrContinueSession {
            success
          }
        }
      }
    `,
  });

  return response.channelMappingRules.startOrContinueSession;
};

export const endSession = async () => {
  const response = await fetcherSol<unknown, EndSessionGQLResponse>({
    query: gql`
      mutation EndSession {
        channelMappingRules {
          endSession {
            success
          }
        }
      }
    `,
  });

  return response.channelMappingRules.endSession;
};
