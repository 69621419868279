import { t } from '@lingui/macro';
import { ReactNode } from 'react';

import LoadingIndicator from '@/components/LoadingIndicator';
import { Text } from '@/components/typography';

import styles from './PageSpinner.module.scss';

type Props = {
  text?: ReactNode;
};

const PageSpinner = ({ text = t`Loading data` }: Props) => {
  return (
    <div className={styles.container}>
      <LoadingIndicator size="large" />
      <Text variant="subhead">{text}</Text>
    </div>
  );
};

export default PageSpinner;
