import { t } from '@lingui/macro';
import { FormInstance } from 'antd';

import { SolError } from '@/api/solType';
import { useNotification } from '@/providers/Notification';

import SolGraphQLError from './SolGraphQLError';

type ErrorContextProps = {
  error: SolGraphQLError | Error | unknown;
  form?: FormInstance;
};

type NamePath = string | number | (string | number)[];
type FieldData = {
  name: NamePath;
  errors?: string[];
  warnings?: string[];
};

const displayInlineFormErrors = (form: FormInstance, errors: SolError[]) => {
  const fieldErrorsByFieldName = errors.reduce<Record<string, FieldData>>((memo, flError) => {
    const { fieldName } = flError.extensions.details || {};

    if (!fieldName) {
      return memo;
    }

    const fieldObject = memo[fieldName] ? memo[fieldName] : { name: fieldName, errors: [] };
    fieldObject.errors = fieldObject.errors || [];
    fieldObject.errors.push(flError.message);
    memo[fieldName] = fieldObject;
    return memo;
  }, {});

  // Set the field errors into the form context and let it display them.
  form.setFields(Object.values(fieldErrorsByFieldName));
};

const useErrorDisplay = () => {
  const { pushNotification } = useNotification();

  return ({ form, error: apiError }: ErrorContextProps) => {
    // Split errors into "page-level" and "inline-level" errors. The latter are SolError objects
    // which have a corresponding field in the form and will be displayed directly beneath the
    // <Form.Item /> component. The former are generic errors for the entire page and could be
    // SolErrors or generic unknown Errors.
    const fieldLevelErrors: SolError[] = [];
    const pageLevelErrors: (SolError | Error)[] = [];

    if (apiError instanceof SolGraphQLError) {
      Object.values(apiError.errors).forEach((error) => {
        const { fieldName } = error.extensions.details || {};
        if (fieldName != null && form) {
          // Try to get a value for this field. If the field exists, we should have something.
          const fieldValue = form.getFieldValue(fieldName);
          const fieldExistsInForm = fieldValue != null;
          if (fieldExistsInForm) {
            fieldLevelErrors.push(error);
          } else {
            // We can't seem to find the field in the `form` context. Let's just treat this as a
            // page-level error. Also, be sure to log it so we can discover it in our logs and fix
            // it later in the UI or Sol.
            console.error(
              `Field '${fieldName}' does not exist on form with the following names: ${Object.keys(
                form.getFieldsValue(),
              )}`,
            );
            pageLevelErrors.push(error);
          }
        } else {
          // Sol designated this as a page-level error
          pageLevelErrors.push(error);
        }
      });
    } else {
      // We have an unknown error (something not from Sol) which could have happened on the request
      // or some surrounding code that broke something. Either way it never made it to Sol.
      pageLevelErrors.push(
        new Error(t`An unknown error has occurred, please try again or contact support.`),
      );
      console.error(`An unknown error occurred`);
    }

    if (fieldLevelErrors.length && form) {
      displayInlineFormErrors(form, fieldLevelErrors);
    }

    if (pageLevelErrors.length) {
      pageLevelErrors.forEach((error) => {
        pushNotification({ type: 'error', message: error.message });
      });
    }
  };
};

export default useErrorDisplay;
