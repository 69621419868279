import { List as AntdList } from 'antd';
import classNames from 'classnames';
import { CSSProperties, PropsWithChildren, forwardRef, useContext } from 'react';

import { Flex } from '@/components/Flex';
import { Checkbox } from '@/components/Form';
import { Text } from '@/components/typography';

import { ListContext } from './List';
import styles from './ListItem.module.scss';

type Props = PropsWithChildren<{
  style?: CSSProperties;
  id: string;
  interactive?: boolean;
}>;

const ListItem = forwardRef<HTMLDivElement, Props>(
  ({ style, id, interactive = true, children }, ref) => {
    const { selectedKeys, selectionMode, onSelectedKeysChange } = useContext(ListContext);

    const isChecked = selectedKeys?.includes(id) ?? false;

    const handleClick = () => {
      if (selectionMode === 'none') {
        return;
      }

      if (selectionMode === 'single') {
        onSelectedKeysChange?.(isChecked ? [] : [id]);
      }

      if (selectionMode === 'multiple') {
        onSelectedKeysChange?.(
          isChecked ? selectedKeys?.filter((key) => key !== id) : [...(selectedKeys ?? []), id],
        );
      }
    };

    return (
      <AntdList.Item
        ref={ref}
        className={classNames(styles.listItem, { [styles.nonInteractive]: !interactive })}
        style={style}
        key={id}
        onClick={handleClick}
      >
        <Flex align="center" gap="medium" fullWidth>
          <Flex flex={1} align="center" gap="medium" fullWidth>
            {selectionMode !== 'none' && interactive && <Checkbox value={id} checked={isChecked} />}
            <Text style={{ flex: 1 }} variant="body1">
              {children}
            </Text>
          </Flex>
        </Flex>
      </AntdList.Item>
    );
  },
);

export default ListItem;
