import { Grid, Layout } from 'antd';

import { SIDER_WIDTH } from '@/constants/dimensions';

import { Menu } from '../Menu';
import styles from './Sider.module.scss';

const { Sider: SiderAntd } = Layout;

const { useBreakpoint } = Grid;

type Props = {
  isOpen?: boolean;
};

const Sider = ({ isOpen = false }: Props) => {
  const screens = useBreakpoint();

  return (
    <SiderAntd
      className={styles.sider}
      collapsed={screens.xs && !isOpen}
      collapsedWidth="0"
      width={SIDER_WIDTH}
    >
      <Menu />
    </SiderAntd>
  );
};

export default Sider;
