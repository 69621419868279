import { useChannelList } from '@/api/channel';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { CategoryFilter } from '@/components/filters';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const ChannelFilter = ({ onChange, ...rest }: Props) => {
  const { channels, isLoading, error } = useChannelList();

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={channels}
      onChange={onChange}
    />
  );
};

export default ChannelFilter;
