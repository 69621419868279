import { Trans, t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { useChannelById } from '@/api/channel';
import { Flex } from '@/components/Flex';
import { ReturnToLink } from '@/components/ReturnToLink';
import Error404 from '@/components/errors/Error404';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';
import { ROUTES } from '@/router';

import ChannelDetailKPIMetrics from './ChannelDetailKPIMetrics';
import ChannelVisitsOverviewTable from './ChannelVisitsOverviewTable';
import ChannelWeeklyVisitsChart from './ChannelWeeklyVisitsChart';

const ChannelDetail = () => {
  const { channel: channelId } = useParams();
  const { channel, isLoading } = useChannelById(channelId);

  return (
    <Page title={t`Channels`} pageName={channel?.name}>
      <Flex vertical gap="large" fullWidth>
        <ReturnToLink route={ROUTES.channels.path} routeName={<Trans>Channels</Trans>} />
        {!isLoading && channel == null ? (
          <Error404 message={<Trans>The channel you're looking for does not exist</Trans>} />
        ) : (
          <>
            <PageFilterProvider>
              <Flex justify="space-between">
                <PageDateRangeFilter />
                <PageAudienceFilter />
              </Flex>
              <ChannelDetailKPIMetrics channelId={channel?.id} />
              <ChannelWeeklyVisitsChart channelId={channel?.id} />
              <ChannelVisitsOverviewTable channelId={channel?.id} />
            </PageFilterProvider>
          </>
        )}
      </Flex>
    </Page>
  );
};

export default ChannelDetail;
