import { PropsWithChildren, ReactNode } from 'react';

import WidgetError from '@/components/errors/WidgetError';

import styles from './DonutContainer.module.scss';

type Props = {
  error?: Error;
};

const DonutContainer = ({ error, children, ...rest }: PropsWithChildren<Props>) => {
  return (
    <div {...rest} className={styles.content}>
      {error ? <WidgetError /> : children}
    </div>
  );
};

type ItemProps = {
  minWidth: number;
  height: number;
  caption?: ReactNode;
};

DonutContainer.Item = ({
  minWidth,
  height,
  caption,
  children,
  ...rest
}: PropsWithChildren<ItemProps>) => {
  return (
    <div {...rest} className={styles.widgetItem} style={{ minWidth, height }}>
      {children}
      {caption && <div className={styles.widgetCaption}>{caption}</div>}
    </div>
  );
};

export default DonutContainer;
