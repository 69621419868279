import classNames from 'classnames';
import { CSSProperties, PropsWithChildren } from 'react';

import HeaderSetter from '@/hooks/header/HeaderSetter';

import styles from './Page.module.scss';

type Props = {
  className?: string;
  title?: string;
  pageName?: string;
  style?: CSSProperties;
  noPadding?: boolean;
};

const Page = ({
  className = '',
  title,
  pageName,
  children,
  style = {},
  noPadding = false,
  ...rest
}: PropsWithChildren<Props>) => (
  <div
    id="pageRoot"
    {...rest}
    className={classNames(className, styles.layout, { [styles.noPadding]: noPadding })}
    style={style}
  >
    {title && <HeaderSetter>{`${title}${pageName != null ? ` - ${pageName}` : ''}`}</HeaderSetter>}
    {children}
  </div>
);

export default Page;
