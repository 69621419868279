import { t } from '@lingui/macro';

import { OpportunityType } from '@/api/opportunity';
import { MarkerDataType } from '@/components/Flare/annotation';
import TooltipRow from '@/components/Flare/guides/TooltipRow';
import Tooltip from '@/components/Tooltip';
import { USDateViewFormat } from '@/constants/formats';
import { formatDate } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data: MarkerDataType;
  opportunity?: OpportunityType;
};

const ClosedWonMarkerTooltip = ({ data, opportunity }: Props) => {
  return (
    <Tooltip
      yOffset={20}
      title={t`Opportunity`}
      body={
        <table>
          <tbody>
            <TooltipRow
              name={t`Opportunity Open`}
              value={formatDate(opportunity?.openDate, USDateViewFormat, { isUTC: true })}
            />
            <TooltipRow
              name={t`Opportunity Closed`}
              value={formatDate(opportunity?.closeDate, USDateViewFormat, { isUTC: true })}
            />
            <TooltipRow
              name={t`Amount`}
              value={numberFormat(opportunity?.amount, {
                isCurrency: true,
                precision: 0,
              })}
            />
          </tbody>
        </table>
      }
    >
      {data.label}
    </Tooltip>
  );
};

export default ClosedWonMarkerTooltip;
