import { InstanceShortDataType, UserInstanceType } from '@/api/organization';

const WEB_STORAGE = {
  LAST_VISITED_ORG: `channel99-last_visited_org-${import.meta.env.VITE_ENVIRONMENT}`,
  FOREIGN_SUPERUSER_INSTANCE: `channel99-foreign_superuser_instance-${
    import.meta.env.VITE_ENVIRONMENT
  }`,
  DEFAULT_DATE_RANGE: `channel99-default-date-range-${import.meta.env.VITE_ENVIRONMENT}`,
};

export const getLastVisitedOrgSlug = () => localStorage.getItem(WEB_STORAGE.LAST_VISITED_ORG);
export const setLastVisitedOrgSlug = (orgSlug: string) =>
  localStorage.setItem(WEB_STORAGE.LAST_VISITED_ORG, orgSlug);
export const clearLastVisitedOrgSlug = () => localStorage.removeItem(WEB_STORAGE.LAST_VISITED_ORG);

export const getForeignSuperuserInstance = () => {
  const instanceStr = localStorage.getItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);

  if (instanceStr == null) {
    return null;
  }

  try {
    return JSON.parse(instanceStr) as UserInstanceType;
  } catch {
    // oops! Old format, just return the id
    // Should be able to delete this after a few weeks
    return {
      id: '',
      name: instanceStr?.replace(/^inst:/, ''),
      slug: instanceStr?.replace(/^inst:/, 'inst~'),
      instanceId: instanceStr,
    } as UserInstanceType;
  }
};
export const setForeignSuperuserInstance = (instance: InstanceShortDataType) => {
  const instanceObj = {
    id: '',
    name: instance.name,
    slug: instance.id.replace(/^inst:/, 'inst~'),
    instanceId: instance.id,
  } as UserInstanceType;
  localStorage.setItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE, JSON.stringify(instanceObj));
};
export const clearForeignSuperuserInstance = () =>
  localStorage.removeItem(WEB_STORAGE.FOREIGN_SUPERUSER_INSTANCE);

export const getDefaultDateRange = () => {
  const dateRange = sessionStorage.getItem(WEB_STORAGE.DEFAULT_DATE_RANGE);
  if (dateRange) {
    return JSON.parse(dateRange) as { start_date: string; end_date: string };
  }
  return null;
};
export const setDefaultDateRange = (dateRange: { start_date: string; end_date: string }) =>
  sessionStorage.setItem(WEB_STORAGE.DEFAULT_DATE_RANGE, JSON.stringify(dateRange));
export const removeDefaultDateRange = () =>
  sessionStorage.removeItem(WEB_STORAGE.DEFAULT_DATE_RANGE);

export const clearSessionStorage = () => sessionStorage.clear();
