import { Trans, t } from '@lingui/macro';
import { Space } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useMemo, useState } from 'react';

import { OpportunityType, useRecentDeals } from '@/api/opportunity';
import { Option, Select, SelectProps } from '@/components/Form';
import { Text } from '@/components/typography';

import styles from './ClosedWonSelect.module.scss';

interface Props extends SelectProps {
  value?: OpportunityType | null;
  onChange?: (deal: OpportunityType) => void;
}

const ClosedWonSelect = ({ value, onChange, ...rest }: Props) => {
  const { recentDeals, isLoading } = useRecentDeals();
  const [search, setSearch] = useState<string>('');

  const handleChange = ({ value: dealId }: DefaultOptionType) => {
    const recentDeal = recentDeals?.find((deal) => deal.id === dealId);
    setSearch('');

    if (onChange && recentDeal) {
      onChange?.(recentDeal);
    }
  };

  const filteredDeals = useMemo(() => {
    if (search.length === 0) {
      return recentDeals;
    }

    return recentDeals?.filter((deal) => {
      return deal.account?.company.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [recentDeals, search]);

  return (
    <Select
      width={250}
      isLoading={isLoading}
      value={{ value: value?.id, label: value?.account?.company.name }}
      onChange={handleChange}
      onSearch={setSearch}
      placeholder={t`Select Opportunity...`}
      notFoundContent={<Trans>No opportunities were found...</Trans>}
      labelInValue
      filterOption={false}
      showSearch
      {...rest}
    >
      {(filteredDeals || [])?.map((deal) => (
        <Option key={deal.id} value={deal.id}>
          <Space direction="vertical" size={0}>
            <Text className={styles.dropdownLabel} variant="body1" color="grey" weight="bold">
              {deal.account?.company.name}
            </Text>
            <Text className={styles.dropdownLabel} variant="caption1" color="grey">
              {deal.name}
            </Text>
          </Space>
        </Option>
      ))}
    </Select>
  );
};

export default ClosedWonSelect;
