import classNames from 'classnames';
import { DetailedHTMLProps, ElementType, HTMLAttributes, forwardRef } from 'react';

import { TextColorType, TextWeightType, TitleLevelType } from '../types';
import styles from './Title.module.scss';

type TitleHTMLType = DetailedHTMLProps<HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;

export type TitleProps = TitleHTMLType & {
  level: TitleLevelType;
  color?: TextColorType;
  weight?: TextWeightType;
  inline?: boolean;
};

const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ className, level, color = 'black', weight, inline, ...rest }, ref) => {
    const Element = `h${level}` as ElementType;

    return (
      <Element
        {...rest}
        ref={ref}
        className={classNames(
          className,
          styles.title,
          styles[`color-${color}`],
          styles[`level-${level}`],
          styles[`weight-${weight ?? 'regular'}`],
          {
            [styles.inline]: inline,
          },
        )}
      />
    );
  },
);

export default Title;
