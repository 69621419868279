import classNames from 'classnames';
import { Options } from 'highcharts';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { FlareChart } from '@/components/Flare';
import { useFlareContext } from '@/components/Flare/FlareContext';

import styles from './FlareBenchmarkLine.module.scss';

type Props = {
  index: number;
};

const buildBenchmarkLineOptions = (
  options: Options,
  index: number,
  onChartRedraw: (chart: FlareChart) => void,
  onMutedChange: (isMuted: boolean) => void,
): Options => {
  const isPointMuted = (chart: FlareChart) => {
    const selectedPoints = chart.getSelectedPoints();
    const hoveredPoints = chart.hoverPoints ?? [];
    const hasPointsOfInterest = selectedPoints.length > 0 || hoveredPoints.length > 0;

    if (hasPointsOfInterest) {
      const isPointSelected = selectedPoints.some((point) => point.index === index);
      const isPointHovered = hoveredPoints.some((point) => point.index === index);
      onMutedChange(!isPointSelected && !isPointHovered);
    } else {
      onMutedChange(false);
    }
  };

  return {
    ...options,
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        redraw: function (event) {
          options.chart?.events?.redraw?.call(this, event);
          onChartRedraw(this as FlareChart);
        },
      },
    },
    plotOptions: {
      ...options.plotOptions,
      series: {
        ...options.plotOptions?.series,
        point: {
          ...options.plotOptions?.series?.point,
          events: {
            ...options.plotOptions?.series?.point?.events,
            select: function (event) {
              options.plotOptions?.series?.point?.events?.select?.call(this, event);
              isPointMuted(this.series.chart as FlareChart);
            },
            unselect: function (event) {
              options.plotOptions?.series?.point?.events?.unselect?.call(this, event);
              isPointMuted(this.series.chart as FlareChart);
            },
            mouseOver: function (event) {
              options.plotOptions?.series?.point?.events?.mouseOver?.call(this, event);
              isPointMuted(this.series.chart as FlareChart);
            },
            mouseOut: function (event) {
              options.plotOptions?.series?.point?.events?.mouseOut?.call(this, event);
              // Need a timeout because `chart.hoverPoints` is updated after the mouseOut event
              setTimeout(() => {
                isPointMuted(this.series.chart as FlareChart);
              }, 0);
            },
          },
        },
      },
    },
  };
};

const FlareBenchmarkLine = ({ index }: Props) => {
  const { id, registerChild, parentProps } = useFlareContext();
  const { isLoading } = parentProps;
  const [container, setContainer] = useState<Element | null>(null);
  const [bounds, setBounds] = useState<{
    top: number;
    left: number;
    width: number;
  }>();
  const [color, setColor] = useState<string>();
  const [isMuted, setIsMuted] = useState(false);

  const handleRender = useCallback(
    (chart: FlareChart) => {
      if (chart && chart.series && chart.series.length > 0) {
        const { points } = chart.series[0];
        const point = points[index];
        const lastPoint = points[points.length - 1];

        const top = point.plotY;
        const left = point.plotX;
        const right = lastPoint.plotX;
        const pointWidth = point.shapeArgs?.width as number;

        if (top && left && right) {
          setContainer(chart.container?.parentNode as Element);
          setBounds({
            top: top + chart.plotTop - 1.5,
            left: left + chart.plotLeft - pointWidth / 2 - 1,
            width: right - left + pointWidth + 2,
          });
          setColor(point.color as string);
        }
      }
    },
    [index],
  );

  useEffect(() => {
    registerChild(id, (options: Options) =>
      buildBenchmarkLineOptions(options, index, handleRender, setIsMuted),
    );
  }, [handleRender, setIsMuted, index]);

  if (!container || !container.parentNode || isLoading || !bounds) {
    return null;
  }

  return createPortal(
    <div className={classNames(styles.container, { [styles.muted]: isMuted })} style={bounds}>
      <svg width="100%" fill="none">
        <path
          d={`M2,2 L${bounds.width},2`}
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="3 3"
        />
      </svg>
    </div>,
    container,
  );
};

export default FlareBenchmarkLine;
