import { usePipelineWeeklyTrends } from '@/api/pipeline';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import PipelineTrendsChart from '@/features/charts/PipelineTrendsChart';

const PipelineTrendsWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { start_date, end_date } = pageFilters;
  const { pipelineWeeklyTrends, isLoading, error } = usePipelineWeeklyTrends(start_date, end_date);

  return <PipelineTrendsChart isLoading={isLoading} data={pipelineWeeklyTrends} error={error} />;
};

export default PipelineTrendsWidget;
