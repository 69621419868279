import { MessageDescriptor, i18n } from '@lingui/core';

import { SolErrorDetails } from '@/api/solType';

import { AUDIENCE_ERROR_MESSAGES_BY_CODE } from './audience-standard-errors';
import { CORE_ERROR_MESSAGES_BY_CODE } from './core-standard-errors';
import { INSTANCE_ERROR_MESSAGES_BY_CODE } from './instance-standard-errors';
import { INTEGRATION_ERROR_MESSAGES_BY_CODE } from './integration-standard-errors';
import { PAGINATION_ERROR_MESSAGES_BY_CODE } from './pagination-standard-errors';
import { PIXEL_ERROR_MESSAGES_BY_CODE } from './pixel-standard-errors';
import { USER_ERROR_MESSAGES_BY_CODE } from './user-standard-errors';

const errorMessages: Record<string, MessageDescriptor> = {
  ...CORE_ERROR_MESSAGES_BY_CODE,
  ...AUDIENCE_ERROR_MESSAGES_BY_CODE,
  ...INSTANCE_ERROR_MESSAGES_BY_CODE,
  ...INTEGRATION_ERROR_MESSAGES_BY_CODE,
  ...PAGINATION_ERROR_MESSAGES_BY_CODE,
  ...PIXEL_ERROR_MESSAGES_BY_CODE,
  ...USER_ERROR_MESSAGES_BY_CODE,
};

export const getMessageByCode = (code: string, details?: SolErrorDetails): string | null => {
  const messageDescriptor = errorMessages[code];

  if (!messageDescriptor) {
    return null;
  }

  if (details) {
    return i18n._(errorMessages[code].id, details);
  }
  return i18n._(errorMessages[code]);
};
