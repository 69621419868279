/* eslint-disable no-restricted-imports */
import { Tag as AntdTag, TagProps as AntdTagProps } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import Tooltip from '@/components/Tooltip';
import { isLowLuminance } from '@/helper/colorHelper';

import styles from './Tag.module.scss';

export type TagProps = Omit<AntdTagProps, 'color'> & {
  size?: 'small' | 'medium' | 'large';
  color?: 'success' | 'error' | 'info' | 'default' | string;
  variant?: 'outlined' | 'solid';
  noMargin?: boolean;
  isDisabled?: boolean;
};

const getTextColor = (color: string) => {
  // We are putting opacity on it, so it's likely to be dark text on a light background
  if (color.length > 7) {
    return 'dark';
  }

  return isLowLuminance(color) ? 'light' : 'dark';
};

const Tag = ({
  children,
  className = '',
  size = 'medium',
  color = 'default',
  variant = 'outlined',
  noMargin = false,
  closable = false,
  isDisabled = false,
  ...props
}: PropsWithChildren<TagProps>) => {
  const isCustomColor =
    color !== 'success' && color !== 'error' && color !== 'info' && color !== 'default';
  const colorThemeClassName = isCustomColor ? 'default' : color;
  const textColor = isCustomColor && getTextColor(color);

  return (
    <Tooltip body={children}>
      <AntdTag
        {...props}
        closable={!isDisabled && closable}
        className={classNames(
          styles.tag,
          className,
          styles[`size-${size}`],
          styles[`variant-${variant}`],
          styles[`text-color-${textColor}`],
          {
            [styles[`color-${colorThemeClassName}`]]: color != null,
            [styles.closable]: closable,
            [styles.noMargin]: noMargin,
            [styles.disabled]: isDisabled,
          },
        )}
        style={{
          backgroundColor: isCustomColor ? color : undefined,
          borderColor: isCustomColor && variant === 'outlined' ? color : undefined,
        }}
      >
        <span className={styles.content}>{children}</span>
      </AntdTag>
    </Tooltip>
  );
};

export default Tag;
