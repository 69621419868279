import { Tooltip as AntdTooltip } from 'antd';
import { TooltipPlacement, TooltipRef } from 'antd/es/tooltip';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode, forwardRef } from 'react';

import { Text } from '@/components/typography';

import styles from './Tooltip.module.scss';

const mouseEnterDelayMap = {
  slow: 1.75,
  normal: 0.5,
  fast: 0.1,
};

type Props = {
  title?: ReactNode;
  body: ReactNode;
  open?: boolean;
  placement?: TooltipPlacement;
  xOffset?: number;
  yOffset?: number;
  pointerEvents?: boolean;
  mouseEnterDelay?: 'slow' | 'normal' | 'fast';
  maxWidth?: number;
  onOpenChange?: (open: boolean) => void;
};

const Tooltip = forwardRef<TooltipRef, PropsWithChildren<Props>>(
  (
    {
      title,
      body,
      open,
      placement = 'bottom',
      xOffset,
      yOffset,
      pointerEvents = true,
      mouseEnterDelay = 'normal',
      maxWidth,
      children,
      onOpenChange,
    },
    ref,
  ) => {
    const offset = xOffset != null || yOffset != null ? [xOffset || 0, yOffset || 0] : undefined;
    const isBodyOnly = !title;

    return (
      <AntdTooltip
        ref={ref}
        overlayClassName={classNames(styles.overlay, {
          [styles.noPointerEvents]: pointerEvents === false,
          [styles.bodyOnly]: isBodyOnly,
        })}
        overlayStyle={{ maxWidth }}
        open={open}
        placement={placement}
        align={offset && { offset }}
        showArrow={false}
        mouseEnterDelay={mouseEnterDelayMap[mouseEnterDelay]}
        title={
          <div className={styles.tooltip}>
            {title && (
              <Text variant="caption1" color="light-green">
                {title}
              </Text>
            )}
            <Text variant="body2" color="white">
              {body}
            </Text>
          </div>
        }
        onOpenChange={onOpenChange}
      >
        {children}
      </AntdTooltip>
    );
  },
);

export default Tooltip;
