import { MessageDescriptor, i18n } from '@lingui/core';
import { msg } from '@lingui/macro';

import { REVENUE_RANGE_ID } from '@/api/common';

const revenueRangeNameMessages: Record<REVENUE_RANGE_ID, MessageDescriptor> = {
  [REVENUE_RANGE_ID.VERY_SMALL]: msg`Very Small`,
  [REVENUE_RANGE_ID.SMALL_MEDIUM]: msg`Small`,
  [REVENUE_RANGE_ID.MID_MARKET]: msg`Mid-Market`,
  [REVENUE_RANGE_ID.ENTERPRISE]: msg`Enterprise`,
};

export const getRevenueRangeName = (revenueRangeId: REVENUE_RANGE_ID) =>
  i18n._(revenueRangeNameMessages[revenueRangeId]);

const revenueRangeDescriptionMessages: Record<REVENUE_RANGE_ID, MessageDescriptor> = {
  [REVENUE_RANGE_ID.VERY_SMALL]: msg`<$10M`,
  [REVENUE_RANGE_ID.SMALL_MEDIUM]: msg`$10M-$100M`,
  [REVENUE_RANGE_ID.MID_MARKET]: msg`$100M-$1B`,
  [REVENUE_RANGE_ID.ENTERPRISE]: msg`$1B+`,
};

export const getRevenueRangeDescription = (revenueRangeId?: REVENUE_RANGE_ID) => {
  if (!revenueRangeId) {
    return '';
  }

  return i18n._(revenueRangeDescriptionMessages[revenueRangeId]);
};
