import classNames from 'classnames';
import { useContext } from 'react';

import DragHandleIcon from '@/assets/svg/bx-align-justify.svg?react';
import { Flex } from '@/components/Flex';

import styles from './DraggableHandle.module.scss';
import { SortableItemContext } from './SortableListItem';

type Props = {
  isActive: boolean;
};

const DraggableHandle = ({ isActive }: Props) => {
  const { attributes, listeners, ref } = useContext(SortableItemContext);

  return (
    <Flex align="center" {...attributes} {...listeners} ref={ref}>
      <DragHandleIcon className={classNames(styles.dragHandle, { [styles.active]: isActive })} />
    </Flex>
  );
};

export default DraggableHandle;
