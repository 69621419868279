import { NavigableRouteType } from '@/router/types';

export const channels = {
  path: '/app/channels',
  description:
    'Displays metrics for the various marketing channels the company is using to reach their customers. Each channel has information displayed such as total visits and average spend per visit during a date range. The channels can be broken down by the audiences that are targeted through those channels.',
  navigable: true,
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the channels by',
      object: 'audience',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const channelDetail = {
  path: '/app/channels/:channel',
  exampleQuestions: [
    'Show me details for Channel A',
    'Where can I see details on Channel B with audience Audience C applied?',
  ],
  description:
    'Displays metrics for an individual marketing channel. Each channel is broken down by vendor and shows metrics such as total visits and average spend per visit during a date range. The channel can be broken down by the audiences that are targeted through that channel.',
  navigable: true,
  pathParams: {
    audience: {
      type: 'string',
      description: 'The ID of the channel the user wishes to view',
      object: 'channel',
      required: true,
    },
  },
  searchParams: {
    audience: {
      type: 'string',
      description: 'An audience ID to filter the channels by',
      object: 'audience',
    },
  },
} satisfies NavigableRouteType<'audience'>;

export const channelById = (channelId?: string) =>
  channelId && `${channels.path}/${encodeURIComponent(channelId)}`;
