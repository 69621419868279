import LetteredAvatar from '@/components/LetteredAvatar';

import styles from './ProfileAvatar.module.scss';

type Props = {
  name?: string;
  pictureUrl?: string;
};

const ProfileAvatar = ({ name, pictureUrl }: Props) => {
  if (pictureUrl) {
    return (
      <object className={styles.picture} data={pictureUrl} type="image/jpeg">
        <LetteredAvatar name={name} size="medium" shape="circle" />
      </object>
    );
  }
  if (name) {
    return <LetteredAvatar name={name} size="medium" shape="circle" />;
  }
  return <div className={styles.avatarImage} />;
};

export default ProfileAvatar;
