import { Trans, t } from '@lingui/macro';

import Form, { TextInput } from '@/components/Form';
import { Text } from '@/components/typography';
import { maxEmailLength, maxUserNameLength } from '@/constants/numbers';
import { EMAIL_STANDARD_RULES } from '@/helper/ant-rule';

import styles from './UserDetailInfoFields.module.scss';
import { USER_FORM_FIELD } from './UserFormType';

type Props = {
  isEditing: boolean;
  name: string;
  email?: string;
  isEmailEditable?: boolean;
};

const UserDetailInfoFields = ({ isEditing, name, email, isEmailEditable }: Props) => {
  return (
    <div className={styles.container}>
      <Form.Item
        label={<Trans>Name</Trans>}
        name={USER_FORM_FIELD.NAME}
        initialValue={name}
        rules={[{ required: true, message: <Trans>Name is required</Trans> }]}
        required={false}
      >
        {isEditing ? (
          <TextInput
            autoComplete="name"
            spellCheck="false"
            autoCapitalize="words"
            autoFocus
            maxLength={maxUserNameLength}
          />
        ) : (
          <Text variant="body1">{name}</Text>
        )}
      </Form.Item>
      {email != null && (
        <Form.Item
          label={<Trans>Email</Trans>}
          name={USER_FORM_FIELD.EMAIL}
          initialValue={email}
          rules={EMAIL_STANDARD_RULES}
          required={false}
        >
          {isEditing && isEmailEditable ? (
            <TextInput
              inputMode="email"
              placeholder={t`name@work-email.com`}
              autoComplete="email"
              spellCheck="false"
              autoCapitalize="none"
              maxLength={maxEmailLength}
            />
          ) : (
            <Text variant="body1">{email}</Text>
          )}
        </Form.Item>
      )}
    </div>
  );
};

export default UserDetailInfoFields;
