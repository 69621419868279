import { Organization } from '@stytch/vanilla-js';
import { useEffect, useRef, useState } from 'react';

import { getOrganizationMetadataBySlug } from '@/api/organization';

type Props = {
  slug?: string;
};

/**
 * Fetches metadata from an organization by org slug. This is an unauthenticated call and supplies
 * limited data about the org. If the user is authenticated, don't use this.
 */
const useOrganizationMetadataBySlug = ({ slug }: Props) => {
  const isFetchingOrgMetadata = useRef(false);
  const [data, setData] = useState<Organization>();
  const [error, setError] = useState<Error>();

  useEffect(() => {
    if (slug && !isFetchingOrgMetadata.current && !data) {
      isFetchingOrgMetadata.current = true;
      getOrganizationMetadataBySlug(slug)
        .then((org) => {
          if (org) {
            setData({
              ...org,
              organization_slug: slug,
            });
          } else {
            throw new Error('org not found');
          }
        })
        .catch((e) => {
          setError(e);
        })
        .finally(() => {
          isFetchingOrgMetadata.current = false;
        });
    }
  }, [slug, isFetchingOrgMetadata.current, data]);

  return {
    data,
    isLoading: isFetchingOrgMetadata.current,
    error,
  };
};

export default useOrganizationMetadataBySlug;
