import { Navigate, Outlet } from 'react-router-dom';

import { UserProfileType } from '@/api/user';
import { getForeignSuperuserInstance } from '@/helper/common';
import { useMe } from '@/providers/User';

type Role = 'admin' | 'c99Superuser';

type Props = {
  role: Role;
};

const isAuthorized = (user: UserProfileType | undefined, role: Role) => {
  const foreignInstanceId = getForeignSuperuserInstance();
  const isSuperuserInForeignOrg = !!foreignInstanceId && user?.isC99Superuser;

  if (user) {
    if (isSuperuserInForeignOrg) {
      return true;
    }
    if (role === 'admin' && user.isAdmin) {
      return true;
    }
    if (role === 'c99Superuser' && user.isC99Superuser) {
      return true;
    }
  }

  return false;
};

const UserRoleRoute = ({ role }: Props) => {
  const user = useMe();

  return isAuthorized(user, role) ? <Outlet /> : <Navigate to="/" replace />;
};

export default UserRoleRoute;
