import { Trans } from '@lingui/macro';
import { ReactNode } from 'react';

import { Button } from '@/components/buttons';
import { Text, Title } from '@/components/typography';
import { ROUTES } from '@/router';

import styles from './IntegrationCard.module.scss';
import IntegrationStatus from './IntegrationStatus';

type Props = {
  id: string;
  name: string;
  description: string | null;
  icon: ReactNode;
  status: 'active' | 'notConnected' | 'broken';
};

const IntegrationCard = ({ id, name, description, icon, status }: Props) => {
  return (
    <div className={styles.card}>
      <div className={styles.header}>
        <div className={styles.logo}>{icon}</div>
        <IntegrationStatus status={status} />
      </div>
      <div className={styles.content}>
        <div className={styles.title}>
          <Title level={3} weight="semi-bold">
            {name}
          </Title>
          <Text variant="body2">{description}</Text>
        </div>
        <Button color="black" variant="secondary" to={ROUTES.integrationById(id)}>
          {status === 'notConnected' ? <Trans>Connect</Trans> : <Trans>Details</Trans>}
        </Button>
      </div>
    </div>
  );
};

export default IntegrationCard;
