import { Trans, t } from '@lingui/macro';

import { RegionType } from '@/api/common';
import { useRegions } from '@/api/settings';
import { MultiSelect, MultiSelectProps } from '@/components/Form';

const RegionMultiSelect = (props: MultiSelectProps<RegionType>) => {
  const { regions, isLoading } = useRegions();

  return (
    <MultiSelect
      placeholder={t`Select Regions...`}
      notFoundContent={<Trans>No regions were found...</Trans>}
      {...props}
      options={regions}
      isLoading={isLoading}
    />
  );
};

export default RegionMultiSelect;
