import { VendorType } from '@/api/vendor';
import { getOpacityInHex, stringToColor } from '@/helper/colorHelper';
import { stringInitials } from '@/helper/string-util';

export const getVendorColor = (vendor: VendorType, opacity?: number) => {
  const color = vendor.color ?? stringToColor(stringInitials(vendor.name));

  if (opacity) {
    return `${color}${getOpacityInHex(opacity)}`;
  }

  return color;
};
