import gql from 'graphql-tag';
import { useMemo } from 'react';
import useSWR from 'swr';

import { getIsRecognizedProvider } from '@/app/integrations/providerUtil';
import SolGraphQLError from '@/error/SolGraphQLError';

import { fetcherSol } from '../swr-fetcher';
import {
  INTEGRATION_PROVIDER_ID,
  IntegrationConnectionCreateGQLResponse,
  IntegrationConnectionCreateVariablesType,
  IntegrationConnectionDeleteGQLResponse,
  IntegrationConnectionDeleteVariablesType,
  IntegrationProviderListGQLResponse,
  IntegrationTokenCreateGQLResponse,
  IntegrationTokenCreateVariablesType,
} from './integrationType';

export const useIntegrationProviders = () => {
  const { data, isLoading, error, mutate } = useSWR<
    IntegrationProviderListGQLResponse,
    SolGraphQLError
  >(
    {
      query: gql`
        query oAuthProviders(
          $page: Pagination!
          $sort: [SortParamInput]!
          $filter: [FilterParamInput]!
          $connPage: Pagination!
          $connSort: [SortParamInput]!
          $connFilter: [FilterParamInput]!
        ) {
          oAuthProviders {
            get(page: $page, sort: $sort, filter: $filter) {
              edges {
                node {
                  id
                  name
                  startUrl
                  type
                  multiConnection
                  syncIntervalHours
                  connections(page: $connPage, sort: $connSort, filter: $connFilter) {
                    edges {
                      node {
                        id
                        createdBy {
                          email
                          name
                        }
                        lastJob {
                          created_at
                          status
                          errors
                        }
                        organizationId
                        organizationName
                        status
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        page: {
          limit: 20,
          offset: 0,
        },
        sort: [
          {
            direction: 'asc',
            field: 'name',
          },
        ],
        filter: [],
        connPage: {
          limit: 100,
          offset: 0,
        },
        connSort: [],
        connFilter: [],
      },
    },
    fetcherSol,
    {},
  );

  const providers = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return data?.oAuthProviders.get.edges
      .map((d) => ({
        ...d.node,
        connections: d.node.connections.edges.map((conn) => conn.node),
      }))
      .filter((provider) => {
        if (!getIsRecognizedProvider(provider.id)) {
          return false;
        }

        if (provider.id === INTEGRATION_PROVIDER_ID.SLACK_AI) {
          return false;
        }
        return true;
      });
  }, [data]);

  return {
    providers,
    isLoading,
    error,
    mutate,
  };
};

export const useIntegrationProviderDetails = (providerId: INTEGRATION_PROVIDER_ID) => {
  const { providers, isLoading, error, mutate } = useIntegrationProviders();
  const provider = providers?.find((d) => d.id === providerId);

  let is404 = false;
  if (providers && !provider) {
    is404 = true;
  }

  return {
    provider,
    is404,
    isLoading,
    error,
    mutate,
  };
};

export const mutateIntegrationTokenCreate = async (
  oAuthProviderId: string,
  params: URLSearchParams,
) => {
  const response = await fetcherSol<
    IntegrationTokenCreateVariablesType,
    IntegrationTokenCreateGQLResponse
  >({
    query: gql`
      mutation CreateOAuthToken($oAuthProviderId: String!, $params: [OAuthQueryParamInput]) {
        oAuthTokens {
          create(params: $params, oAuthProviderId: $oAuthProviderId) {
            id
            orgs {
              id
              name
            }
            connection {
              id
            }
          }
        }
      }
    `,
    variables: {
      oAuthProviderId,
      params: [...params.entries()].map(([name, value]) => ({ name, value })),
    },
  });

  return response.oAuthTokens.create;
};

export const mutateIntegrationConnectionCreate = async (oAuthTokenId: string, orgId: string) => {
  const response = await fetcherSol<
    IntegrationConnectionCreateVariablesType,
    IntegrationConnectionCreateGQLResponse
  >({
    query: gql`
      mutation CreateOAuthConnection($oAuthTokenId: String!, $orgId: String!) {
        oAuthConnections {
          create(oAuthTokenId: $oAuthTokenId, organizationId: $orgId) {
            id
          }
        }
      }
    `,
    variables: {
      oAuthTokenId,
      orgId,
    },
  });

  return response.oAuthConnections.create.id;
};

export const mutateIntegrationConnectionDelete = async (oAuthConnectionId: string) => {
  const response = await fetcherSol<
    IntegrationConnectionDeleteVariablesType,
    IntegrationConnectionDeleteGQLResponse
  >({
    query: gql`
      mutation DeleteOAuthConnection($id: String!) {
        oAuthConnections {
          delete(id: $id) {
            success
            id
          }
        }
      }
    `,
    variables: {
      id: oAuthConnectionId,
    },
  });

  return response.oAuthConnections.delete.success;
};
