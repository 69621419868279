import classNames from 'classnames';
import { Axis, Options } from 'highcharts';
import { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

import { useFlareContext } from '@/components/Flare/FlareContext';
import { FlareChart } from '@/components/Flare/types';

import styles from './Marker.module.scss';

export type MarkerDataType = {
  id: string;
  label: string;
  align: 'center' | 'right';
  date: string;
};

type Props = {
  data: MarkerDataType[];
  renderLabel?: (data: MarkerDataType) => React.ReactNode;
};

const buildMarkerOptions = (
  options: Options,
  onChartRedraw: (chart: FlareChart) => void,
): Options => {
  return {
    ...options,
    chart: {
      ...options.chart,
      events: {
        ...options.chart?.events,
        redraw: function (event) {
          options.chart?.events?.redraw?.call(this, event);
          onChartRedraw(this as FlareChart);
        },
      },
    },
  };
};

const Marker = ({ data, renderLabel = (marker) => marker.label }: Props) => {
  const { id, registerChild, parentProps } = useFlareContext();
  const [xAxis, setXAxis] = useState<Axis | undefined>();
  const [plotBounds, setPlotBounds] = useState<{
    plotTop: number;
    plotLeft: number;
    plotHeight: number;
    plotWidth: number;
  }>();
  const [container, setContainer] = useState<Element | null>(null);
  const { parseX, data: parentData, isLoading } = parentProps;

  const handleRender = useCallback((chart: FlareChart) => {
    if (chart) {
      setXAxis(chart.xAxis[0]);
      setPlotBounds({
        plotTop: chart.plotTop,
        plotLeft: chart.plotLeft,
        plotHeight: chart.plotHeight,
        plotWidth: chart.plotWidth,
      });
      setContainer(chart.container?.parentNode as Element);
    }
  }, []);

  useEffect(() => {
    registerChild(id, (options: Options) => buildMarkerOptions(options, handleRender));
  }, [handleRender]);

  if (!container || !container.parentNode || isLoading || !data || !parentData || !plotBounds) {
    return null;
  }

  const { plotTop, plotLeft, plotHeight } = plotBounds;

  return createPortal(
    <div className={styles.markerContainer}>
      {data?.map((marker) => {
        const xPos = xAxis?.toPixels(parseX ? parseX(marker.date) : marker.date, true) ?? NaN;
        const left = Math.round(xPos + plotLeft);

        if (isNaN(left)) {
          return null;
        }

        return (
          <div
            className={styles.markerWrapper}
            style={{
              position: 'absolute',
              left,
              top: plotTop - 5,
              height: plotHeight + 5,
            }}
            key={marker.id}
          >
            <div className={styles.crosshair}>
              <svg xmlns="http://www.w3.org/2000/svg" height="100%" width="2px">
                <line className={styles.crosshairLine} x1="0" y1="0" x2="0" y2="100%" />
              </svg>
            </div>
            <div className={classNames(styles.marker, styles[`align-${marker.align}`])}>
              {renderLabel(marker)}
            </div>
          </div>
        );
      })}
    </div>,
    container,
  );
};

export default Marker;
