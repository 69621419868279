import {
  COLOR_TEAL_300,
  COLOR_TEAL_400,
  COLOR_TEAL_500,
  COLOR_TEAL_600,
  COLOR_TEAL_700,
  COLOR_TEAL_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_TEALS = [
  COLOR_TEAL_300,
  COLOR_TEAL_400,
  COLOR_TEAL_500,
  COLOR_TEAL_600,
  COLOR_TEAL_700,
  COLOR_TEAL_800,
];
