import { Trans, t } from '@lingui/macro';

import { useWebTrafficKPIs } from '@/api/webTraffic';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';

const WebTrafficKPIMetrics = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const { webTrafficKPIs, isLoading, error } = useWebTrafficKPIs(startDate, endDate, audience);

  return (
    <KpiMetricGroup>
      {webTrafficKPIs?.map((kpi) => {
        const captionVal =
          kpi.lastPeriod === null ? (
            <Text variant="caption1" color="grey">{t`Partial Data`}</Text>
          ) : (
            numberFormat(kpi.lastPeriod, kpi.valueFormat)
          );

        return (
          <KpiMetric
            key={kpi.id}
            label={kpi.label}
            value={kpi.value && numberFormat(kpi.value, kpi.valueFormat)}
            valueLink={kpi.valueLink}
            caption={<Trans>Previous Period: {captionVal}</Trans>}
            percentChange={kpi.lastPeriod == null ? null : kpi.percentChange}
            isLoading={isLoading}
            error={error}
          />
        );
      })}
    </KpiMetricGroup>
  );
};

export default WebTrafficKPIMetrics;
