import { Trans } from '@lingui/macro';
import { useMemo, useState } from 'react';

import { OptionType } from '@/api/common';
import { Flex } from '@/components/Flex';
import { SortableList } from '@/components/list';
import { ConfirmModal } from '@/components/modals';
import { Title } from '@/components/typography';
import { useTableState } from '@/providers/TableState';

import styles from './MetricSelector.module.scss';

type Props = {
  open?: boolean;
  onClose: () => void;
};

const MetricSelector = <T extends object>({ open = false, onClose }: Props) => {
  const { columns, columnsMap, onSortKeyColumnsChange, onVisibleColumnsChange } =
    useTableState<T>();
  const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>(() =>
    Object.keys(columnsMap),
  );
  const options = useMemo<OptionType[]>(
    () => columns.map((metric) => ({ id: `${metric.key}`, name: metric.title ?? `${metric.key}` })),
    [columns],
  );
  const [sortedOptions, setSortedOptions] = useState<OptionType[] | undefined>(options);

  const handleOk = () => {
    onSortKeyColumnsChange((sortedOptions ?? []).map((option) => option.id));
    onVisibleColumnsChange(selectedKeys ?? []);
    onClose();
  };

  return (
    <ConfirmModal
      open={open}
      textAlign="left"
      okText={<Trans>Update Metrics</Trans>}
      onOk={handleOk}
      onCancel={onClose}
    >
      <Flex gap="large" vertical>
        <Title level={2} weight="light">
          <Trans>Metrics Selection</Trans>
        </Title>
        <div className={styles.listContainer}>
          <SortableList
            selectionMode="multiple"
            selectedKeys={selectedKeys}
            options={sortedOptions}
            renderItem={(item) => (
              <SortableList.Item key={item.id} id={item.id}>
                {item.name}
              </SortableList.Item>
            )}
            onOptionsChange={setSortedOptions}
            onSelectedKeysChange={setSelectedKeys}
          />
        </div>
      </Flex>
    </ConfirmModal>
  );
};

export default MetricSelector;
