import { useState } from 'react';

import { useCampaignOptions } from '@/api/campaign';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { InfiniteScrollCategoryFilter } from '@/components/filters';
import { usePageFilterContext } from '@/components/page';

const PAGE_SIZE = 50;

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const CampaignFilter = (props: Props) => {
  const [search, setSearch] = useState<string>('');
  const {
    pageFilters: { start_date: startDate, end_date: endDate },
  } = usePageFilterContext();
  const { campaignOptions, error, hasMore, isLoading, isLoadingMore, pageIndex, loadMore } =
    useCampaignOptions({ search, pageSize: PAGE_SIZE, startDate, endDate });

  return (
    <InfiniteScrollCategoryFilter
      {...props}
      options={campaignOptions}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      hasMore={hasMore}
      error={error}
      loadMore={() => loadMore(pageIndex + 1)}
      searchText={search}
      onSearch={setSearch}
    />
  );
};

export default CampaignFilter;
