import { PropsWithChildren } from 'react';

import { PageFiltersType } from '@/components/page';

import styles from './DashboardRowWidget.module.scss';
import DashboardWidget from './DashboardWidget';
import NotAvailable from './NotAvailable';
import { DashboardWidgetRowType, DashboardWidgetType } from './types';

type Props = {
  widget: DashboardWidgetRowType;
  pageFilters: PageFiltersType;
  onWidgetExpand: (isExpanded: boolean, widget: DashboardWidgetType) => void;
};

const DashboardRowWidget = ({ widget, pageFilters, onWidgetExpand }: PropsWithChildren<Props>) => {
  const { notAvailable, children } = widget;

  return (
    <div className={styles.container}>
      {children.map((child) => (
        <DashboardWidget
          key={child.id}
          widget={{ ...child, gridPos: { x: 0, y: 0, w: 0, h: 0 } }} // TODO: Fix types to avoid this
          isFullScreen={false}
          canFullScreen={false}
          pageFilters={pageFilters}
          draggableHandleSelector="" // TODO: Implement a better drag and drop solution for rows and content inside them
          draggableIgnoreSelector=""
          onWidgetExpand={onWidgetExpand}
        />
      ))}
      {notAvailable && <NotAvailable {...notAvailable} />}
    </div>
  );
};

export default DashboardRowWidget;
