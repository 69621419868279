import { t } from '@lingui/macro';

import { Flex } from '@/components/Flex';
import {
  Page,
  PageAudienceFilter,
  PageDateRangeFilter,
  PageFilterProvider,
} from '@/components/page';

import ChannelOverviewKPIMetrics from './ChannelOverviewKPIMetrics';
import ChannelsSpendPerVisitChart from './ChannelsSpendPerVisitChart';
import ChannelsTrafficBreakdownChart from './ChannelsTrafficBreakdownChart';

const ChannelOverview = () => {
  return (
    <Page title={t`Channels`} pageName={t`All Channels`}>
      <PageFilterProvider>
        <Flex vertical gap="large" fullWidth>
          <Flex justify="space-between">
            <PageDateRangeFilter />
            <PageAudienceFilter />
          </Flex>
          <ChannelOverviewKPIMetrics />
          <ChannelsTrafficBreakdownChart />
          <ChannelsSpendPerVisitChart />
        </Flex>
      </PageFilterProvider>
    </Page>
  );
};

export default ChannelOverview;
