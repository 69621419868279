import { t } from '@lingui/macro';

import TooltipRow from '@/components/Flare/guides/TooltipRow';
import MultiSeriesTooltip from '@/components/MultiSeriesTooltip';
import Tooltip from '@/components/Tooltip';
import { TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './BenchmarksTooltip.module.scss';

type Props = {
  title: string;
  customerBenchmark?: number;
  industryBenchmark?: number;
  open: boolean;
};

const BenchmarksTooltip = ({
  title,
  customerBenchmark = 0,
  industryBenchmark = 0,
  open,
}: Props) => {
  return (
    <Tooltip
      pointerEvents={false}
      placement="bottom"
      open={open}
      maxWidth={600}
      body={
        <MultiSeriesTooltip title={title}>
          <TooltipRow
            color={TAM_PRIMARY}
            name={t`Your Performance`}
            value={t`${numberFormat(customerBenchmark, {
              isPercent: true,
              precision: 0,
            })} TAM Traffic`}
          />
          <TooltipRow
            color={VISITS_PRIMARY}
            name={t`Industry Average`}
            value={t`${numberFormat(industryBenchmark, {
              isPercent: true,
              precision: 0,
            })} TAM Traffic`}
          />
        </MultiSeriesTooltip>
      }
    >
      <div
        className={styles.invisibleBar}
        style={{
          width: numberFormat(customerBenchmark, { isPercent: true }),
        }}
      />
    </Tooltip>
  );
};

export default BenchmarksTooltip;
