import {
  COLOR_VIOLET_300,
  COLOR_VIOLET_400,
  COLOR_VIOLET_500,
  COLOR_VIOLET_600,
  COLOR_VIOLET_700,
  COLOR_VIOLET_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_VIOLETS = [
  COLOR_VIOLET_300,
  COLOR_VIOLET_400,
  COLOR_VIOLET_500,
  COLOR_VIOLET_600,
  COLOR_VIOLET_700,
  COLOR_VIOLET_800,
];
