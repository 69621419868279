import classNames from 'classnames';
import moment from 'moment';
import { useEffect } from 'react';

import { DateRangePicker, RangeValue } from '@/components/Form';
import { DateBEFormat } from '@/constants/formats';
import { getDefaultDateRange, setDefaultDateRange } from '@/helper/common';

import styles from './PageDateRangeFilter.module.scss';
import { usePageFilterContext } from './PageFilterProvider';
import { PageFiltersType } from './types';

type Props = {
  className?: string;
};

const getLast90Days = () => {
  return {
    start_date: moment().subtract(90, 'd').startOf('d').format(DateBEFormat),
    end_date: moment().endOf('d').format(DateBEFormat),
  };
};

const addDefaultDatesIntoFiltersIfNecessary = (pageFilters: PageFiltersType) => {
  // We may not have a `start_date` and `end_date` in the pageFilters until the filters get
  // pushed down into the component. This is a workaround to ensure that the date range is set to
  // what the filters will eventually contain.
  if (!pageFilters.start_date && !pageFilters.end_date) {
    const defaultRange = getDefaultDateRange() || getLast90Days();
    return {
      ...pageFilters,
      ...defaultRange,
    };
  } else {
    return pageFilters;
  }
};

const DashboardDateRangeFilter = ({ className = '' }: Props) => {
  const { pageFilters, setPageFilters } = usePageFilterContext();

  // Push the default start/end dates into the filters context so other components can use them.
  useEffect(() => {
    const defaultRange = getDefaultDateRange();

    if (defaultRange) {
      setPageFilters(defaultRange);
    } else {
      const newDefaultRange = getLast90Days();
      setDefaultDateRange(newDefaultRange);
      setPageFilters(newDefaultRange);
    }
  }, []);

  const handleDateRangeChange = (dates: RangeValue | null, dateStrings: [string, string]) => {
    const newDateRange = { start_date: dateStrings[0], end_date: dateStrings[1] };
    setDefaultDateRange(newDateRange);
    setPageFilters(newDateRange);
  };

  const filtersWithDateRange = addDefaultDatesIntoFiltersIfNecessary(pageFilters);

  return (
    <div className={classNames(styles.dateRange, className)} data-testid="date-range-picker">
      <DateRangePicker
        size="large"
        value={[moment(filtersWithDateRange.start_date), moment(filtersWithDateRange.end_date)]}
        allowClear={false}
        maxDate={moment().endOf('d')}
        onChange={handleDateRangeChange}
      />
    </div>
  );
};

export default DashboardDateRangeFilter;
