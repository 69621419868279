import classNames from 'classnames';
import once from 'lodash/once';
import { CSSProperties, ReactNode, createContext, useContext, useState } from 'react';

import styles from './Legend.module.scss';

export type LegendContextProps<T> = {
  disabledSeriesValues: T[];
  noToggleVisibility: boolean;
  onToggleSeries: (value: T, selected: boolean) => void;
};

type Props<T> = {
  className?: string;
  style?: CSSProperties;
  children: ReactNode;
  noToggleVisibility?: boolean;
  defaultDisabledValues?: T[];
  onSeriesToggle?: (value: T, selected: boolean) => void;
  onDisabledSeriesChange?: (disabledSeriesValues: T[]) => void;
};

const createLegendContext = once(<T,>() => createContext({} as LegendContextProps<T>));

export const useLegendContext = <T extends string>() => {
  const context = useContext(createLegendContext<T>());
  if (!context) {
    throw new Error('useLegendContext must be used within a LegendProvider');
  }
  return context;
};

const Legend = <T extends string>({
  className = '',
  style = {},
  children,
  noToggleVisibility = false,
  defaultDisabledValues = [],
  onSeriesToggle,
  onDisabledSeriesChange,
  ...rest
}: Props<T>) => {
  const [disabledSeriesValues, setDisabledSeriesValues] = useState<T[]>(defaultDisabledValues);
  const LegendContext = createLegendContext<T>();

  const handleDisabledSeriesChange = (disabledSeriesValues: T[]) => {
    setDisabledSeriesValues(disabledSeriesValues);
    onDisabledSeriesChange?.(disabledSeriesValues);
  };

  const handleSeriesToggle = (value: T, selected: boolean) => {
    onSeriesToggle?.(value, selected);
    if (selected) {
      handleDisabledSeriesChange(disabledSeriesValues.filter((v) => v !== value));
    } else {
      handleDisabledSeriesChange([...disabledSeriesValues, value]);
    }
  };

  return (
    <div className={classNames(styles.legend, className)} style={style} {...rest}>
      <LegendContext.Provider
        value={{ disabledSeriesValues, noToggleVisibility, onToggleSeries: handleSeriesToggle }}
      >
        {children}
      </LegendContext.Provider>
    </div>
  );
};

export default Legend;
