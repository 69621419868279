import classNames from 'classnames';
import { Children, PropsWithChildren, cloneElement, forwardRef, isValidElement } from 'react';

import ChevronLeft from '@/assets/svg/chevron-left.svg?react';

import styles from './PanelDrawer.module.scss';

type Props = {
  isOpen: boolean;
  onOpenChange?: (isOpen: boolean) => void;
};

const PanelDrawer = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
  ({ children, isOpen, onOpenChange }, ref) => {
    const handleDrawerToggle = () => {
      const newIsOpen = !isOpen;
      onOpenChange?.(newIsOpen);
    };

    const wrappedChildren = Children.map(children, (child) => {
      if (isValidElement(child)) {
        return cloneElement(child, { ...child.props, isOpen });
      }
    });

    return (
      <div
        ref={ref}
        data-testid="panel-drawer"
        className={classNames(styles.drawer, { [styles.isOpen]: isOpen })}
      >
        <div className={styles.content}>{wrappedChildren}</div>
        <div
          role="button"
          data-testid="panel-drawer-toggle"
          className={styles.toggleButton}
          onClick={handleDrawerToggle}
        >
          <ChevronLeft className={styles.toggleButtonIcon} />
        </div>
      </div>
    );
  },
);

export default PanelDrawer;
