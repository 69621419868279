import { Trans, t } from '@lingui/macro';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';

import { VendorActivityDataType, useVendorActivity } from '@/api/vendor';
import Remove from '@/assets/svg/remove.svg?react';
import {
  InstantSearchSortOrder,
  SolQueryParamsNew,
  useInstantSearchState,
} from '@/components/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { Legend, LegendItem } from '@/components/Legend';
import Table, { ColumnsType } from '@/components/Table';
import { KpiMetric } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link, Text } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { COLOR_GREEN_100, COLOR_RED_300 } from '@/styles/palette';

import styles from './VendorFitScoringTable.module.scss';
import VendorFitScoringTableActions from './VendorFitScoringTableActions';

const TITLE_WIDTH = 160;

const getColor = ({ best, worst }: { best: boolean; worst: boolean }) => {
  if (best) {
    return COLOR_GREEN_100;
  }
  if (worst) {
    return COLOR_RED_300;
  }
  return undefined;
};

const VendorFitScoringTable = () => {
  const { pageFilters } = usePageFilterContext();
  const { queryState } = useInstantSearchState();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const solQueryParams = getSolQueryParamsNewFromQueryState(queryState);

  const fitScoreQueryParams = useMemo<SolQueryParamsNew | undefined>(() => {
    if (!solQueryParams) {
      return undefined;
    }

    return {
      ...solQueryParams,
      sort: { field: 'fitScore', direction: InstantSearchSortOrder.DESC },
      filter: [
        ...solQueryParams.filter,
        {
          field: 'visits',
          operator: 'gt',
          operand: '0',
        },
        {
          field: 'spend',
          operator: 'gt',
          operand: '0',
        },
      ],
    };
  }, [solQueryParams]);
  const {
    vendorActivity: vendorFitScores,
    isLoading,
    error,
  } = useVendorActivity(fitScoreQueryParams, startDate, endDate, audience?.id);
  const [removedVendors, setRemovedVendors] = useState<VendorActivityDataType[]>([]);

  const handleRemoveVendor = useCallback(
    (vendorToRemove: VendorActivityDataType) => {
      setRemovedVendors([...removedVendors, vendorToRemove]);
    },
    [removedVendors, setRemovedVendors],
  );

  const handleAddVendor = useCallback(
    (vendorToAdd: VendorActivityDataType) => {
      setRemovedVendors(removedVendors.filter((vendor) => vendor !== vendorToAdd));
    },
    [removedVendors, setRemovedVendors],
  );

  const visibleVendorFitScores = useMemo(() => {
    return vendorFitScores?.filter((vendor) => !removedVendors.includes(vendor));
  }, [removedVendors, vendorFitScores]);

  const columns: ColumnsType<VendorActivityDataType> = useMemo(
    () => [
      {
        title: t`Vendor`,
        key: 'vendor.id',
        fixed: 'left',
        width: TITLE_WIDTH,
        render: (text, field) => (
          <div className={styles.titleContainer}>
            <div
              className={classNames(styles.removeTab)}
              onClick={() => handleRemoveVendor(field)}
              role="button"
            >
              <Remove className={styles.icon} width={20} height={20} />
            </div>
            <Link variant="caption1" weight="bold" to={ROUTES.vendorById(field.vendor.id)}>
              {field.vendor.name}
            </Link>
            <Text variant="caption1">{field.vendor.channel?.name}</Text>
          </div>
        ),
      },
      {
        title: t`Visits`,
        key: 'allVisits',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.visitsBest, worst: field.visitsWorst })}
            label={<Trans>Visits</Trans>}
            value={numberFormat(field.allVisits)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Engaged Companies`,
        key: 'engagedCompanies',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.engagedCompaniesBest,
              worst: field.engagedCompaniesWorst,
            })}
            label={<Trans>Engaged Companies</Trans>}
            value={numberFormat(field.percentOfEngagedCompanies, { isPercent: true, precision: 1 })}
            caption={numberFormat(field.engagedCompanies)}
            captionLink={ROUTES.webTrafficActivityWithParams({
              audienceIds: audience?.id,
              vendorIds: field.vendor.id,
              'metrics.visits': '[1,]',
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Visit`,
        key: 'spendPerVisit',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.spendPerVisitBest, worst: field.spendPerVisitWorst })}
            label={<Trans>$/Visit</Trans>}
            value={numberFormat(field.spendPerVisit, { isCurrency: true, precision: 2 })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`$/Pipeline Influenced`,
        key: 'pipelineInfluencedPerSpend',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.pipelineInfluencedBest,
              worst: field.pipelineInfluencedWorst,
            })}
            label={<Trans>$/Pipeline Influenced</Trans>}
            value={numberFormat(field.pipelineInfluencedPerSpend, {
              isMultiplier: true,
              precision: 1,
            })}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`% Opps Influenced`,
        key: 'percentOpportunitiesInfluenced',
        render: (text, field) => (
          <KpiMetric
            color={getColor({
              best: field.opportunityInfluencedBest,
              worst: field.opportunityInfluencedWorst,
            })}
            label={<Trans>% Opps Influenced</Trans>}
            value={numberFormat(field.percentOpportunitiesInfluenced, {
              isPercent: true,
              precision: 0,
            })}
            caption={numberFormat(field.opportunitiesInfluenced)}
            error={error}
            isLoading={false}
          />
        ),
      },
      {
        title: t`Fit Score`,
        key: 'fitScore',
        fixed: 'right',
        sorter: true,
        sortOrder: 'descend',
        render: (text, field) => (
          <KpiMetric
            color={getColor({ best: field.fitScoreBest, worst: field.fitScoreWorst })}
            label={<Trans>Fit Score</Trans>}
            value={numberFormat(field.fitScore, { precision: 1 })}
            error={error}
            isLoading={false}
          />
        ),
      },
    ],
    [audience, handleRemoveVendor],
  );

  const countBaseAccounts = vendorFitScores?.length && vendorFitScores[0].baseAccounts;

  return (
    <Panel
      title={
        <Trans>
          Fit Score - {audience ? audience.name : t`TAM`}
          {countBaseAccounts != null ? ' ' + t`(${numberFormat(countBaseAccounts)} Companies)` : ''}
        </Trans>
      }
      infoPopover={{
        title: t`Fit Score`,
        body: (
          <Trans>
            The Vendor Fit Score is an analysis of 5 major metrics used to calculate vendor
            performance from 1-100.{' '}
            <Link
              variant="body2"
              color="static-white"
              to="https://support.channel99.com/hc/en-us/articles/21697895853083-C99-Platform-Definitions#h_01HXYZVHZYTEZ1HTMG79PCB1DJ"
              target="_blank"
            >
              Learn More
            </Link>
          </Trans>
        ),
      }}
      noPadding
    >
      <Legend noToggleVisibility>
        <LegendItem value="highest" color={COLOR_GREEN_100} name={t`Highest Performer`} />
        <LegendItem value="lowest" color={COLOR_RED_300} name={t`Lowest Performer`} />
      </Legend>
      <div className={styles.tableWrapper}>
        <div className={styles.tableContainer}>
          <Table
            className={styles.table}
            columns={columns}
            dataSource={visibleVendorFitScores}
            emptyMessage={t`Please add vendor spend data to see fit scores.`}
            loading={isLoading}
            error={error}
            showHeader={false}
            pagination={false}
            rowKey={(record) => `${record.vendor.channel?.id}-${record.vendor.id}`}
            scroll={{ x: 1200 }}
            actionContent={
              removedVendors.length > 0 && (
                <VendorFitScoringTableActions
                  titleWidth={TITLE_WIDTH}
                  removedVendors={removedVendors}
                  onAddVendor={handleAddVendor}
                />
              )
            }
          />
        </div>
      </div>
    </Panel>
  );
};

export default VendorFitScoringTable;
