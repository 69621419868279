import { Trans } from '@lingui/macro';
import { Row, Space } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { ROUTES } from '@/router';

type Props = {
  isSaving: boolean;
};

const UserInviteHeader = ({ isSaving }: Props) => {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(ROUTES.users.path);
  };

  return (
    <Row align="middle" justify="space-between">
      <ReturnToLink route={ROUTES.users.path} routeName={<Trans>Users & Roles</Trans>} />
      <Space size={16}>
        <Button color="black" variant="secondary" onClick={handleCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button color="green" variant="primary" type="submit" isLoading={isSaving}>
          <Trans>Invite User</Trans>
        </Button>
      </Space>
    </Row>
  );
};

export default UserInviteHeader;
