import CheckableTag from 'antd/es/tag/CheckableTag';
import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';

import { Flex } from '@/components/Flex';
import Tooltip from '@/components/Tooltip';
import { Text } from '@/components/typography';

import { useLegendContext } from './Legend';
import styles from './LegendItem.module.scss';

type Props = {
  className?: string;
  value: string;
  name?: string;
  color?: string | null;
  isDisabled?: boolean;
  onChange?: (checked: boolean) => void;
};

const LegendItem = ({ className = '', value, color, name, onChange, ...rest }: Props) => {
  const { disabledSeriesValues, noToggleVisibility, onToggleSeries } = useLegendContext();
  const interactive = !noToggleVisibility;
  const selected = !disabledSeriesValues.includes(value);

  const handleChange = (isChecked: boolean) => {
    onChange?.(isChecked);
    onToggleSeries?.(value, isChecked);
  };

  const children = useMemo<ReactNode>(() => {
    return (
      <Tooltip body={name} mouseEnterDelay="slow">
        <Flex align="center">
          {color && <div className={styles.symbol} style={{ backgroundColor: color }} />}
          <Text className={styles.label} variant="caption1">
            {name}
          </Text>
        </Flex>
      </Tooltip>
    );
  }, [color, name]);

  if (interactive) {
    return (
      <CheckableTag
        {...rest}
        className={classNames(
          styles.legendItem,
          styles.legendItemToggle,
          { [styles.selected]: selected, [styles.interactive]: true },
          className,
        )}
        checked={selected}
        onChange={handleChange}
      >
        {children}
      </CheckableTag>
    );
  } else {
    return <div className={classNames(styles.legendItem, className)}>{children}</div>;
  }
};

export default LegendItem;
