import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

import { PageFilterContextProps, PageFiltersChangeFunction, PageFiltersType } from './types';

type Props = {
  className?: string;
  onPageFiltersChange?: PageFiltersChangeFunction;
};

const PageFilterContext = createContext<PageFilterContextProps | undefined>(undefined);

export const usePageFilterContext = () => {
  const context = useContext(PageFilterContext);
  if (!context) {
    throw new Error('useFilterContext must be used within a DashboardFilterProvider');
  }
  return context;
};

const PageFilterProvider = ({ children, onPageFiltersChange }: PropsWithChildren<Props>) => {
  const [filters, setFilters] = useState<PageFiltersType>({});

  const handlePageFiltersChange = useCallback(
    (newFilters: Partial<PageFiltersType>) => {
      setFilters((previousFilters) => {
        const combinedFilters = { ...previousFilters, ...newFilters };
        onPageFiltersChange?.(combinedFilters);
        return combinedFilters;
      });
    },
    [setFilters],
  );

  return (
    <PageFilterContext.Provider
      value={{ pageFilters: filters, setPageFilters: handlePageFiltersChange }}
    >
      {children}
    </PageFilterContext.Provider>
  );
};

export default PageFilterProvider;
