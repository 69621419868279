import { useCallback, useState } from 'react';

const useControlled = <T extends object>(
  controlledValue: T | undefined,
  defaultValue: T | undefined,
  onChange: ((val: T | undefined) => void) | undefined,
): [T | undefined, (val: T | undefined) => void] => {
  const isControlled = typeof controlledValue !== 'undefined';
  const isUncontrolled = typeof defaultValue != 'undefined';
  const [internalValue, setInternalValue] = useState<T | undefined>(
    isUncontrolled ? defaultValue : undefined,
  );

  const value = isControlled ? controlledValue : internalValue;

  const handleSetValue = useCallback(
    (val: T | undefined) => {
      if (!isControlled) {
        setInternalValue(val);
      }

      onChange?.(val);
    },
    [isControlled, setInternalValue, onChange],
  );

  return [value, handleSetValue];
};

export default useControlled;
