import { CSSProperties } from 'react';

import SkeletonLoader from '@/components/SkeletonLoader';

type Props = {
  style?: CSSProperties;
};

const DonutGaugeLoader = ({ style }: Props) => (
  <SkeletonLoader style={{ ...style, padding: '20px' }} viewBox="0 0 162 158">
    <circle cx="81" cy="79" r="16.216" />
    <path d="M81 1.975a77.025 77.025 0 1 1-69.727 44.3L36.68 58.197A48.96 48.96 0 1 0 81 30.041Z" />
    <path d="M81 33.16a45.84 45.84 0 0 1 19.56 87.298l-5.323-11.28A33.367 33.367 0 0 0 81 45.632Z" />
  </SkeletonLoader>
);

export default DonutGaugeLoader;
