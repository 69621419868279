import { SolError } from '@/api/solType';

import { CORE_ERROR_CODES } from './messages/core-standard-errors';
import getSolStandardError from './sol-standard-errors';

class SolGraphQLError extends Error {
  errors: Record<string, SolError> = {};

  constructor(errors: SolError[]) {
    // eslint-disable-next-line lingui/no-unlocalized-strings
    super('GraphQLError');

    const standardErrors = errors.reduce<Record<string, SolError>>((memo, error) => {
      const standardError: SolError = getSolStandardError(error);
      memo[standardError.extensions.code] = standardError;
      return memo;
    }, {});

    this.errors = standardErrors;

    Object.setPrototypeOf(this, SolGraphQLError.prototype);
  }

  is404() {
    return this.errors[CORE_ERROR_CODES.NOT_FOUND] != null;
  }

  getFirstError() {
    return Object.values(this.errors)[0];
  }
}

export default SolGraphQLError;
