import { Trans, t } from '@lingui/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { OpportunityType, useRecentDeals } from '@/api/opportunity';
import { DashboardWidgetComponentProps } from '@/components/Dashboard/types';
import Table, { ColumnsType } from '@/components/Table';
import { Link } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import { ROUTES } from '@/router';

const MAX_RESULTS = 10;

const RecentDealsWidget = ({ pageFilters }: DashboardWidgetComponentProps) => {
  const { recentDeals, isLoading, error } = useRecentDeals();
  const { start_date, end_date } = pageFilters;

  const columns: ColumnsType<OpportunityType> = [
    {
      title: t`Company`,
      key: 'account.company.name',
      fixed: 'left',
      sorter: (a, b) =>
        a.account?.company.name
          .toLowerCase()
          .localeCompare(b.account?.company.name.toLowerCase() || '') ?? 0,
      render: (text, field) => (
        <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
          {field.account?.company.name}
        </Link>
      ),
    },
    {
      title: t`Opportunity`,
      key: 'name',
      sorter: (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      render: (text, field) => (
        <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
          {text}
        </Link>
      ),
    },
    {
      title: t`Close Date`,
      key: 'closeDate',
      sorter: (a, b) => +new Date(a.closeDate) - +new Date(b.closeDate),
      render: (text) => moment(text).format(USDateViewFormat),
      defaultSortOrder: 'descend',
    },
  ];

  const filteredDeals = useMemo(() => {
    const start = moment(start_date);
    const end = moment(end_date);

    if (!recentDeals) {
      return undefined;
    }

    return recentDeals
      .filter((deal: OpportunityType) => moment(deal.closeDate).isBetween(start, end))
      .slice(0, MAX_RESULTS);
  }, [recentDeals, start_date, end_date]);

  return (
    <div style={{ padding: 8 }}>
      <Table<OpportunityType>
        loading={isLoading}
        error={error}
        columns={columns}
        dataSource={filteredDeals}
        emptyMessage={<Trans>No Closed Won opportunities found</Trans>}
        pagination={false}
        rowKey={(record) => record.id}
        sticky
      />
    </div>
  );
};

export default RecentDealsWidget;
