import { Organization } from '@stytch/vanilla-js';
import axios from 'axios';
import gql from 'graphql-tag';
import useSWR from 'swr';
import useSWRImmutable from 'swr/immutable';

import { SolQueryParamsNew } from '@/components/InstantSearch';
import SolGraphQLError from '@/error/SolGraphQLError';

import { SOL_API_HOST } from '../Fetcher';
import { fetcherSol } from '../swr-fetcher';
import {
  InstanceCreateRestResponse,
  InstanceDetailDataType,
  InstanceGQLResponse,
  InstanceListGQLResponse,
  InstanceShortDataType,
  InstanceUpdateGQLResponse,
  InstanceUpdateVariablesType,
  SalesforcePipelineReportMetadataGQLResponse,
} from './organizationType';

export const useCurrentInstance = () => {
  const { data, error, isLoading, mutate } = useSWRImmutable<
    InstanceGQLResponse<InstanceShortDataType>,
    SolGraphQLError
  >(
    {
      query: gql`
        query GetCurrentInstance {
          instances {
            current {
              id
              createdAt
              name
              tagInstallationDate
            }
          }
        }
      `,
    },
    fetcherSol,
    {},
  );

  return {
    instance: data?.instances.current,
    error,
    isLoading,
    mutate,
  };
};

export const useCurrentInstanceSettings = () => {
  const { data, error, isLoading, mutate } = useSWRImmutable<
    InstanceGQLResponse<InstanceDetailDataType>,
    SolGraphQLError
  >(
    {
      query: gql`
        query GetCurrentInstanceSettings {
          instances {
            current {
              id
              createdAt
              name
              tam {
                regions {
                  id
                  name
                }
                revRanges {
                  id
                  name
                }
                sectors {
                  id
                  name
                }
              }
              code
              domain
              pipelineReport {
                url
                fieldName
                influenceWindow
              }
              visitExclusions
            }
          }
        }
      `,
    },
    fetcherSol,
    {},
  );

  return {
    instanceDetails: data?.instances.current,
    error,
    isLoading,
    mutate,
  };
};

export const mutateCurrentInstanceUpdate = async (accountSettings: InstanceUpdateVariablesType) => {
  return await fetcherSol<InstanceUpdateVariablesType, InstanceUpdateGQLResponse>({
    query: gql`
      mutation UpdateCurrentInstance($instance: UpdateInstanceInput!) {
        instances {
          update(instance: $instance) {
            id
          }
        }
      }
    `,
    variables: accountSettings,
  });
};

export const getOrganizationMetadataBySlug = async (slug: string): Promise<Organization> => {
  const stytchToken = import.meta.env.VITE_STYTCH_PUBLIC_TOKEN || '';
  const authorization = window.btoa(`${stytchToken}:${stytchToken}`);
  const clientSdk = window.btoa(
    JSON.stringify({
      app: {
        identifier: '',
      },
      sdk: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        identifier: 'Stytch.js Javascript SDK',
        version: '4.5.2',
      },
    }),
  );

  const response = await axios.post(
    'https://web.stytch.com/sdk/v1/b2b/organizations/search',
    { organization_slug: slug },
    {
      headers: {
        // eslint-disable-next-line lingui/no-unlocalized-strings
        'Content-Type': 'application/json',
        Authorization: `Basic ${authorization}`,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        'X-Sdk-Client': clientSdk,
        // eslint-disable-next-line lingui/no-unlocalized-strings
        'X-Sdk-Parent-Host': location.origin,
      },
    },
  );

  if (!response || response.status !== 200) {
    throw new Error('Unable to fetch organization details');
  }

  return response.data.data.organization as Organization;
};

export const mutateOrganizationCreate = (domain: string, intermediateSessionToken: string) => {
  return axios.post<InstanceCreateRestResponse>(
    `${SOL_API_HOST}instances`,
    { domain },
    {
      headers: {
        Authorization: `Bearer ${intermediateSessionToken}`,
      },
      timeout: 60000, // Upping the timeout to 60 seconds because this call can take a while
    },
  );
};

export const useLoginAsInstanceList = (tableParams?: SolQueryParamsNew) => {
  const { data, error, isLoading } = useSWR<InstanceListGQLResponse, SolGraphQLError>(
    {
      query:
        tableParams &&
        gql`
          query Instances($page: Pagination!, $sort: [SortParamInput]!, $searchQuery: String) {
            instances {
              get(page: $page, sort: $sort, searchQuery: $searchQuery) {
                edges {
                  node {
                    id
                    name
                    createdAt
                    domain
                  }
                }
                totalEdges
              }
            }
          }
        `,
      variables: {
        ...tableParams,
        filter: null,
      },
    },
    fetcherSol,
    {},
  );

  return {
    instanceListData: data?.instances.get.edges.map((d) => d.node),
    totalResults: data?.instances.get.totalEdges,
    isLoading,
    error,
  };
};

export const useSalesforcePipelineReportMetadata = (reportURL?: string) => {
  const { data, error, isLoading } = useSWRImmutable<
    SalesforcePipelineReportMetadataGQLResponse,
    SolGraphQLError
  >(
    {
      query:
        reportURL &&
        gql`
          query GetPipelineReportMetadata($url: String!) {
            instances {
              getPipelineReportMetadata(url: $url) {
                currencyFieldNames
                name
              }
            }
          }
        `,
      variables: {
        url: reportURL,
      },
    },
    fetcherSol,
    { shouldRetryOnError: false },
  );

  return {
    reportMetadata: data?.instances.getPipelineReportMetadata,
    isLoading,
    error,
  };
};
