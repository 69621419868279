import { useEventLogTypes } from '@/api/eventLog';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { CategoryFilter } from '@/components/filters';

type Props = {
  startDate?: string;
  endDate?: string;
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const EventTypeFilter = ({ startDate, endDate, onChange, ...rest }: Props) => {
  const { eventTypes, isLoading, error } = useEventLogTypes(startDate, endDate);

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={eventTypes}
      onChange={onChange}
    />
  );
};

export default EventTypeFilter;
