import { Trans } from '@lingui/macro';
import { Row } from 'antd';

import TableActions from '@/components/TableActions/TableActions';
import { Button } from '@/components/buttons';

type Props = {
  isInstanceSelected: boolean;
  onLoginAs: () => void;
};

const LoginAsHeader = ({ isInstanceSelected, onLoginAs }: Props) => {
  return (
    <TableActions
      actions={
        <Row align="middle" justify="end" style={{ width: '100%', marginInlineEnd: 16 }}>
          <Button
            color="green"
            variant="primary"
            isDisabled={!isInstanceSelected}
            onClick={onLoginAs}
          >
            <Trans>Login As</Trans>
          </Button>
        </Row>
      }
    />
  );
};

export default LoginAsHeader;
