import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { CSSProperties, PropsWithChildren, createContext, useMemo } from 'react';

import { Flex } from '@/components/Flex';

import ListItem from '../List/ListItem';
import DraggableHandle from './DraggableHandle';

type Props = PropsWithChildren<{
  style?: CSSProperties;
  id: string;
}>;

type SortableListContext = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
};

export const SortableItemContext = createContext<SortableListContext>({
  attributes: {},
  listeners: undefined,
  ref() {},
});

const SortableListItem = ({ style, id, children }: Props) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    active,
  } = useSortable({ id });
  const context = useMemo(
    () => ({ attributes, listeners, ref: setActivatorNodeRef }),
    [attributes, listeners, setActivatorNodeRef],
  );

  const mergedStyles: CSSProperties = {
    ...style,
    opacity: isDragging ? 0 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortableItemContext.Provider value={context}>
      <ListItem ref={setNodeRef} style={mergedStyles} id={id}>
        <Flex align="center" gap="medium" fullWidth>
          <div style={{ flex: 1 }}>{children}</div>
          <DraggableHandle isActive={active?.id === id} />
        </Flex>
      </ListItem>
    </SortableItemContext.Provider>
  );
};

export default SortableListItem;
