import { Trans, t } from '@lingui/macro';
import { useParams } from 'react-router-dom';

import { usePixelsByIds } from '@/api/pixel';
import PixelDetailErrorOrChildren from '@/app/pixels/:pixel/PixelDetailErrorOrChildren';
import PixelTagList from '@/app/pixels/:pixel/PixelTagList';
import { Flex } from '@/components/Flex';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';
import { PIXEL_COMPARE_MAX_LIMIT, PIXEL_COMPARE_MIN_LIMIT } from '@/constants/numbers';
import { ROUTES } from '@/router';

import PixelMultiCostPerVisitChart from './PixelMultiCostPerVisitChart';
import PixelMultiKPIMetrics from './PixelMultiKPIMetrics';
import PixelMultiOpportunityInfluenceChart from './PixelMultiOpportunityInfluenceChart';
import PixelMultiReachEngagementChart from './PixelMultiReachEngagementChart';
import PixelMultiTargetingEfficiencyChart from './PixelMultiTargetingEfficiencyChart';

const PixelCompare = () => {
  const { pixels: pixelIdString } = useParams();
  const pixelIds = pixelIdString?.split(',');
  const maxLimitExceeded = (pixelIds?.length ?? 0) > PIXEL_COMPARE_MAX_LIMIT;
  const { pixels } = usePixelsByIds(maxLimitExceeded ? undefined : pixelIds);

  return (
    <Page title={t`Pixel Compare`}>
      <Flex vertical gap="large" fullWidth>
        <PageFilterProvider>
          <Flex justify="space-between" align="start" gap="xlarge">
            <ReturnToLink route={ROUTES.pixels.path} routeName={<Trans>Pixel Overview</Trans>} />
            <PixelTagList pixels={pixels} />
          </Flex>
          <PixelDetailErrorOrChildren
            pixelIds={pixelIds}
            minLimit={PIXEL_COMPARE_MIN_LIMIT}
            maxLimit={PIXEL_COMPARE_MAX_LIMIT}
          >
            <PageDateRangeFilter />
            <PixelMultiKPIMetrics pixelIds={pixelIds} />
            <PixelMultiReachEngagementChart pixelIds={pixelIds} />
            <PixelMultiOpportunityInfluenceChart pixelIds={pixelIds} />
            <PixelMultiTargetingEfficiencyChart pixelIds={pixelIds} />
            <PixelMultiCostPerVisitChart pixelIds={pixelIds} />
          </PixelDetailErrorOrChildren>
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default PixelCompare;
