import { Trans } from '@lingui/macro';
import { useNavigate } from 'react-router-dom';

import TableActions from '@/components/TableActions/TableActions';
import { Button } from '@/components/buttons';
import { ROUTES } from '@/router';

const UsersListHeader = () => {
  const navigate = useNavigate();

  const handleInviteUser = () => {
    navigate(ROUTES.userInvite.path);
  };

  return (
    <TableActions
      actions={
        <Button color="green" variant="primary" onClick={handleInviteUser}>
          <Trans>Invite User</Trans>
        </Button>
      }
    />
  );
};

export default UsersListHeader;
