import { Trans } from '@lingui/macro';

import { AudienceType } from '@/api/audience';
import Form, { TextArea, TextInput } from '@/components/Form';
import { maxDescriptionLength, maxObjectNameLength } from '@/constants/numbers';

enum FORM_FIELD {
  NAME = 'name',
  DESCRIPTION = 'description',
}

type Props = {
  audience?: AudienceType;
  isDisabled?: boolean;
};

const AudienceBaseForm = ({ audience, isDisabled = false }: Props) => {
  return (
    <>
      <Form.Item
        label={<Trans>Name the Audience</Trans>}
        name={FORM_FIELD.NAME}
        initialValue={audience != null ? audience.name : ''}
        rules={[{ required: true, message: <Trans>Name is Required</Trans> }]}
      >
        <TextInput isDisabled={isDisabled} maxLength={maxObjectNameLength} />
      </Form.Item>
      <Form.Item
        label={<Trans>Describe the Audience</Trans>}
        name={FORM_FIELD.DESCRIPTION}
        initialValue={audience != null ? audience.description : ''}
        rules={[{ max: 2000 }]}
      >
        <TextArea
          isDisabled={isDisabled}
          showCount
          maxLength={maxDescriptionLength}
          autoSize={{ minRows: 6, maxRows: 20 }}
        />
      </Form.Item>
    </>
  );
};

export default AudienceBaseForm;
