import { t } from '@lingui/macro';
import { Space } from 'antd';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { InstanceShortDataType, useLoginAsInstanceList } from '@/api/organization';
import { InstantSearchSortOrder, QueryState, SolQueryParamsNew } from '@/components/InstantSearch';
import InstantSearch from '@/components/InstantSearch/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Page } from '@/components/page';
import { getForeignSuperuserInstance, setForeignSuperuserInstance } from '@/helper/common';
import { useLogOut } from '@/hooks/useLogOut';
import { UserContext } from '@/providers/User';
import { ROUTES } from '@/router';

import LoginAsHeader from './LoginAsHeader';
import LoginAsTable from './LoginAsTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'createdAt',
  sortOrder: InstantSearchSortOrder.DESC,
};

const LoginAs = () => {
  const context = useContext(UserContext);
  const navigate = useNavigate();
  const { clearCacheOnly } = useLogOut();
  const [tableParams, setTableParams] = useState<SolQueryParamsNew>();
  const { instanceListData, totalResults, isLoading, error } = useLoginAsInstanceList(tableParams);
  const [selectedRow, setSelectedRow] = useState<InstanceShortDataType | undefined>(undefined);

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsNewFromQueryState(queryState);
    setTableParams(tableParams);
  };

  const handleLoginAs = async () => {
    if (selectedRow) {
      setForeignSuperuserInstance(selectedRow);
      clearCacheOnly();
      if (context) {
        context.setForeignInstance(getForeignSuperuserInstance());
      }
      navigate(ROUTES.app.path);
    }
  };

  return (
    <Page title={t`Your Profile`} pageName={t`Login As`}>
      <ReturnToLink route={ROUTES.profile.path} routeName={t`Your Profile`} />
      <InstantSearch
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={handleQueryStateChange}
      >
        <Space direction="vertical" size="large">
          <LoginAsHeader isInstanceSelected={!!selectedRow} onLoginAs={handleLoginAs} />
          <LoginAsTable
            selectedRow={selectedRow}
            data={instanceListData}
            totalResults={totalResults}
            isLoading={isLoading}
            error={error}
            onSelectedRowChange={setSelectedRow}
          />
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default LoginAs;
