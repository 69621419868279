import { Trans, t } from '@lingui/macro';
import { Col, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { mutateUserDelete, mutateUserUpdate, useUserById } from '@/api/user';
import PermissionSettingInfo from '@/app/users/:user/PermissionSettingInfo';
import Form from '@/components/Form';
import PageSpinner from '@/components/PageSpinner';
import Error404 from '@/components/errors/Error404';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import SolGraphQLError from '@/error/SolGraphQLError';
import { useNotification } from '@/providers/Notification';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';

import UserDeleteModal from './UserDeleteModal';
import UserDetailHeader from './UserDetailHeader';
import UserDetailInfoFields from './UserDetailInfoFields';
import { USER_FORM_FIELD, UserFormType } from './UserFormType';

const UserDetail = () => {
  const loggedInUser = useMe();
  const { pushNotification, removeNotification } = useNotification();
  const navigate = useNavigate();
  const { user: userId } = useParams();
  const { user, isLoading, error, mutate } = useUserById(userId);
  const [form] = useForm();
  const [isEditing, setEditing] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleFinish = async (values: UserFormType) => {
    setSaving(true);

    try {
      const name = values[USER_FORM_FIELD.NAME];
      const role = values[USER_FORM_FIELD.ROLE];

      await mutateUserUpdate(userId!, name, role);

      mutate({ users: { getById: { ...user!, name, role } } });

      pushNotification({ type: 'success', message: t`Success! User has been saved.` });
      removeNotification({ type: 'error' });

      setEditing(false);
    } catch {
      pushNotification({
        type: 'error',
        message: t`Apologies, but something went wrong. Please try to Save again.`,
      });
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      const { success } = await mutateUserDelete(userId!);
      if (!success) {
        throw new Error();
      }

      navigate(ROUTES.users.path);
    } catch {
      pushNotification({
        type: 'error',
        message: t`Apologies, but something went wrong. Please try to Delete again.`,
      });
    } finally {
      setShowDeleteModal(false);
    }
  };

  return (
    <Page title={t`Settings`} pageName={user?.name}>
      {isLoading && <PageSpinner />}
      {error instanceof SolGraphQLError && error.is404() ? (
        <Error404 message={<Trans>The user you're looking for does not exist</Trans>} />
      ) : (
        user && (
          <Form form={form} validateTrigger="onSubmit" onFinish={handleFinish}>
            <Space size="large" direction="vertical" style={{ width: '100%' }}>
              <UserDetailHeader
                isDeletable={loggedInUser?.id !== user.id}
                isEditable={user.status === 'active'}
                isEditing={isEditing}
                isSaving={isSaving}
                onEditChange={setEditing}
                onDelete={() => setShowDeleteModal(true)}
              />
              <Row gutter={[24, 24]}>
                <Col xxl={7} xl={9} md={24} sm={24} xs={24}>
                  <Panel size="L" title={<Trans>User Details</Trans>} style={{ height: '100%' }}>
                    <UserDetailInfoFields
                      isEditing={isEditing}
                      name={user.name}
                      email={user.email}
                      isEmailEditable={false}
                    />
                  </Panel>
                </Col>
                <Col xxl={17} xl={15} lg={24}>
                  <Panel
                    title={<Trans>Permission Setting</Trans>}
                    size="L"
                    style={{ height: '100%' }}
                  >
                    <PermissionSettingInfo role={user.role!} isEditing={isEditing} />
                  </Panel>
                </Col>
              </Row>
            </Space>
          </Form>
        )
      )}
      <UserDeleteModal
        name={user?.name}
        open={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Page>
  );
};

export default UserDetail;
