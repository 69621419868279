import { t } from '@lingui/macro';
import { useMemo } from 'react';

import { AudienceGroupedOptionType } from '@/api/audience';
import { TRAFFIC_TYPES } from '@/api/common';
import { PixelTargetingEfficiencyDataType } from '@/api/pixel';
import MultiDonut from '@/components/charts/MultiDonut';
import { ALL_PRIMARY, AUDIENCE_PRIMARY, BOT_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  pixelIds?: string[];
  audienceIds?: string[];
  data: PixelTargetingEfficiencyDataType;
  audience: AudienceGroupedOptionType;
  tooltipTitle: string;
  disabledSeries?: string[];
  isLoading?: boolean;
};

const PixelTargetingEfficiencyAudienceDonut = ({
  pixelIds,
  audienceIds,
  data,
  audience,
  tooltipTitle,
  disabledSeries,
  isLoading,
}: Props) => {
  const seriesData = useMemo(() => {
    let total = data?.all;

    if (disabledSeries?.includes(TRAFFIC_TYPES.UNRESOLVED)) {
      total -= data?.unresolved;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.OTHER)) {
      total -= data?.otherNonAudience;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.BOT)) {
      total -= data?.bot;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.AUDIENCE)) {
      total -= data?.audience;
    }

    return [
      {
        id: TRAFFIC_TYPES.AUDIENCE,
        value: data?.audience,
        secondaryValue: (data?.audience || 0) / total,
        seriesName: t`${audience.name}`,
        color: AUDIENCE_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.OTHER,
        value: data?.otherNonAudience,
        secondaryValue: (data?.otherNonAudience || 0) / total,
        seriesName: t`Other Companies`,
        color: VISITS_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.BOT,
        value: data?.bot,
        secondaryValue: (data?.bot || 0) / total,
        seriesName: t`Bot`,
        color: BOT_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.UNRESOLVED,
        value: data?.unresolved,
        secondaryValue: (data?.unresolved || 0) / total,
        seriesName: t`Unknown`,
        color: ALL_PRIMARY,
      },
    ];
  }, [data, disabledSeries]);

  return (
    <MultiDonut
      data={seriesData}
      disabledSeries={disabledSeries}
      metricName={t`Target Audience<br/>Impressions`}
      titleValue={numberFormat(data?.audience, { compact: true })}
      tooltipTitle={tooltipTitle}
      isLoading={isLoading}
      error={null}
      valueLink={(item) => {
        const point = item?.point;
        if (point?.id === TRAFFIC_TYPES.AUDIENCE) {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            audienceIds,
            'metrics.impressions': '[1,]',
          });
        }

        if (point?.id === TRAFFIC_TYPES.OTHER) {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            ['audienceIds!']: audienceIds,
            'metrics.impressions': '[1,]',
          });
        }

        if (item?.key === 'total') {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            'metrics.impressions': '[1,]',
          });
        }
      }}
    />
  );
};

export default PixelTargetingEfficiencyAudienceDonut;
