import { Layout as AntdLayout } from 'antd';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';

import styles from './Layout.module.scss';

type Props = {
  className?: string;
};

const Layout = ({ children, className }: PropsWithChildren<Props>) => {
  return <AntdLayout className={classNames(styles.container, className)}>{children}</AntdLayout>;
};

Layout.Content = AntdLayout.Content;

export default Layout;
