import { Navigate, Outlet } from 'react-router-dom';

import { UserProfileType } from '@/api/user';
import { useMe } from '@/providers/User';

const isAuthorized = (user: UserProfileType | undefined) =>
  user && (user.isC99Superuser || user.isPaid);

/**
 * A route which unlocks child routes if the instance has been purchased
 */
const PaidRoute = () => {
  const user = useMe();
  return isAuthorized(user) ? <Outlet /> : <Navigate to="/" replace />;
};

export default PaidRoute;
