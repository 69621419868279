import { Trans, t } from '@lingui/macro';
import { Col, Row, Space } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { mutateUserInvite } from '@/api/user';
import Form from '@/components/Form';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import useErrorDisplay from '@/error/useErrorDisplay';
import { ROUTES } from '@/router';

import PermissionSettingInfo from '../:user/PermissionSettingInfo';
import UserDetailInfoFields from '../:user/UserDetailInfoFields';
import { USER_FORM_FIELD, UserFormType } from '../:user/UserFormType';
import UserInviteHeader from './UserInviteHeader';

const UserInvite = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isSaving, setSaving] = useState(false);
  const displayErrors = useErrorDisplay();

  const handleFinish = async (values: UserFormType) => {
    setSaving(true);

    try {
      const { success } = await mutateUserInvite(
        values[USER_FORM_FIELD.NAME],
        values[USER_FORM_FIELD.EMAIL],
        values[USER_FORM_FIELD.ROLE],
      );
      if (!success) {
        throw new Error();
      }

      navigate(ROUTES.users.path);
    } catch (e) {
      displayErrors({ error: e });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Page title={t`Settings`} pageName={t`Invite User`}>
      <Form form={form} validateTrigger="onSubmit" onFinish={handleFinish}>
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <UserInviteHeader isSaving={isSaving} />
          <Row gutter={[24, 24]}>
            <Col xxl={7} xl={9} md={24} sm={24} xs={24}>
              <Panel size="L" title={<Trans>User Details</Trans>} style={{ height: '100%' }}>
                <UserDetailInfoFields name="" email="" isEmailEditable={true} isEditing={true} />
              </Panel>
            </Col>
            <Col xxl={17} xl={15} lg={24}>
              <Panel title={<Trans>Permission Setting</Trans>} size="L" style={{ height: '100%' }}>
                <PermissionSettingInfo role="user" isEditing={true} />
              </Panel>
            </Col>
          </Row>
        </Space>
      </Form>
    </Page>
  );
};

export default UserInvite;
