import { Trans } from '@lingui/macro';

import { ConfirmModal } from '@/components/modals';
import { Text } from '@/components/typography';
import { useMe } from '@/providers/User';

type Props = {
  open: boolean;
  onClose: () => void;
  onOk?: () => void;
};

const DemandbaseInstructionsModal = ({ open, onClose, onOk }: Props) => {
  const user = useMe();

  const handleOk = () => {
    onClose();
    onOk?.();
  };

  return (
    <ConfirmModal
      open={open}
      onCancel={onClose}
      onOk={handleOk}
      okText={<Trans>Yes, email sent</Trans>}
    >
      <Text variant="body1" style={{ textAlign: 'left' }}>
        <Trans>
          <p>
            To complete this integration, Demandbase support must be notified. Please copy and paste
            the following information into your business email system.
          </p>
          <div style={{ border: '1px solid black', padding: 16 }}>
            <strong>To:</strong> support@demandbase.com; mark@channel99.com;
            <br />
            <strong>Subject:</strong> Demandbase and Channel99 Data Share - {user?.currentOrg?.name}
            <p>
              <strong>Message:</strong>
            </p>
            <p>Hello,</p>
            <p>
              I would like to share campaign data from my Demandbase account with Channel99 through
              the S3 bucket integration. Can Demandbase please do this for my organization with the
              following requirements?
            </p>
            <ul style={{ listStyle: 'disc', marginBlock: 16, paddingInlineStart: 40 }}>
              <li>Format should be JSON</li>
              <li>C99 Account ID for my account is: "{user?.currentOrg.instanceId}"</li>
              <li>Please provide all historic data with the initial data drop</li>
              <li>Update the data daily with incremental data updates</li>
            </ul>
            <p>Thank you,</p>
          </div>
        </Trans>
      </Text>
    </ConfirmModal>
  );
};

export default DemandbaseInstructionsModal;
