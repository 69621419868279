import { t } from '@lingui/macro';

import { TRAFFIC_TYPES } from '@/api/common';
import TooltipRow from '@/components/Flare/guides/TooltipRow';
import MultiSeriesTooltip from '@/components/MultiSeriesTooltip/MultiSeriesTooltip';
import { VisitsTableBarCellDataType } from '@/features/VisitsTableBarCell/types';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data: VisitsTableBarCellDataType[];
  totalVisits: number;
  tooltipTitle: string;
  sticky: boolean;
  tooltipLink: (trafficType: TRAFFIC_TYPES) => string | undefined;
  onClose?: () => void;
};

const VisitsTableBarCellTooltip = ({
  data,
  totalVisits,
  tooltipTitle,
  sticky,
  tooltipLink,
  onClose,
}: Props) => {
  return (
    <MultiSeriesTooltip
      data-testid={sticky && 'visits-tooltip-sticky'}
      title={tooltipTitle}
      sticky={sticky}
      onClose={onClose}
    >
      {data.map(
        ({ type, label, value, color, isDisabled }) =>
          !isDisabled && (
            <TooltipRow
              key={type}
              color={color}
              name={label}
              value={t`${numberFormat(value)} Visits`}
              valueLink={value > 0 && tooltipLink(type)}
              secondaryValue={
                value > 0 &&
                totalVisits > 0 &&
                t`(${numberFormat(value / totalVisits, { isPercent: true, precision: 1 })})`
              }
            />
          ),
      )}
      <TooltipRow
        name={t`All`}
        value={t`${numberFormat(totalVisits)} Visits`}
        valueLink={totalVisits > 0 && tooltipLink(TRAFFIC_TYPES.ALL)}
      />
    </MultiSeriesTooltip>
  );
};

export default VisitsTableBarCellTooltip;
