import { useSectors } from '@/api/settings';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { CategoryFilter } from '@/components/filters';

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const SectorFilter = ({ onChange, ...rest }: Props) => {
  const { sectors, isLoading, error } = useSectors({ includeBlanks: true });

  return (
    <CategoryFilter
      {...rest}
      selectionMode="multiple"
      isLoading={isLoading}
      error={error}
      options={sectors}
      onChange={onChange}
    />
  );
};

export default SectorFilter;
