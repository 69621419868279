import { Trans, t } from '@lingui/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { Organization } from '@stytch/vanilla-js';
import { useState } from 'react';

import Form, { TextInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { Link, Text } from '@/components/typography';
import { maxEmailLength } from '@/constants/numbers';
import { getStytchAPIErrorMessage, isStytchAPIError } from '@/error/StytchError';
import { EMAIL_STANDARD_RULES } from '@/helper/ant-rule';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import RateLimit from '../../RateLimitButton';
import SignInPanel from '../../SignInPanel';
import styles from './MagicLinkForm.module.scss';

enum FORM_FIELD {
  EMAIL = 'email',
}

type FormType = {
  [FORM_FIELD.EMAIL]: string;
};

type Props = {
  org: Organization;
};

const MagicLinkForm = ({ org }: Props) => {
  const stytch = useStytchB2BClient();
  const { pushNotification, removeAllNotifications } = useNotification();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleFinish = async (values: FormType) => {
    const email = values[FORM_FIELD.EMAIL];

    try {
      setLoading(true);
      await stytch.magicLinks.email.loginOrSignup({
        email_address: email,
        organization_id: org.organization_id,
        login_redirect_url: `${location.origin}/sign-in`,
        signup_redirect_url: `${location.origin}/sign-in`,
      });

      removeAllNotifications();
      pushNotification({
        type: 'success',
        message: (
          <Trans>
            A one-time access link was sent to&nbsp;
            <Text variant="caption1" weight="extra-bold">
              {email}
            </Text>
            . You'll be able to log in and change your password after verification. If you don't
            receive an email, reach out to support@channel99.com or try again.
          </Trans>
        ),
      });
    } catch (e) {
      if (isStytchAPIError(e) && e.error_type === 'invalid_email') {
        form.setFields([
          {
            name: FORM_FIELD.EMAIL,
            errors: [t`Please enter a valid email address.`],
          },
        ]);
      } else {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form
      className={styles.container}
      form={form}
      validateTrigger="onSubmit"
      size="large"
      onFinish={handleFinish}
    >
      <SignInPanel
        title={<Trans>Welcome Back, {org.organization_name}</Trans>}
        description={
          <Trans>
            We can email you a one-time access link so you can sign in without a password.
          </Trans>
        }
        footer={
          <Text variant="caption1">
            <Trans>
              Don't want to use a email link?{' '}
              <Link
                variant="caption1"
                color="aqua"
                to={ROUTES.tenantSignInByOrgSlug(org.organization_slug)}
              >
                {t`Sign in a different way`}
              </Link>
            </Trans>
          </Text>
        }
      >
        <Form.Item
          label={<Trans>Business email</Trans>}
          name={FORM_FIELD.EMAIL}
          rules={EMAIL_STANDARD_RULES}
          initialValue=""
          required={false}
        >
          <TextInput
            inputMode="email"
            placeholder={t`name@work-email.com`}
            autoComplete="email"
            spellCheck="false"
            autoCapitalize="none"
            autoFocus
            maxLength={maxEmailLength}
          />
        </Form.Item>
        <RateLimit>
          <Button
            className={styles.magicLinkButton}
            color="green"
            variant="primary"
            size="large"
            type="submit"
            isLoading={isLoading}
            block
          >
            <Trans>Email me a link</Trans>
          </Button>
        </RateLimit>
      </SignInPanel>
    </Form>
  );
};

export default MagicLinkForm;
