import classNames from 'classnames';

import { Text, Title } from '@/components/typography';
import { stringToColor } from '@/helper/colorHelper';
import { stringInitials } from '@/helper/string-util';

import styles from './LetteredAvatar.module.scss';

type Props = {
  name?: string;
  size?: 'small' | 'medium';
  shape?: 'circle' | 'square';
};

const LetteredAvatar = ({ name, size = 'small', shape = 'circle' }: Props) => {
  const props = {
    className: classNames(styles.avatar, styles[`size-${size}`], styles[`shape-${shape}`]),
    style: { backgroundColor: stringToColor(name) },
    children: stringInitials(name),
  };

  if (size === 'small') {
    return <Text variant="caption2" {...props} />;
  }

  return <Title level={4} {...props} />;
};

export default LetteredAvatar;
