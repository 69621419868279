import {
  COLOR_GREEN_300,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_GREEN_600,
  COLOR_GREEN_700,
  COLOR_GREEN_800,
} from '@/styles/palette';

export const ALL_SEMANTIC_GREENS = [
  COLOR_GREEN_300,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_GREEN_600,
  COLOR_GREEN_700,
  COLOR_GREEN_800,
];
