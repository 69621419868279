import { InputNumber, InputNumberProps } from 'antd';
import classNames from 'classnames';
import { forwardRef } from 'react';

import { maxDefaultLength } from '@/constants/numbers';

import styles from './NumberInput.module.scss';

export type NumberInputProps = Omit<
  InputNumberProps,
  'variant' | 'status' | 'size' | 'disabled' | 'suffix'
> & {
  error?: boolean;
  isDisabled?: boolean;
  size?: 'small' | 'medium' | 'large';
};

const NumberInput = forwardRef<HTMLInputElement, NumberInputProps>(
  (
    {
      className,
      style = {},
      error = false,
      isDisabled = false,
      size = 'large',
      maxLength = maxDefaultLength,
      width,
      ...rest
    },
    ref,
  ) => {
    return (
      <InputNumber
        {...rest}
        className={classNames(styles.input, className)}
        style={{ ...style, width: width ? width : undefined }}
        ref={ref}
        variant="outlined"
        size={size === 'medium' ? 'middle' : size}
        status={error ? 'error' : undefined}
        disabled={isDisabled}
        maxLength={maxLength}
      />
    );
  },
);

export default NumberInput;
