import { PropsWithChildren } from 'react';

import { Text } from '@/components/typography';

type Props = {
  label: string;
};

const HealthPodDetail = ({ label, children }: PropsWithChildren<Props>) => (
  <Text variant="body2">
    {label}: {children}
  </Text>
);

export default HealthPodDetail;
