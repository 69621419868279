import { t } from '@lingui/macro';
import { Series, SeriesOptionsType } from 'highcharts';
import { useEffect } from 'react';

import { LegendItem, Legend as SharedLegend } from '@/components/Legend';

import { useFlareContext } from '../FlareContext';

type Props = {
  reversed?: boolean;
  noToggleVisibility?: boolean;
  showByCategory?: boolean;
  onSeriesToggle?: (value: string, selected: boolean) => void;
};

type SeriesOptionsTypeData = {
  id: string;
  name: string;
  color: string;
};

type SeriesOptionsTypeEnhanced = {
  data: SeriesOptionsTypeData[];
  color: string;
  colorByPoint?: boolean;
} & SeriesOptionsType;

const Legend = ({
  reversed = false,
  noToggleVisibility = false,
  showByCategory,
  onSeriesToggle,
}: Props) => {
  const { id, options, registerChild, chart, parentProps } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options: Highcharts.Options) => {
      return {
        ...options,
        // turn off legend since we're rendering an HTML one below
        legend: {
          enabled: false,
        },
      };
    });
  }, []);

  const handleSeriesToggle = (value: string, selected: boolean) => {
    if (chart && value) {
      // if onSeriesToggle is provided, this is a controlled component which must pass disabled
      // series to the parent Flare component.
      if (onSeriesToggle) {
        onSeriesToggle(value, selected);
      } else {
        const series = chart.get(value) as Series;
        series?.setVisible(selected);
      }
    }
  };

  // Wait until Flare has processed options merging and have Legend updates.
  if (!options?.legend) {
    return null;
  }

  let updatedSeries = (options?.series || []) as SeriesOptionsTypeEnhanced[];
  if (reversed) {
    updatedSeries = updatedSeries.slice().reverse();
  }

  return (
    <SharedLegend
      noToggleVisibility={noToggleVisibility}
      defaultDisabledValues={parentProps.disabledSeriesIds}
      onSeriesToggle={handleSeriesToggle}
    >
      {showByCategory
        ? updatedSeries
            .reduce<SeriesOptionsTypeData[]>((memo, series) => memo.concat(series?.data), [])
            .map((d, index) => {
              let color = d.color;
              if (!color && updatedSeries[0]?.colorByPoint) {
                color = options.colors?.[index] as string;
              }
              return (
                <LegendItem key={(d.name || '') + index} value={d.id} color={color} name={d.name} />
              );
            })
        : updatedSeries.map(
            (series, index) =>
              series.showInLegend !== false && (
                <LegendItem
                  key={(id || '') + index}
                  value={series.id!}
                  color={typeof series.color === 'string' ? series.color : null}
                  name={series.name ? series.name : t`Series ${index + 1}`}
                />
              ),
          )}
    </SharedLegend>
  );
};

export default Legend;
