import { t } from '@lingui/macro';
import { Col, Row } from 'antd';

import { Flex } from '@/components/Flex';
import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';

import PipelineInfluencedTable from './PipelineInfluencedTable';
import PipelineKPIMetrics from './PipelineKPIMetrics';
import PipelineReturnOnSpendChart from './PipelineReturnOnSpendChart';
import PipelineTrendsChart from './PipelineTrendsChart';
import PipelineVendorInfluenceTable from './PipelineVendorInfluenceTable';

const PipelineOverview = () => {
  return (
    <Page title={t`Analytics`} pageName={t`Pipeline`}>
      <Flex vertical gap="large" fullWidth>
        <PageFilterProvider>
          <PageDateRangeFilter />
          <PipelineKPIMetrics />
          <PipelineTrendsChart />
          <Row gutter={[24, 24]}>
            <Col xl={12} lg={24} sm={24} xs={24}>
              <PipelineReturnOnSpendChart />
            </Col>
            <Col xl={12} lg={24} sm={24} xs={24}>
              <PipelineInfluencedTable />
            </Col>
          </Row>
          <PipelineVendorInfluenceTable />
        </PageFilterProvider>
      </Flex>
    </Page>
  );
};

export default PipelineOverview;
