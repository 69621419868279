import { Trans, t } from '@lingui/macro';

import { useCampaignKPIs } from '@/api/campaign';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

const CampaignOverviewKPIMetrics = () => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();

  const {
    campaignKPIs: data,
    isLoading,
    error,
  } = useCampaignKPIs(undefined, startDate, endDate, audience?.id);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>All Visits</Trans>}
        value={data && numberFormat(data.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>{audience?.id ? audience.name : t`TAM`} Visits</Trans>}
        value={
          audience?.id
            ? data && numberFormat(data.audienceVisits)
            : data && numberFormat(data.tamVisits)
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Engaged Companies</Trans>}
        value={data && numberFormat(data.engagedCompanies)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Pipeline Influenced</Trans>}
        value={data && numberFormat(data.pipelineInfluenced)}
        caption={<Trans>{numberFormat(data?.pipelineOpportunities)} Opportunities</Trans>}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Closed Won Influenced</Trans>}
        value={data && numberFormat(data.closedWonInfluenced)}
        caption={<Trans>{numberFormat(data?.closedWonOpportunities)} Opportunities</Trans>}
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default CampaignOverviewKPIMetrics;
