import { Trans } from '@lingui/macro';

import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { BenchmarksWidget } from '@/features/WidgetLibrary';

const BenchmarksByChannel = () => {
  const { pageFilters } = usePageFilterContext();

  return (
    <Panel title={<Trans>Total Visits</Trans>} size="L" noPadding style={{ minHeight: 500 }}>
      <BenchmarksWidget pageFilters={pageFilters} />
    </Panel>
  );
};

export default BenchmarksByChannel;
