/* eslint-disable no-restricted-imports */
import { Popover as AntdPopover, PopoverProps as AntdPopoverProps } from 'antd';
import { TooltipRef } from 'antd/es/tooltip';
import classNames from 'classnames';
import { forwardRef } from 'react';

import styles from './Popover.module.scss';

export type PopoverProps = AntdPopoverProps & {
  noPadding?: boolean;
  variant?: 'default' | 'dark';
};

const Popover = forwardRef<TooltipRef, PopoverProps>(
  ({ overlayClassName, variant = 'default', noPadding = false, ...rest }, ref) => {
    return (
      <AntdPopover
        ref={ref}
        overlayClassName={classNames(
          styles.overlay,
          overlayClassName,
          variant === 'dark' && styles.dark,
          {
            [styles.noPadding]: noPadding,
          },
        )}
        arrow
        {...rest}
      />
    );
  },
);

export default Popover;
