import { Trans, t } from '@lingui/macro';
import { Space } from 'antd';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import { INTEGRATION_PROVIDER_ID, useIntegrationProviderDetails } from '@/api/integration';
import DemandbaseInstructionsModal from '@/app/integrations/:provider/DemandbaseInstructionsModal';
import PageSpinner from '@/components/PageSpinner';
import { ReturnToLink } from '@/components/ReturnToLink';
import Error404 from '@/components/errors/Error404';
import PageError from '@/components/errors/PageError';
import { Page } from '@/components/page';
import { Link, Text, Title } from '@/components/typography';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import { getProviderHelpLinkById, getProviderLongDescriptionById } from '../providerUtil';
import styles from './IntegrationDetail.module.scss';
import IntegrationDetailRail from './IntegrationDetailRail';

type ConnectionResultsType = {
  connectionSavedSuccess?: boolean;
  connectionSavedError?: boolean;
};

const IntegrationDetail = () => {
  const { pushNotification } = useNotification();
  const { provider: providerId } = useParams();
  const [showDemandbaseInstructionsModal, setShowDemandbaseInstructionsModal] = useState(false);

  const { state } = useLocation();
  const { connectionSavedSuccess, connectionSavedError } = (state as ConnectionResultsType) || {};
  const { provider, isLoading, is404, error } = useIntegrationProviderDetails(
    providerId as INTEGRATION_PROVIDER_ID,
  );

  useEffect(() => {
    if (provider) {
      if (connectionSavedSuccess === false) {
        pushNotification({
          type: 'error',
          message:
            connectionSavedError ??
            t`Oh no! Something went wrong. Please, try syncing again or contact your administrator`,
        });
      }
      if (connectionSavedSuccess === true) {
        pushNotification({
          type: 'success',
          message: (
            <span>
              <Text variant="caption1" weight="semi-bold">
                <Trans>
                  Success! You have connected your {provider.name} to Channel99! Learn more about
                  the {provider.name} connection{' '}
                  <Link
                    color="black"
                    variant="caption1"
                    weight="semi-bold"
                    to={getProviderHelpLinkById(provider.id)}
                  >
                    HERE
                  </Link>
                </Trans>
              </Text>
            </span>
          ),
        });
      }
    }
  }, [connectionSavedSuccess, provider?.name, provider?.id]);

  return (
    <Page title={t`Integrations`} pageName={provider?.name}>
      <Space direction="vertical" size="large">
        <ReturnToLink route={ROUTES.integrations.path} routeName={t`Integrations`} />
        {is404 && <Error404 message={<Trans>The integration provider cannot be found</Trans>} />}
        {error && (
          <PageError
            message={<Trans>An error occurred while loading this integration provider</Trans>}
            detail={<Trans>Please refresh the page and try again</Trans>}
          />
        )}
        {isLoading && <PageSpinner />}
        {!is404 && !error && !isLoading && provider && (
          <div className={styles.container}>
            <IntegrationDetailRail provider={provider} />
            <div className={styles.content}>
              <Title level={1} weight="light">
                <Trans>Information Pertaining to the {provider?.name} Connection</Trans>
              </Title>
              <Text variant="headline" weight="light">
                {getProviderLongDescriptionById(provider?.id)}
              </Text>
              {providerId === INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS && (
                <Text variant="headline" weight="light">
                  <Trans>
                    By clicking the "+Demandbase Ads Account" button, an email template will be
                    shown with a pre-written message to Demandbase and Channel99 support.{' '}
                    <Link
                      variant="headline"
                      onClick={() => setShowDemandbaseInstructionsModal(true)}
                    >
                      This message
                    </Link>{' '}
                    must come from a Demandbase admin to setup the connection. If you are not a
                    Demandbase Admin, please CC them on the email.
                  </Trans>
                </Text>
              )}
              <Text variant="headline" weight="light">
                <Trans>
                  For more details please review our{' '}
                  <Link to={getProviderHelpLinkById(provider.id)} variant="headline">
                    Support Center
                  </Link>
                </Trans>
              </Text>
            </div>
            <DemandbaseInstructionsModal
              open={showDemandbaseInstructionsModal}
              onClose={() => setShowDemandbaseInstructionsModal(false)}
            />
          </div>
        )}
      </Space>
    </Page>
  );
};

export default IntegrationDetail;
