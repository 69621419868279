import { Pagination as AntdPagination, PaginationProps } from 'antd';
import classNames from 'classnames';

import styles from './Pagination.module.scss';

const Pagination = ({ className, total, ...rest }: PaginationProps) => {
  return (
    <AntdPagination
      {...rest}
      className={classNames(styles.pagination, className)}
      showSizeChanger={
        total != null &&
        total > 50 && {
          getPopupContainer: () => document.getElementById('pageRoot') as HTMLElement,
          popupClassName: styles.sizeChangerPopup,
        }
      }
      total={total}
    />
  );
};

export default Pagination;
