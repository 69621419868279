import classNames from 'classnames';

import { TRAFFIC_TYPES } from '@/api/common';
import Tooltip from '@/components/Tooltip';
import { Text } from '@/components/typography';
import VisitsTableBarCellTooltip from '@/features/VisitsTableBarCell/VisitsTableBarCellTooltip';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './VisitsTableBarCell.module.scss';
import { VisitsTableBarCellDataType } from './types';

const ALLOCATED_LABEL_WIDTH = 60;

type Props = {
  data: VisitsTableBarCellDataType[];
  maxVisits?: number;
  tooltipTitle: string;
  isOpaque: boolean;
  isTooltipOpen: boolean;
  isTooltipSticky: boolean;
  tooltipLink: (trafficType: TRAFFIC_TYPES) => string | undefined;
  onStickyTooltipClose?: () => void;
};

const VisitsTableBarCell = ({
  data,
  maxVisits = 0,
  tooltipTitle,
  isOpaque = false,
  isTooltipOpen = false,
  isTooltipSticky = false,
  tooltipLink,
  onStickyTooltipClose,
}: Props) => {
  const totalVisits = data.reduce(
    (acc, { value, isDisabled }) => acc + (isDisabled ? 0 : value),
    0,
  );

  return (
    <div className={classNames(styles.container)}>
      <div
        className={classNames(styles.barGroup, { [styles.isFaded]: !isOpaque })}
        style={{ width: `calc(100% - ${ALLOCATED_LABEL_WIDTH}px)` }}
      >
        {data.map(({ type, value, color, isDisabled }) => (
          <div
            key={type}
            className={styles.bar}
            style={{
              backgroundColor: color,
              width: `${isDisabled ? 0 : (value / maxVisits) * 100}%`,
            }}
          />
        ))}
        <Tooltip
          placement="bottom"
          open={isTooltipOpen}
          maxWidth={600}
          pointerEvents={isTooltipSticky}
          body={
            <VisitsTableBarCellTooltip
              data={data}
              totalVisits={totalVisits}
              tooltipTitle={tooltipTitle}
              sticky={isTooltipSticky}
              tooltipLink={tooltipLink}
              onClose={onStickyTooltipClose}
            />
          }
        >
          {/* A hidden bar with the full width of the stacked series that tooltip can anchor to */}
          <div
            className={styles.tooltipAnchor}
            style={{ width: `${(totalVisits / maxVisits) * 100}%` }}
          />
        </Tooltip>
        <div className={styles.label} style={{ left: `${(totalVisits / maxVisits) * 100}%` }}>
          {totalVisits > 0 && (
            <Text variant="caption1" className={classNames(styles.text, 'light')}>
              {numberFormat(totalVisits)}
            </Text>
          )}
        </div>
      </div>
    </div>
  );
};

export default VisitsTableBarCell;
