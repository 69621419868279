import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { Col, Row } from 'antd';
import { useMemo } from 'react';

import { usePixelUsageVisualizationData } from '@/api/pixel';
import Flare, { Axis, Column, FlareSeriesOptions, Legend, Tooltip } from '@/components/Flare';
import { KpiMetric } from '@/components/kpi';
import { TAM_PRIMARY } from '@/constants/colors';
import { dateFormatterUTC, parseUTCDate } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

import styles from './PixelUsageChart.module.scss';

const DEFAULT_TOOLTIP_GAP = 80;
const TOOLTIP_GAP = 20;
const ONE_MILLION = 1_000_000;

const getNearestMillionImpressions = (value?: number | null): string => {
  if (value == null || isNaN(value)) {
    return '0';
  }

  const impressions = Math.max(Math.ceil(value / ONE_MILLION) * ONE_MILLION, ONE_MILLION);
  return numberFormat(impressions);
};

const PixelUsageChart = () => {
  const { i18n } = useLingui();
  const { impressionMonthlyData, impressionKPIData, isLoading, error } =
    usePixelUsageVisualizationData();

  const monthCategories = useMemo(
    () => impressionMonthlyData?.map((d) => d.firstDayOfMonth) || [],
    [impressionMonthlyData],
  );

  const chartSeriesData = useMemo<FlareSeriesOptions[] | undefined>(() => {
    if (!impressionMonthlyData) {
      return undefined;
    }

    return [
      {
        id: 'projected',
        name: t`Projected Usage`,
        zIndex: 1,
        dashStyle: 'ShortDot',
        borderWidth: 2,
        borderColor: TAM_PRIMARY,
        data: (impressionMonthlyData || []).map((d) => ({ amount: d.projected, opacity: 0.5 })),
      },
      {
        id: 'usage',
        name: t`Pixel Usage`,
        zIndex: 2,
        data: (impressionMonthlyData || []).map((d) => ({ amount: d.actual })),
      },
    ];
  }, [impressionMonthlyData]);

  return (
    <Flare data={chartSeriesData} width="auto" height="auto" isLoading={isLoading} error={error}>
      <Legend noToggleVisibility />
      <div style={{ overflow: 'hidden' }}>
        <Row className={styles.kpiMetricsContainer} gutter={[24, 32]}>
          <Col md={8} sm={24} xs={24}>
            <KpiMetric
              label={<Trans>Impressions Served</Trans>}
              value={impressionKPIData && numberFormat(impressionKPIData.served)}
              isLoading={isLoading}
              error={error}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <KpiMetric
              label={<Trans>Recommended Monthly Allocation</Trans>}
              value={
                impressionKPIData &&
                t`${getNearestMillionImpressions(impressionKPIData.recommendedAllocation)}/mo.`
              }
              isLoading={isLoading}
              error={error}
            />
          </Col>
          <Col md={8} sm={24} xs={24}>
            <KpiMetric
              label={<Trans>Impressions (Last 30 Days)</Trans>}
              value={impressionKPIData && numberFormat(impressionKPIData.last30Days)}
              isLoading={isLoading}
              error={error}
            />
          </Col>
        </Row>
      </div>
      <Column
        y="amount"
        groupPadding={0}
        pointPadding={0.075}
        position="stack"
        skeletonLoaderOptions={{ numCategories: 6, numSeries: 2 }}
      />
      <Axis position="left" labelFormat={(item) => numberFormat(item.value, { compact: true })} />
      <Axis
        type="datetime"
        position="bottom"
        categories={monthCategories}
        labelFormat={(item) => dateFormatterUTC(i18n, item.value, { month: 'long' })}
        crosshair="rect"
      />
      <Tooltip
        shared
        titleFormat={(item) => dateFormatterUTC(i18n, item?.x, { month: 'long' })}
        positioner={(tooltip, labelWidth, labelHeight, point) => {
          const xPos = point.plotX - labelWidth - TOOLTIP_GAP;

          return {
            y: point?.plotY - labelHeight / 2,
            // flip tooltip position if we don't have enough space
            x: xPos < 0 ? point.plotX + DEFAULT_TOOLTIP_GAP + TOOLTIP_GAP : xPos,
          };
        }}
        rowValueFormat={(item) => {
          const xDateUTC = parseUTCDate(item?.x + '');
          const nowUTC = parseUTCDate(new Date());

          const isCurrentMonth =
            nowUTC.getMonth() === xDateUTC.getMonth() &&
            nowUTC.getFullYear() === xDateUTC.getFullYear();
          const isFutureMonth = +nowUTC < +xDateUTC;
          const isPastMonth = !isCurrentMonth && !isFutureMonth;

          const isProjectedDataPoint = item?.colorIndex === 0;
          const isActualDataPoint = item?.colorIndex === 1;

          // don't display an actual value of zero for a future month
          if (isActualDataPoint && isFutureMonth) {
            return null;
          }

          // don't display a projected value of zero for past months
          if (isProjectedDataPoint && isPastMonth) {
            return null;
          }

          return numberFormat(item?.y);
        }}
      />
    </Flare>
  );
};

export default PixelUsageChart;
