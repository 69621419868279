export const stringInitials = (str?: string | null) => {
  const parts = (str || '').split(' ');
  const firstInitial = parts[0].toLocaleUpperCase()[0];

  if (parts.length > 1) {
    const secondWord = parts[1].toLocaleUpperCase();
    const secondInitial = secondWord[0];
    // Don't show an initial for some 2nd words.
    const dropSecondInitial =
      secondWord === 'INC' ||
      secondWord === 'INC.' ||
      secondWord === 'LLC' ||
      secondWord === 'LLC.';
    return `${firstInitial}${dropSecondInitial ? '' : secondInitial}`;
  }
  return firstInitial;
};

export const truncateMiddle = (fullStr = '', strLen = 16, separator = '...') => {
  if (fullStr.length <= strLen) {
    return fullStr;
  }

  const separatorLength = separator.length;
  const charsToShow = strLen - separatorLength;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    fullStr.substring(0, frontChars) + separator + fullStr.substring(fullStr.length - backChars)
  );
};
