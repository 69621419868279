import { B2BAllowedAuthMethods, Organization } from '@stytch/vanilla-js';

const ALL_AUTH_METHODS: B2BAllowedAuthMethods[] = [
  'sso',
  'magic_link',
  'password',
  'google_oauth',
  'microsoft_oauth',
];

// Auth methods that aren't supported (yet)
const RESTRICTED_AUTH_METHODS = ['sso'];

const getAuthMethods = (
  authMethods: 'ALL_ALLOWED' | 'RESTRICTED',
  allowedAuthMethods: B2BAllowedAuthMethods[],
) => {
  if (authMethods === 'ALL_ALLOWED') {
    return ALL_AUTH_METHODS;
  } else {
    return allowedAuthMethods;
  }
};

export const getAllowedAuthMethods = (org?: Organization) => {
  if (!org) {
    return [];
  }

  return getAuthMethods(org.auth_methods, org.allowed_auth_methods ?? [])?.filter(
    (method) => !RESTRICTED_AUTH_METHODS.some((restrictedMethod) => restrictedMethod === method),
  );
};
