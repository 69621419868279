import { t } from '@lingui/macro';

import { AudienceOptionType } from '@/api/audience';
import { TRAFFIC_TYPES } from '@/api/common';
import { Legend, LegendItem } from '@/components/Legend';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants';

type Props = {
  audience?: AudienceOptionType;
  onDisabledSeriesChange: (disabledSeriesType: TRAFFIC_TYPES[]) => void;
};

const VisitActivityLegend = ({ audience, onDisabledSeriesChange }: Props) => {
  return (
    <Legend<TRAFFIC_TYPES>
      style={{ marginInline: -32, marginBlockStart: -20, marginBlockEnd: 16 }}
      onDisabledSeriesChange={onDisabledSeriesChange}
    >
      {audience?.name ? (
        <LegendItem value={TRAFFIC_TYPES.AUDIENCE} color={AUDIENCE_PRIMARY} name={audience?.name} />
      ) : (
        <LegendItem value={TRAFFIC_TYPES.TAM} color={TAM_PRIMARY} name={t`TAM`} />
      )}
      <LegendItem value={TRAFFIC_TYPES.OTHER} color={VISITS_PRIMARY} name={t`Other Companies`} />
      <LegendItem value={TRAFFIC_TYPES.BOT} color={BOT_PRIMARY} name={t`Bot`} />
      <LegendItem value={TRAFFIC_TYPES.UNRESOLVED} color={UNKNOWN_PRIMARY} name={t`Unknown`} />
    </Legend>
  );
};

export default VisitActivityLegend;
