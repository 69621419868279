import { Trans, t } from '@lingui/macro';
import { Row, Space } from 'antd';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  AudienceType,
  mutateAudienceUpdate,
  useAudienceById,
  useSalesforceAudienceReportMetadata,
} from '@/api/audience';
import Form from '@/components/Form';
import PageSpinner from '@/components/PageSpinner';
import { ReportName } from '@/components/ReportName';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import Error404 from '@/components/errors/Error404';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import { Text } from '@/components/typography';
import SolGraphQLError from '@/error/SolGraphQLError';
import useErrorDisplay from '@/error/useErrorDisplay';
import { ROUTES } from '@/router';

import AudienceBaseForm from '../../AudienceBaseForm';

type FormType = {
  name: string;
  description: string;
};

const AudienceDetailEdit = () => {
  const { audience: audienceId } = useParams();
  const navigate = useNavigate();
  const { audience, isLoading, error, mutate: mutateAudienceById } = useAudienceById(audienceId);
  const {
    reportMetadata,
    isLoading: isReportLoading,
    error: reportError,
  } = useSalesforceAudienceReportMetadata(audience?.salesforce_url);
  const displayErrors = useErrorDisplay();
  const [isUpdatingAudience, setIsUpdatingAudience] = useState<boolean>();
  const [form] = Form.useForm<FormType>();

  const handleReturnToListView = () => {
    navigate(ROUTES.audiences.path);
  };

  const handleFinish = async (values: FormType) => {
    if (audience) {
      setIsUpdatingAudience(true);

      try {
        const updatedAudience = { ...audience, ...values } as AudienceType;
        await mutateAudienceUpdate(updatedAudience);
        mutateAudienceById({
          audiences: {
            getById: { ...audience, name: values.name, description: values.description },
          },
        });
        navigate(ROUTES.audiences.path, {
          state: {
            successMessage: t`Audience was successfully updated.`,
            optimisticUpdate: updatedAudience,
          },
        });
      } catch (e) {
        displayErrors({ error: e, form });
      }

      setIsUpdatingAudience(false);
    }
  };

  if (error instanceof SolGraphQLError && error.is404()) {
    return <Error404 message={<Trans>The audience you're looking for does not exist</Trans>} />;
  }

  return (
    <Page title={t`Edit Audience`}>
      <Form form={form} validateTrigger="onBlur" onFinish={handleFinish}>
        <Space size={24} direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <ReturnToLink route={ROUTES.audiences.path} routeName={t`Audiences`} />
            <Space size={16}>
              <Button color="black" variant="secondary" onClick={handleReturnToListView}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                color="green"
                variant="primary"
                isDisabled={isUpdatingAudience}
                isLoading={isUpdatingAudience}
                type="submit"
              >
                <Trans>Save</Trans>
              </Button>
            </Space>
          </Row>
          {isLoading && !audience ? (
            <PageSpinner />
          ) : (
            <Panel size="L" title={<Trans>Edit Audience Details</Trans>}>
              <>
                {audience?.type === 'salesforce' && (
                  <Space direction="vertical" size={16} style={{ width: '100%' }}>
                    <div>
                      <Trans>
                        <Text variant="label">Note: </Text>
                        <Text variant="body1">
                          Your Salesforce Accounts report must include the standard Account object
                          field "<Text variant="label">Website</Text>" as a column, and must not
                          contain groupings.
                        </Text>
                      </Trans>
                    </div>
                    <Text variant="label">
                      <Trans>Salesforce Report Link</Trans>
                    </Text>
                    <Text variant="body1" color="grey">
                      {audience?.salesforce_url}
                    </Text>
                    {
                      <ReportName
                        reportName={reportMetadata?.name}
                        isLoading={isReportLoading}
                        error={reportError}
                      />
                    }
                  </Space>
                )}
                <AudienceBaseForm audience={audience} isDisabled={isUpdatingAudience} />
              </>
            </Panel>
          )}
        </Space>
      </Form>
    </Page>
  );
};

export default AudienceDetailEdit;
