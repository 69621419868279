import { ChannelType } from '@/api/channel';
import { getOpacityInHex, stringToColor } from '@/helper/colorHelper';
import { stringInitials } from '@/helper/string-util';

export const getChannelColor = (channel?: ChannelType, opacity?: number) => {
  const color = channel?.color ?? stringToColor(stringInitials(channel?.name));

  if (opacity) {
    return `${color}${getOpacityInHex(opacity)}`;
  }

  return color;
};
