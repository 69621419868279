import { Trans } from '@lingui/macro';

import { usePageFilterContext } from '@/components/page';
import { NotesPanel } from '@/components/panels';
import { PipelineTrendsWidget } from '@/features/WidgetLibrary';

const PipelineTrendsChart = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;

  return (
    <NotesPanel
      startDate={startDate}
      endDate={endDate}
      title={<Trans>Trends</Trans>}
      size="L"
      style={{ height: 415 }}
      noPadding
    >
      <PipelineTrendsWidget pageFilters={{ start_date: startDate, end_date: endDate }} />
    </NotesPanel>
  );
};

export default PipelineTrendsChart;
